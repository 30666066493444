import React, { useState, useEffect } from 'react';
import { EmployeeHolidaysTabs, HolidaysHead, ResponseData } from '../../../data/HolidaysData';
import { Holidays, TabCustom, HolidaysNote } from '../../common';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { findAllHolidays, findHolidaysNote } from '../../../redux/holidays/action';

export const EmployeeHolidays = () => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 50;

  const tabs = {
    ...EmployeeHolidaysTabs,
  };

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Employee | Holidays';
  }, []);

  const {
    holidaysFindAllProcess,
    holidaysFindAllError,
    holidaysFindAllData,
    holidaysNoteFindProcess,
    holidaysNoteFindError,
    holidaysNoteFindData,
  } = useSelector(({ holidays }) => ({
    ...holidays,
  }));

  useEffect(() => {
    dispatch(findAllHolidays(skip, limit));
    dispatch(findHolidaysNote());
  }, [skip]);

  //   map holidays data from API response
  const holidaysResponse = holidaysFindAllData && holidaysFindAllData?.data?.list;
  const holidaysData =
    holidaysResponse &&
    holidaysResponse.map((holiday) => {
      return { ...holiday };
    });

  // Table data
  const tableData = {
    tabs: tabs,
    module: 'employee',
    count: holidaysFindAllData?.data?.count,
    skip: skip,
    data: holidaysData,
    head: HolidaysHead,
    formInputs: [],
    errorMessage: holidaysFindAllError?.message,
    hiddenKeys: ['actions'],
    inProgress: holidaysFindAllProcess,
  };

  // holiday note find data
  const holidayNote =
    !holidaysNoteFindProcess &&
    !holidaysNoteFindError &&
    holidaysNoteFindData &&
    holidaysNoteFindData?.data;
   
// holiday note content
  const noteContent = {
    content: holidayNote,
    hiddenKeys: [],
    module: 'employee',
  };

  return (
    <>
      <HolidaysNote {...noteContent} />
      <TabCustom {...tabs} />

      <Box mb={4}>
        <Holidays {...tableData} />
      </Box>
    </>
  );
};
