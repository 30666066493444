export const Types = {
  START_CREATE_PAYROLL: 'START_CREATE_PAYROLL',
  END_CREATE_PAYROLL: 'END_CREATE_PAYROLL',

  START_FIND_ALL_PAYROLL: 'START_FIND_ALL_PAYROLL',
  END_FIND_ALL_PAYROLL: 'END_FIND_ALL_PAYROLL',

  START_FIND_PAYROLL: 'START_FIND_PAYROLL',
  END_FIND_PAYROLL: 'END_FIND_PAYROLL',

  START_UPDATE_PAYROLL: 'START_UPDATE_PAYROLL',
  END_UPDATE_PAYROLL: 'END_UPDATE_PAYROLL',
};
