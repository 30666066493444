import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HrmsEmployees } from '.';
import { AddOrEditEmployee, ViewEmployee } from '../../common';
import { commonFn } from '../../../util/commonFn';

export const EmployeesRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/hrms/employees'} component={HrmsEmployees} />
        <Route
          path={'/hrms/employees/create'}
          render={(props) => <AddOrEditEmployee {...props} />}
        />
        <Route
          path={'/hrms/employees/view/:id'}
          render={(props) => <ViewEmployee isView={true} {...props} />}
        />
        <Route
          path={'/hrms/employees/edit/:id'}
          render={(props) => <AddOrEditEmployee isEdit={true} {...props} />}
        />
      </Switch>
    </>
  );
};
