import React, { useEffect, useState } from 'react';
import { TableCustom, TabCustom } from '../common';
import { Box, CircularProgress } from '@material-ui/core';
import { PartnersHead, Tabs } from '../../data/PartnersData';
import '../positions/Positions.scss';
import { useHistory } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';
import { partnersFindAll } from '../../redux/partners/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deletePartners } from '../../../src/redux/partners/action';

// Start: Main Function
export const Partners = ({ match }) => {
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const {
    partnersFindAllError,
    partnersFindAllData,
    partnersFindAllProcess,
    partnersDeleteProcess,
    partnersDeleteError,
    partnersDeleteData,
    userInfo,
  } = useSelector(({ partners, sign }) => ({
    ...partners,
    ...sign,
  }));

  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [hiddenAction, setHiddenAction] = useState([]);
  const [hiddenTableCol, setHiddenTableCol] = useState([]);

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Partners';
  }, []);

  useEffect(() => {
    dispatch(partnersFindAll(skip, limit));
  }, [skip]);

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const partnersId = data.row[0].value;

    if (partnersId && data?.action?.type === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-partners') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/partners/edit/${partnersId}`);
    }

    // view partners
    if ((partnersId && data.action?.key) || (partnersId && data.action?.type === 'view')) {
      history.push(`/partners/view/${partnersId}`);
    }

    // delete partners
    if (partnersId && data.action.type === 'delete') {
      if (commonFn.menuHiddenKeys.indexOf('delete-partners') !== -1) {
        commonFn.showPopup(commonFn.deleteAccessError);
        return false;
      }

      if (userInfo?.data?.id === partnersId) {
        MySwal.fire({
          title: 'Warning!!',
          text: `You can't delete your partner`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        });
        return false;
      }

      MySwal.fire({
        title: 'Are you sure?',
        text: 'You want to delete the Partner',
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        result = result.isConfirmed && dispatch(deletePartners(partnersId));
      });
    }
  };

  // refresh partners data after deleting
  useEffect(() => {
    if (!partnersDeleteProcess) {
      dispatch(partnersFindAll(skip, limit));
      if (partnersDeleteData) {
        Swal.fire({
          title: 'Success!',
          text: 'Partner has been deleted',
          icon: 'success',
          iconColor: '#3085d6',
        });
      } else if (partnersDeleteError) {
        Swal.fire({
          title: 'Error!',
          text: `${partnersDeleteError?.message}`,
          icon: 'error',
          iconColor: '#B41E26',
        });
      }
    }
  }, [partnersDeleteData, partnersDeleteError, partnersDeleteProcess]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
  };

  // hide action based on the permission
  useEffect(() => {
    if (!partnersFindAllProcess && !partnersFindAllError && partnersFindAllData) {
      if (commonFn.menuHiddenKeys.indexOf('edit-partners') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Edit']);
      }
      if (commonFn.menuHiddenKeys.indexOf('delete-partners') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Delete']);
      }
    }
  }, [partnersFindAllProcess, partnersFindAllError, partnersFindAllData]);

  // hide the action column if edit and delete action is not
  useEffect(() => {
    if (
      hiddenAction &&
      hiddenAction.indexOf('Edit') !== -1 &&
      hiddenAction.indexOf('Delete') !== -1
    ) {
      setHiddenTableCol((hiddenTableCol) => [...hiddenTableCol, 'action']);
    }
  }, [hiddenAction]);

  // Mapping API response
  const tableData = {
    hiddenAction: hiddenAction,
    count: partnersFindAllData?.data?.count,
    skip: skip,
    data: partnersFindAllData?.data?.list?.map((partner) => ({
      ...partner,
      account_name: `${partner.account_name} ${
        partner.id === userInfo?.data?.partner_id ? '(Your Partner)' : ''
      }`,
    })),
    head: PartnersHead,
    pagination: {
      numberOfPages: partnersFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    clickOnCell: clickOnCell,
    errorMessage: partnersFindAllError?.message,
  };

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  return (
    <div>
      {/* Table component */}
      <TabCustom {...tabs} />

      {partnersFindAllProcess && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}

      {!partnersFindAllProcess && (
        <>
          <Box mb={4}>
            <TableCustom {...tableData} hiddenKeys={hiddenTableCol} />
          </Box>
        </>
      )}
    </div>
  );
};
