import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Reimbursement',
    groupLabelClass: 'fieldset-label',
    key: 'reimbursement',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'From Date',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'from_date',
        name: 'from_date',
        placeholder: 'From Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 40,
        errors: [{ type: 'required', message: 'Please select from date' }],
      },
      {
        label: 'To Date',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'to_date',
        name: 'to_date',
        placeholder: 'To Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select to date' }],
      },
      {
        label: 'Description',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'description',
        name: 'description',
        hiddenKey: 'description',
        placeholder: 'Description',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        multiline: true,
        rows: 6,
        minLength: 1,
        maxLength: 5000,
      },
      {
        label: 'Reimbursement Status',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'reimbursement_status_id',
        name: 'reimbursement_status_id',
        placeholder: 'Select Status',
        hide: false,
        optionsKey: 'reimbursementStatus',
        valueId: 'reimbursement_status_id',
        displayKey: 'reimbursement_status',
        hiddenKey: 'reimbursement_status',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
    ],
  },
  {
    groupLabel: 'Attachment',
    groupLabelClass: 'fieldset-label',
    key: 'attachment',
    groupClass: 'form-fieldset',
    inputs: [
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadReimbursement',
      },
    ],
  },
];

// document upload
export const DocumentUploadInputs = {
  label: 'Upload Document(s)',
  displayLabel: 'Upload Reimbursement',
  containerClass: 'interview_status_row',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  documentTypeName: 'documentList.0.document_type',
  documentName: 'documentList.0.document',
  documentUploadType: 'file',
  documentUploadId: 'document-0-value',
  uploadTextClass: 'file-label',
  formControlClass: 'input-form-control',
  fieldClass: 'text-box-style',
  documentTypePlaceholder: 'Select or Create Doc Type',
  documentPlaceholder: 'Upload Reimbursement',
  variant: 'outlined',
  margin: 'dense',
  inputClass: 'input-file-button',
  keyValue: 1,
  id: 'documentUpload',
  formElementClass: 'form-input-box',
};

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Accounts',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Company', link: '/company/profile', className: 'active' },
    { text: 'Invoices', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Invoices',
      key: 'view',
      link: '/company/invoices',
      path: '/company/invoices',
      className: 'tab-link',
      hiddenKey: 'view-invoices',
    },
  ],
};
