import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leaveUpdateAction } from '../../redux/leaves/action';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DialogBox } from '.';
import { LeaveRejectionInputsData } from '../../data/EditLeaveData';
import { openSnackBarLayout } from '../../redux/common/action';

export const LeaveAction = ({ match }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [action, setAction] = useState('');
  const MySwal = withReactContent(Swal);
  const [formData, setFormData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [reasonData, setReasonData] = useState();

  const { leaveActionProcess, leaveActionError, leaveActionData } = useSelector(({ leaves }) => ({
    ...leaves,
  }));

  useEffect(() => {
    const actionString = queryString.parse(location?.search)?.action;
    setAction(() => ({
      values: {
        action: actionString,
        token: queryString.parse(location?.search)?.em,
        actionEmail: queryString.parse(location?.search)?.key,
      },
    }));

    const leaveAction = {
      em: queryString.parse(location?.search)?.em,
      action: actionString,
      approved_by_email: queryString.parse(location?.search)?.key,
    };
    actionString && actionString === 'approved' && dispatch(leaveUpdateAction(leaveAction));
  }, [location?.search]);

  useEffect(() => {
    if (action && !leaveActionProcess && !leaveActionError && leaveActionData) {
      Swal.fire({
        title: 'Success!',
        text: `${leaveActionData?.message}`,
        icon: 'success',
        iconColor: '#3085d6',
      }).then(function () {
        window.location.href = '/login';
      });
    }
    else if (action && !leaveActionProcess && leaveActionError?.message) {
        Swal.fire({
          title: 'Whoops!',
          text: `${leaveActionError?.message}`,
          icon: 'error',
          iconColor: '#B41E26',
        }).then(function () {
          window.location.href = '/login';
        });
    }
  }, [leaveActionProcess, leaveActionError, leaveActionData, action]);

  // set rejection inputs data
  useEffect(() => {
    setFormData(() => ({
      list: LeaveRejectionInputsData,
      buttonText: {
        submitText: 'Submit',
        cancelText: 'Cancel',
      },
    }));
    setIsFormSubmitted(() => false);
    setIsDialogOpened(() => true);
  }, []);

  // close dialog function
  const closeDialog = () => {
    setIsDialogOpened(() => false);
  };

  // change event
  const onChangeEvent = (data) => {
    const dataArray = {};

    if (data?.field?.name === 'reason') {
      dataArray.reason = data?.value?.target?.value;
    }

    setReasonData((reasonData) => ({
      ...reasonData,
      ...dataArray,
    }));
  };

  useEffect(() => {
    if (isFormSubmitted) {
      if (!leaveActionProcess && !leaveActionError && leaveActionData) {
        Swal.fire({
          title: 'Success!',
          text: `${leaveActionData?.message}`,
          icon: 'success',
          iconColor: '#3085d6',
        }).then(function () {
          window.location.href = '/login';
        });
      } else if (!leaveActionProcess && leaveActionError?.message) {
        Swal.fire({
          title: 'Whoops!',
          text: `${leaveActionError?.message}`,
          icon: 'error',
          iconColor: '#B41E26',
        }).then(function () {
          window.location.href = '/login';
        });
      }
    }
  }, [leaveActionProcess, leaveActionError, leaveActionData]);


  // form submit
  const formSubmit = () => {
    const request = {};
    request.reason = reasonData?.reason;
    request.em = action?.values?.token;
    request.action = action?.values?.action;
    request.rejected_by_email = action?.values?.actionEmail;

    if (!reasonData?.reason) {
      dispatch(openSnackBarLayout('Please enter the reason for leave rejection', 'error', 1000));
      return false;
    }

    setIsFormSubmitted(() => true);
    setIsDialogOpened(() => false);
    dispatch(leaveUpdateAction(request));
  };

  return (
    <>
      {action?.values?.action === 'rejected' && (
        <DialogBox
          {...formData}
          isOpened={isDialogOpened}
          closeDialog={closeDialog}
          formSubmit={formSubmit}
          changeEvent={onChangeEvent}
        />
      )}
    </>
  );
};
