import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Positions, CreatePosition, ViewPosition } from '.';
import { commonFn } from '../../util/commonFn';
import { NotFound } from '../common';

export const PositionsRouter = (props) => {
  return (
    <>
      <Switch>
        {commonFn.menuHiddenKeys.indexOf('list-positions') === -1 && (
          <Route exact path={'/positions'} component={Positions} />
        )}
        {commonFn.menuHiddenKeys.indexOf('create-position') === -1 && (
          <Route exact path={'/positions/create'} component={CreatePosition} />
        )}
        {commonFn.menuHiddenKeys.indexOf('list-positions') === -1 && (
          <Route
            path={'/positions/view/:id'}
            render={(props) => <ViewPosition isView={true} {...props} />}
          />
        )}
        {commonFn.menuHiddenKeys.indexOf('edit-position') === -1 && (
          <Route
            path={'/positions/edit/:id'}
            render={(props) => <CreatePosition isEdit={true} {...props} />}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
