import { Validation } from './CommonData';
import { Edit, Delete } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    day: 'Monday',
    date: 'Jan 01, 2021',
    holiday: `New Year's Day`,
  },
  {
    id: 2,
    day: 'Wednesday',
    date: 'Jan 14, 2021',
    holiday: 'Makar Sankranti / Pongal',
  },
  {
    id: 3,
    day: 'Friday',
    date: 'Jan 26, 2021',
    holiday: 'Republic Day',
  },
];

// holidays table header
export const HolidaysHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: '', bodyClass: '' },
  {
    label: 'Day',
    key: 'day',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Date',
    key: 'date',
    hide: false,
    headClass: 'w-100',
    type: 'text',
    link: '',
  },
  {
    label: 'Holiday',
    key: 'holiday',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Type',
    key: 'type',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hiddenKey: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'edit',
        icon: Delete,
        text: 'Delete',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// company holidays page header
export const PageHeader = {
  className: 'page-header',
  page_title: 'Holidays',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Holidays', link: '', className: 'active' },
  ],
};

// Hrms holidays page header
export const HrmsPageHeader = {
  className: 'page-header',
  page_title: 'Holidays',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Holidays', link: '', className: 'active' },
  ],
};

// Hrms holidays page header
export const EmployeePageHeader = {
  className: 'page-header',
  page_title: 'Holidays',
  breadcrumb: [
    { text: 'Dashboard', link: '/employee/profile', className: '' },
    { text: 'Holidays', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Holidays',
      key: 'view',
      link: '/company/holidays',
      path: '/company/holidays',
      className: 'tab-link',
      hiddenKey: 'view-holidays',
    },
  ],
};

// Tabs data
export const CompanyHolidaysTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Holidays',
      key: 'holidays',
      link: '/company/holidays',
      className: 'tab-link',
      hiddenKey: 'list-holidays',
    },
  ],
};

// employee holidays tab
export const EmployeeHolidaysTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Holidays',
      key: 'holidays',
      link: '/employee/holidays',
      className: 'tab-link',
      hiddenKey: 'list-holidays',
    },
  ],
};

// invoice tabs data
export const HrmsHolidaysTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Holidays',
      key: 'holidays',
      link: '/hrms/holidays',
      className: 'tab-link',
      hiddenKey: 'list-holidays',
    },
    // {
    //   label: 'Create Holiday',
    //   key: 'create',
    //   link: '/hrms/holidays/create',
    //   path: '/hrms/holidays/create',
    //   className: 'tab-link',
    //   hiddenKey: 'create-invoice',
    // },
    {
      label: 'View Holiday',
      key: 'view',
      link: '/hrms/holidays/view/:id',
      path: '/hrms/holidays/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-holidays',
    },
    {
      label: 'Edit Holiday',
      key: 'edit',
      link: '/hrms/holidays/edit/:id',
      path: '/hrms/holidays/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-holiday',
    },
  ],
};

// Holiday create data
export const HolidayInputsData = [
  {
    groupLabel: 'Create Holiday',
    groupLabelClass: 'fieldset-label',
    key: 'holiday',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Date',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'date',
        name: 'date',
        placeholder: 'Holiday Date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 40,
        errors: [{ type: 'required', message: 'Please select the date' }],
      },
      {
        label: 'Holiday',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'holiday',
        name: 'holiday',
        placeholder: 'Holiday',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 40,
        errors: [{ type: 'required', message: 'Please enter holiday detail' }],
      },
      {
        label: 'Type',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'holidayType',
        name: 'holidayType',
        placeholder: 'Type',
        hide: false,
        optionsKey: 'holidayTypes',
        valueId: 'holidayType',
        displayKey: 'types',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'Add New',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'holidayCreate',
        formControlClass: 'input-form-control',
        type: 'button',
        fieldClass: '',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
];

// holidays note data
export const HolidaysNoteData = {
  groupLabel: 'Create Holiday',
  groupLabelClass: 'fieldset-label',
  key: 'holiday',
  groupClass: 'form-fieldset',
  inputs: [
    {
      label: 'Holiday Note',
      xs: 12,
      sm: 12,
      md: 6,
      lg: 4,
      xl: 4,
      key: 'holidayNote',
      name: 'holidayNote',
      placeholder: 'Holiday Note',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'resizableTextArea',
      fieldClass: 'text-box-style',
      textAreaClass: 'resizableTextArea',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      isRequired: true,
      rows: 2,
      errors: [{ type: 'required', message: 'Please enter holiday note' }],
    },
    {
      label: 'Update',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'holidayNoteUpdate',
      formControlClass: 'input-form-control',
      type: 'button',
      fieldClass: '',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    },
  ],
};
