import React from 'react';
import { HolidaysRouter } from '.';
import { DashboardHeader } from '../../common';
import { EmployeePageHeader } from '../../../data/HolidaysData';
import { Container } from '@material-ui/core';

export const EmployeeHolidaysMain = () => {
  const headerData = { header: EmployeePageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <HolidaysRouter />
    </Container>
  );
};
