import React, { useEffect, useState } from 'react';
import { Invoices } from '../../common';
import { InvoiceTabs, ResponseData, AccountsHead, MainFilter } from '../../../data/AccountsData';
import { commonFn } from '../../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { findAllInvoices } from '../../../redux/invoices/action';
import { FilterCustom } from '../../common';
import { clientsFindAll } from '../../../redux/clients/action';
import { invoiceStatusFindAll } from '../../../redux/master/action';
import { invoiceCreatorsFindAll } from '../../../redux/invoices/action';

export const AccountsInvoices = (match) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [filterValues, setFilterValues] = useState([]);

  const {
    invoiceFindAllProcess,
    invoiceFindAllError,
    invoiceFindAllData,
    clientsFindAllData,
    invoiceStatusFindAllData,
    invoiceCreatorFindAllData,
  } = useSelector(({ invoices, clients, invoiceStatus }) => ({
    ...invoices,
    ...clients,
    ...invoiceStatus,
  }));

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Accounts | Invoices';
  }, []);

  useEffect(() => {
    dispatch(findAllInvoices(skip, limit));
    dispatch(clientsFindAll());
    dispatch(invoiceStatusFindAll());
    dispatch(invoiceCreatorsFindAll());
  }, [skip]);

  const tabs = {
    ...InvoiceTabs,
    list: commonFn.updateLink(InvoiceTabs.list, match),
  };

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllInvoices(limit * (value - 1), limit));
  };

  const invoicesResponse = invoiceFindAllData && invoiceFindAllData?.data?.list;
  const invoiceData =
    invoicesResponse &&
    invoicesResponse.map((invoice) => {
      const triggered_at = invoice.triggered_at && `Sent on: ${invoice.triggered_at}`;
      return { ...invoice, triggered_at };
    });

  // extract clients from api response
  const clients =
    clientsFindAllData &&
    clientsFindAllData?.data?.list?.map((client) => {
      return { label: client?.company_name, value: client?.id };
    });

  // invoice creators
  const creators =
    invoiceCreatorFindAllData &&
    invoiceCreatorFindAllData?.data?.list?.map((creator) => {
      return { label: creator?.full_name, value: creator?.user_id };
    });

  // invoice status
  const invoiceStatus =
    invoiceStatusFindAllData &&
    invoiceStatusFindAllData?.data?.list?.map((status) => {
      return { label: status?.status, value: status?.id };
    });

  // filter data
  const filterData = {
    filter: MainFilter,
    options: { clients, invoiceStatus, creators },
  };

  // change event
  const changeEvent = (data) => {
    const filterArray = {};

    if (data?.field?.name === 'client_id') {
      const clients = commonFn.getIdFromMultiValue(data?.value);
      filterArray.client_id = clients;
    }

    if (data?.field?.name === 'status_id') {
      const status = commonFn.getIdFromMultiValue(data?.value?.value);
      filterArray.status_id = status;
    }

    if (data?.field?.name === 'creator_id') {
      const creator = commonFn.getIdFromMultiValue(data?.value?.value);
      filterArray.creator_id = creator;
    }

    setFilterValues((filterValue) => ({
      ...filterValue,
      ...filterArray,
    }));
  };

  // filter form submit
  const filterFormSubmit = (data) => {
    const request = data;
    request.client_id = filterValues?.client_id;
    request.status_id = filterValues?.status_id;
    request.creator_id = filterValues?.creator_id;

    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(findAllInvoices(0, limit, { ...filterValues, ...request }));
  };

  // table data
  const tableData = {
    tabs: tabs,
    module: 'accounts',
    count: invoiceFindAllData?.data?.count,
    skip: skip,
    data: invoiceData,
    head: AccountsHead,
    pagination: {
      numberOfPages: invoiceFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: invoiceFindAllError?.message,
    hiddenKeys: [],
    inProgress: invoiceFindAllProcess ? true : false,
    filterData: filterData || {},
    filterFormSubmit: filterFormSubmit,
    changeEvent: changeEvent,
  };

  return (
    <>
      <Invoices {...tableData} />
    </>
  );
};
