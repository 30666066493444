import { Types } from './types';

const initState = {
  leaveTypesFindAllProcess: false,
  leaveTypesFindAllError: null,
  leaveTypesFindAllData: null,

  leaveApproversFindAllProcess: false,
  leaveApproversFindAllError: null,
  leaveApproversFindAllData: null,

  leaveCreateProcess: false,
  leaveCreateError: null,
  leaveCreateData: null,

  leavesFindAllProcess: false,
  leavesFindAllError: null,
  leavesFindAllData: null,

  leaveFindProcess: false,
  leaveFindError: null,
  leaveFindData: null,

  leaveUpdateProcess: false,
  leaveUpdateError: null,
  leaveUpdateData: null,

  leaveActionProcess: false,
  leaveActionError: null,
  leaveActionData: null,

  leaveStatusFindAllProcess: false,
  leaveStatusFindAllError: null,
  leaveStatusFindAllData: null,

  leaveDeleteProcess: false,
  leaveDeleteError: null,
  leaveDeleteData: null,
};

export const leaves = (state = initState, action) => {
  switch (action.type) {
    // leave types find all
    case Types.START_FIND_LEAVE_TYPES:
      return {
        ...state,
        leaveTypesFindAllProcess: true,
        leaveTypesFindAllError: null,
        leaveTypesFindAllData: null,
      };

    case Types.END_FIND_LEAVE_TYPES: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveTypesFindAllProcess: false,
        leaveTypesFindAllError: error,
        leaveTypesFindAllData: success,
      };
    }

    // leave approvers
    case Types.START_FIND_ALL_APPROVERS:
      return {
        ...state,
        leaveApproversFindAllProcess: true,
        leaveApproversFindAllError: null,
        leaveApproversFindAllData: null,
      };

    case Types.END_FIND_ALL_APPROVERS: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveApproversFindAllProcess: false,
        leaveApproversFindAllError: error,
        leaveApproversFindAllData: success,
      };
    }

    // leave create
    case Types.START_CREATE_LEAVE:
      return {
        ...state,
        leaveCreateProcess: true,
        leaveCreateError: null,
        leaveCreateData: null,
      };

    case Types.END_CREATE_LEAVE: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveCreateProcess: false,
        leaveCreateError: error,
        leaveCreateData: success,
      };
    }

    // leaves find all
    case Types.START_FIND_ALL_LEAVES:
      return {
        ...state,
        leavesFindAllProcess: true,
        leavesFindAllError: null,
        leavesFindAllData: null,
      };

    case Types.END_FIND_ALL_LEAVES: {
      const { success, error } = action.payload;
      return {
        ...state,
        leavesFindAllProcess: false,
        leavesFindAllError: error,
        leavesFindAllData: success,
      };
    }

    // find leave
    case Types.START_FIND_LEAVE:
      return {
        ...state,
        leaveFindProcess: true,
        leaveFindError: null,
        leaveFindData: null,
      };

    case Types.END_FIND_LEAVE: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveFindProcess: false,
        leaveFindError: error,
        leaveFindData: success,
      };
    }

    // update leave
    case Types.START_UPDATE_LEAVE:
      return {
        ...state,
        leaveUpdateProcess: true,
        leaveUpdateError: null,
        leaveUpdateData: null,
      };

    case Types.END_UPDATE_LEAVE: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveUpdateProcess: false,
        leaveUpdateError: error,
        leaveUpdateData: success,
      };
    }

    // leave action - approve/reject
    case Types.START_LEAVE_ACTION:
      return {
        ...state,
        leaveActionProcess: true,
        leaveActionError: null,
        leaveActionData: null,
      };

    case Types.END_LEAVE_ACTION: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveActionProcess: false,
        leaveActionError: error,
        leaveActionData: success,
      };
    }

    // leave status find all
    case Types.START_FIND_ALL_LEAVE_STATUS:
      return {
        ...state,
        leaveStatusFindAllProcess: true,
        leaveStatusFindAllError: null,
        leaveStatusFindAllData: null,
      };

    case Types.END_FIND_ALL_LEAVE_STATUS: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveStatusFindAllProcess: false,
        leaveStatusFindAllError: error,
        leaveStatusFindAllData: success,
      };
    }

    // leave delete
    case Types.START_DELETE_LEAVE:
      return {
        ...state,
        leaveDeleteProcess: true,
        leaveDeleteError: null,
        leaveDeleteData: null,
      };

    case Types.END_DELETE_LEAVE: {
      const { success, error } = action.payload;
      return {
        ...state,
        leaveDeleteProcess: false,
        leaveDeleteError: error,
        leaveDeleteData: success,
      };
    }
    default:
      return state;
  }
};
