import { Types } from './types';

const initState = {
  invoiceFindAllProcess: false,
  invoiceFindAllError: null,
  invoiceFindAllData: null,

  invoiceCreateProcess: false,
  invoiceCreateError: null,
  invoiceCreateData: null,

  invoiceFindProcess: false,
  invoiceFindError: null,
  invoiceFindData: null,

  invoiceUpdateProcess: false,
  invoiceUpdateError: null,
  invoiceUpdateData: null,

  invoiceSendEmailProcess: false,
  invoiceSendEmailError: null,
  invoiceSendEmailData: null,

  invoiceCreatorFindAllProcess: false,
  invoiceCreatorFindAllError: null,
  invoiceCreatorFindAllData: null,
};

export const invoices = (state = initState, action) => {
  switch (action.type) {
    // invoice find all
    case Types.START_FIND_ALL_INVOICES:
      return {
        ...state,
        invoiceFindAllProcess: true,
        invoiceFindAllError: null,
        invoiceFindAllData: null,
      };
    case Types.END_FIND_ALL_INVOICES: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceFindAllProcess: false,
        invoiceFindAllError: error,
        invoiceFindAllData: success,
      };
    }

    // create invoice
    case Types.START_CREATE_INVOICE:
      return {
        ...state,
        invoiceCreateProcess: true,
        invoiceCreateError: null,
        invoiceCreateData: null,
      };
    case Types.END_CREATE_INVOICE: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceCreateProcess: false,
        invoiceCreateError: error,
        invoiceCreateData: success,
      };
    }

    // find invoice
    case Types.START_FIND_INVOICE:
      return {
        ...state,
        invoiceFindProcess: true,
        invoiceFindError: null,
        invoiceFindData: null,
      };
    case Types.END_FIND_INVOICE: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceFindProcess: false,
        invoiceFindError: error,
        invoiceFindData: success,
      };
    }

    // update invoice
    case Types.START_UPDATE_INVOICE:
      return {
        ...state,
        invoiceUpdateProcess: true,
        invoiceUpdateError: null,
        invoiceUpdateData: null,
      };
    case Types.END_UPDATE_INVOICE: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceUpdateProcess: false,
        invoiceUpdateError: error,
        invoiceUpdateData: success,
      };
    }

    // update invoice
    case Types.START_SEND_EMAIL_INVOICE:
      return {
        ...state,
        invoiceSendEmailProcess: true,
        invoiceSendEmailError: null,
        invoiceSendEmailData: null,
      };
    case Types.END_SEND_EMAIL_INVOICE: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceSendEmailProcess: false,
        invoiceSendEmailError: error,
        invoiceSendEmailData: success,
      };
    }

    // invoice creator find all
    case Types.START_FIND_INVOICE_CREATOR:
      return {
        ...state,
        invoiceCreatorFindAllProcess: true,
        invoiceCreatorFindAllError: null,
        invoiceCreatorFindAllData: null,
      };
    case Types.END_FIND_INVOICE_CREATOR: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceCreatorFindAllProcess: false,
        invoiceCreatorFindAllError: error,
        invoiceCreatorFindAllData: success,
      };
    }
    default:
      return state;
  }
};
