import React, { useEffect, useState } from 'react';
import { FormInputsData } from '../../data/CreateUserData';
import { Tabs } from '../../data/UsersData';
import { ViewFormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { findUser } from '../../redux/users/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { permissionsFindAll } from '../../redux/master/action';
import { Permissions } from '.';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

export const ViewUser = ({ isEdit, isView, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [hiddenKeys, setHiddenKeys] = useState([]);

  const { userFindProcess, userFindError, userFindData, permissionsFindAllData, userInfo } =
    useSelector(({ users, permissions, sign }) => ({
      ...users,
      ...permissions,
      ...sign,
    }));
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const { watch, reset } = form;

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const [formData, setFormData] = useState({
    list: FormInputsData,
    tabs: tabs,
    inProgress: userFindProcess,
    error: (userFindData?.data === null && userFindData?.message) || userFindError?.message,
    isEdit,
    isView,
    value: userFindData?.data,
  });

  useEffect(() => {
    match?.params?.id && dispatch(findUser(match?.params?.id));
    dispatch(permissionsFindAll());
  }, []);

  // User find
  useEffect(() => {
    if (!userFindProcess && !userFindError && userFindData?.data) {
      let permission = [];

      if (permissionsFindAllData?.data?.list?.length) {
        if (userFindData?.data?.permissions_slug) {
          const permissionIds = userFindData?.data?.permissions_slug?.split(',');
          permissionsFindAllData.data.list.forEach((item, index) => {
            permission[item.slug] = permissionIds.indexOf(item.slug.toString()) > -1;
          });
        }
      }

      setFormData((data) => ({
        ...data,
        value: { ...userFindData?.data, permission },
        inProgress: false,
      }));

      if (userFindData?.data?.role_slug === 'admin') {
        setHiddenKeys(() => ['permissions']);
      }
    } else if (!userFindProcess && (userFindError?.message || userFindData?.message)) {
      const error = userFindData?.message || userFindError?.message;
      setFormData((data) => ({ ...data, error, inProgress: false }));
    }
  }, [userFindProcess, userFindError, userFindData, permissionsFindAllData, userInfo]);

  // Edit Button Action
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-user') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      if (userInfo?.data.id === parseInt(match?.params?.id)) {
        Swal.fire({
          title: 'Info',
          text: `You can't update your information here. Please use Profile section to update it`,
          icon: 'warning',
          iconColor: '#3085d6',
        });
        return false;
      }
      match?.params?.id && history.push(`/users/edit/${match?.params?.id}`);
    } else if (value?.button?.key === 'close') {
      history.push(`/users`);
    }
  };

  const PermissionRenderer = () => {
    return (
      <>
        {permissionsFindAllData?.data?.list?.length && (
          <Permissions
            {...{
              permissionsFindAllData,
              form,
              formData,
              isView: true,
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!userFindProcess && (
        <ViewFormCustom
          {...formData}
          clickOnBtn={clickOnBtn}
          renderer={{ Permissions: PermissionRenderer }}
          hiddenKeys={hiddenKeys}
        />
      )}
    </>
  );
};

export default ViewUser;
