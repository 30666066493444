import { getService, postService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Document Types Find All
const startDocumentTypesFindAll = () => ({
  type: Types.START_FIND_DOCUMENT_TYPE,
  payload: {},
});

const endDocumentTypesFindAll = (success, error) => ({
  type: Types.END_FIND_DOCUMENT_TYPE,
  payload: {
    success,
    error,
  },
});

export const documentTypesFindAll = (groupKey) => async (dispatch) => {
  dispatch(startDocumentTypesFindAll());
  const [response, error] = await getService(`${apiList.documentTypes}/${groupKey}`);
  dispatch(endDocumentTypesFindAll(response, error));
};

// Document type create
const startCreateDocumentType = () => ({
  type: Types.START_CREATE_DOCUMENT_TYPE,
  payload: {},
});

const endCreateDocumentType = (success, error) => ({
  type: Types.END_CREATE_DOCUMENT_TYPE,
  payload: {
    success,
    error,
  },
});

export const createDocumentType = (data) => async (dispatch) => {
  dispatch(startCreateDocumentType());
  const [response, error] = await postService(apiList.documentTypes, data);
  dispatch(endCreateDocumentType(response, error));
};
