import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const ProfileData = [
  {
    groupLabel: 'Personal Data',
    groupLabelClass: 'fieldset-label',
    key: 'personal',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'User Avatar',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'user_avatar',
        name: 'profile_image',
        placeholder: '',
        hide: false,
        formControlClass: 'input-form-control file-upload-control',
        imageClass: 'form-control-image display-avatar-logo',
        type: 'file',
        fieldClass: 'input-file-button',
        id: 'company-avatar-upload',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box file-upload-element',
        icon: PhotoCamera,
        accept: 'image/*',
        buttonText: 'Change',
      },
      {
        label: 'First Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        min: 3,
        max: 50,
        errors: [{ type: 'required', message: 'Please enter the first name' }],
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        min: 3,
        max: 50,
        errors: [{ type: 'required', message: 'Please enter the last name' }],
      },
      {
        label: 'Email',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'email',
        name: 'email',
        placeholder: 'Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle border-none',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        readOnly: true,
        isRequired: true,
        pattern: Validation.email,
        errors: [
          { type: 'required', message: 'Please enter the email' },
          { type: 'pattern', message: 'Please enter a valid email' },
        ],
      },
      {
        label: 'Company Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_name',
        name: 'company_name',
        placeholder: 'Company Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text', // labelReadOnly
        fieldClass: 'textBoxStyle border-none',
        className: 'text-muted',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        readOnly: true,
      },
      {
        label: 'Designation',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'designation',
        name: 'designation',
        placeholder: 'Designation',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please enter the designation' }],
      },
      {
        label: 'Phone Number',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        placeholder: 'Phone Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndPlus,
        maxLength: 20,
        minLength: 5,
        errors: [
          { type: 'pattern', message: 'Please enter a valid phone number' },
          { type: 'minLength', message: 'Phone number must be greater than 5 digits'},
        ],
      },
      {
        label: 'Extension',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'extension',
        name: 'extension',
        placeholder: 'Extension',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndSpecial,
        maxLength: 8,
        minLength: 2,
        errors: [
          { type: 'pattern', message: 'Please enter a valid extension' },
          { type: 'minLength', message: 'Extension must be greater than 2 digits'},
        ],
      },
      {
        label: 'Update Profile',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'profile_update_button',
        formControlClass: 'input-form-control',
        type: 'button',
        fieldClass: '',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
];

// update password data
export const PasswordData = [
  {
    groupLabel: 'Change Password',
    groupLabelClass: 'fieldset-label',
    key: 'roles',
    groupClass: 'form-fieldset',
    formClass: 'update-password-card',
    inputs: [
      {
        label: 'Current Password',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'current_password',
        name: 'current_password',
        placeholder: 'Current Password',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'password',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        requiredOnEdit: true,
        pattern: Validation.password,
        min: 4,
        max: 50,
        errors: [
          { type: 'required', message: 'Please enter your current password' },
          {
            type: 'pattern',
            message:
              'Password must be more than 8 characters including uppercase, lowercase, numbers and special characters',
          },
        ],
      },
      {
        label: 'New Password',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'new_password',
        name: 'new_password',
        placeholder: 'New Password',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'password',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        requiredOnEdit: true,
        isRequired: true,
        pattern: Validation.password,
        min: 4,
        max: 50,
        errors: [
          { type: 'required', message: 'Please enter your new password' },
          {
            type: 'pattern',
            message:
              'Password must be more than 8 characters including uppercase, lowercase, numbers and special characters',
          },
        ],
      },
      {
        label: 'Confirm Password',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'confirm_password',
        name: 'confirm_password',
        placeholder: 'Confirm Password',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'password',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        requiredOnEdit: true,
        pattern: Validation.password,
        min: 4,
        max: 50,
        errors: [
          { type: 'required', message: 'Please re-enter your new password' },
          {
            type: 'pattern',
            message:
              'Password must be more than 8 characters including uppercase, lowercase, numbers and special characters',
          },
        ],
      },
      {
        label: 'Update Password',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'password_update_button',
        formControlClass: 'input-form-control',
        type: 'button',
        fieldClass: '',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Profile',
  breadcrumb: [
    { text: 'Dashboard', link: '/positions', className: '' },
    { text: 'Profile', link: '', className: 'active' },
  ],
};

// employee page header
export const EmployeePageHeader = {
  className: 'page-header',
  page_title: 'Profile',
  breadcrumb: [
    { text: 'Dashboard', link: '/employee/profile', className: '' },
    { text: 'Profile', link: '', className: 'active' },
  ],
};
