import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'User Roles',
    groupLabelClass: 'fieldset-label',
    key: 'roles',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'User Type',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'type_slug',
        name: 'type_slug',
        optionsKey: 'userTypes',
        valueId: 'type_slug',
        placeholder: 'User Type',
        displayKey: 'user_type',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        options: [
          { label: 'Client', value: 1 },
          { label: 'Super Admin', value: 2 },
        ],
        isRequired: true,
        errors: [{ type: 'required', message: 'Please choose user type' }],
      },
      {
        label: 'User Role',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'role_slug',
        name: 'role_slug',
        optionsKey: 'userRoles',
        valueId: 'role_slug',
        displayKey: 'user_role',
        placeholder: 'User Role',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        options: [],
        isRequired: true,
        errors: [{ type: 'required', message: 'Please choose user role' }],
        isChangeEvent: true,
      },
    ],
  },
  {
    groupLabel: 'User Information',
    groupLabelClass: 'fieldset-label',
    key: 'personal',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'User Avatar',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'profile_image',
        name: 'profile_image',
        placeholder: '',
        hide: false,
        formControlClass: 'input-form-control file-upload-control',
        type: 'file',
        imageClass: 'form-control-image display-avatar-logo',
        displayImageClass: 'display-avatar-logo',
        fieldClass: 'input-file-button',
        id: 'company-avatar-upload',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box file-upload-element',
        icon: PhotoCamera,
        accept: 'image/*',
        buttonText: 'Change',
      },
      {
        label: 'First Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter first name' },
          { type: 'minLength', message: 'First name must be atleast 3 characters' },
        ],
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter last name' },
          { type: 'minLength', message: 'Last name must be atleast 3 characters' },
        ],
      },
      {
        label: 'Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'email',
        name: 'email',
        placeholder: 'Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.email,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter your email' },
          { type: 'pattern', message: 'Please enter a valid email' },
        ],
      },
      {
        label: 'Company Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_id',
        name: 'company_id',
        optionsKey: 'clients',
        valueId: 'company_id',
        placeholder: 'Company Name',
        displayKey: 'company_name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        options: [],
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the company' }],
      },
      {
        label: 'Partners Account Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'partners_account_id',
        name: 'partners_account_id',
        optionsKey: 'partners',
        valueId: 'partners_account_id',
        placeholder: 'Partners Account Name',
        displayKey: 'account_name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        options: [],
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the Partners account' }],
      },
      {
        label: 'Designation',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'designation',
        name: 'designation',
        placeholder: 'Designation',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Phone Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        placeholder: 'Phone Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndPlus,
        maxLength: 20,
        minLength: 5,
        errors: [
          { type: 'pattern', message: 'Please enter a valid phone number' },
          { type: 'minLength', message: 'Phone number must be greater than 5 digits' },
        ],
      },
      {
        label: 'Extension',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'extension',
        name: 'extension',
        placeholder: 'Extension',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndSpecial,
        maxLength: 8,
        minLength: 2,
        errors: [
          { type: 'pattern', message: 'Please enter a valid extension' },
          { type: 'minLength', message: 'Extension must be greater than 2 digits' },
        ],
      },
      // {
      //   label: 'Password',
      //   xs: 12,
      //   sm: 6,
      //   md: 6,
      //   lg: 4,
      //   xl: 4,
      //   key: 'password',
      //   hiddenKey: 'password',
      //   name: 'password',
      //   placeholder: 'Password',
      //   hide: false,
      //   formControlClass: 'input-form-control',
      //   type: 'password',
      //   fieldClass: 'textBoxStyle',
      //   variant: 'outlined',
      //   margin: 'dense',
      //   formElementClass: 'form-input-box',
      //   isRequired: true,
      //   pattern: Validation.password,
      //   maxLength: 50,
      //   errors: [
      //     { type: 'required', message: 'Please enter your password' },
      //     {
      //       type: 'pattern',
      //       message:
      //         'Password must be more than 8 characters including uppercase, lowercase, numbers and special characters',
      //     },
      //   ],
      // },
    ],
  },

  {
    groupLabel: 'User Permissions',
    groupLabelClass: 'fieldset-label',
    key: 'permissions',
    hiddenKey: 'permissions',
    groupClass: 'form-fieldset permission-fieldset',
    type: '',
    inputs: [
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'Permissions',
      },
    ],
  },
];

export const PermissionInput = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 4,
  name: 'permissions',
  value: '',
  placeholder: '',
  hide: false,
  formControlClass: 'input-form-control',
  type: 'toggle',
  fieldClass: 'textBoxStyle',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box',
  labelPlacement: 'start',
  key: 'Permissions',
  type: 'renderer',
};
