import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HrmsPayroll, AddOrEditPayroll } from '.';
import { ViewPayroll } from '../../common';

export const PayrollRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/hrms/payrolls'} component={HrmsPayroll} />
        <Route path={'/hrms/payrolls/create'} render={(props) => <AddOrEditPayroll {...props} />} />
        <Route
          path={'/hrms/payrolls/view/:id'}
          render={(props) => <ViewPayroll isView={true} {...props} />}
        />
        <Route
          path={'/hrms/payrolls/edit/:id'}
          render={(props) => <AddOrEditPayroll isEdit={true} {...props} />}
        />
      </Switch>
    </>
  );
};
