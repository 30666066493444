import React from 'react';
import { LeavesRouter } from '.';
import { DashboardHeader } from '../../common';
import { PageHeader } from '../../../data/LeavesData';
import { Container } from '@material-ui/core';

export const LeavesMain = () => {
    const headerData = { header: PageHeader };

    return (
        <Container maxWidth="xl">
          <DashboardHeader {...headerData} />
          <LeavesRouter />
        </Container>
      );
}