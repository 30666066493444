export const Types = {
  START_FIND_LEAVE_TYPES: 'START_FIND_LEAVE_TYPES',
  END_FIND_LEAVE_TYPES: 'END_FIND_LEAVE_TYPES',

  START_FIND_ALL_APPROVERS: 'START_FIND_ALL_APPROVERS',
  END_FIND_ALL_APPROVERS: 'END_FIND_ALL_APPROVERS',

  START_CREATE_LEAVE: 'START_CREATE_LEAVE',
  END_CREATE_LEAVE: 'END_CREATE_LEAVE',

  START_FIND_ALL_LEAVES: 'START_FIND_ALL_LEAVES',
  END_FIND_ALL_LEAVES: 'END_FIND_ALL_LEAVES',

  START_FIND_LEAVE: 'START_FIND_LEAVE',
  END_FIND_LEAVE: 'END_FIND_LEAVE',

  START_UPDATE_LEAVE: 'START_UPDATE_LEAVE',
  END_UPDATE_LEAVE: 'END_UPDATE_LEAVE',

  START_LEAVE_ACTION: 'START_LEAVE_ACTION',
  END_LEAVE_ACTION: 'END_LEAVE_ACTION',

  START_FIND_ALL_LEAVE_STATUS: 'START_FIND_ALL_LEAVE_STATUS',
  END_FIND_ALL_LEAVE_STATUS: 'END_FIND_ALL_LEAVE_STATUS',

  START_DELETE_LEAVE: 'START_DELETE_LEAVE',
  END_DELETE_LEAVE: 'END_DELETE_LEAVE',
};
