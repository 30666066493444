import { Types } from './types';

const initState = {
  userTypesFindAllProcess: false,
  userTypesFindAllError: null,
  userTypesFindAllData: null,

  userRolesFindAllProcess: false,
  userRolesFindAllError: null,
  userRolesFindAllData: null,

  permissionsFindAllProcess: false,
  permissionsFindAllError: null,
  permissionsFindAllData: null,

  positionTypesFindAllProcess: false,
  positionTypesFindAllError: null,
  positionTypesFindAllData: null,

  timezonesFindAllProcess: false,
  timezonesFindAllError: null,
  timezonesFindAllData: null,

  invoiceStatusFindAllProcess: false,
  invoiceStatusFindAllError: null,
  invoiceStatusFindAllData: null,

  flipkoinsBankFindProcess: false,
  flipkoinsBankFindError: null,
  flipkoinsBankFindData: null,
};

// User Types
export const userTypes = (state = initState, action) => {
  switch (action.type) {
    case Types.START_USERTYPES_FIND_ALL:
      return {
        ...state,
        userRolesFindAllProcess: false,
        userRolesFindAllError: null,
        userRolesFindAllData: null,

        userTypesFindAllProcess: true,
        userTypesFindAllError: null,
        userTypesFindAllData: null,
      };
    case Types.END_USERTYPES_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        userTypesFindAllProcess: false,
        userTypesFindAllError: error,
        userTypesFindAllData: success,
      };
    }
    default:
      return state;
  }
};

// User Roles
export const userRoles = (state = initState, action) => {
  switch (action.type) {
    case Types.START_USERROLES_FIND_ALL:
      return {
        ...state,
        userRolesFindAllProcess: true,
        userRolesFindAllError: null,
        userRolesFindAllData: null,
      };
    case Types.END_USERROLES_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        userRolesFindAllProcess: false,
        userRolesFindAllError: error,
        userRolesFindAllData: success,
      };
    }
    default:
      return state;
  }
};

// User Permissions
export const permissions = (state = initState, action) => {
  switch (action.type) {
    case Types.START_PERMISSIONS_FIND_ALL:
      return {
        ...state,
        permissionsFindAllProcess: true,
        permissionsFindAllError: null,
        permissionsFindAllData: null,
      };
    case Types.END_PERMISSIONS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        permissionsFindAllProcess: false,
        permissionsFindAllError: error,
        permissionsFindAllData: success,
      };
    }
    default:
      return state;
  }
};

// Position Types
export const positionTypes = (state = initState, action) => {
  switch (action.type) {
    case Types.START_POSITION_TYPES_FIND_ALL:
      return {
        ...state,
        positionTypesFindAllProcess: true,
        positionTypesFindAllError: null,
        positionTypesFindAllData: null,
      };
    case Types.END_POSITION_TYPES_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        positionTypesFindAllProcess: false,
        positionTypesFindAllError: error,
        positionTypesFindAllData: success,
      };
    }
    default:
      return state;
  }
};

// Timezones
export const timezones = (state = initState, action) => {
  switch (action.type) {
    case Types.START_FIND_ALL_TIMEZONES:
      return {
        ...state,
        timezonesFindAllProcess: true,
        timezonesFindAllError: null,
        timezonesFindAllData: null,
      };
    case Types.END_FIND_ALL_TIMEZONES: {
      const { success, error } = action.payload;
      return {
        ...state,
        timezonesFindAllProcess: false,
        timezonesFindAllError: error,
        timezonesFindAllData: success,
      };
    }
    default:
      return state;
  }
};

// invoice status find all
export const invoiceStatus = (state = initState, action) => {
  switch (action.type) {
    case Types.START_FIND_ALL_INVOICE_STATUS:
      return {
        ...state,
        invoiceStatusFindAllProcess: true,
        invoiceStatusFindAllError: null,
        invoiceStatusFindAllData: null,
      };
    case Types.END_FIND_ALL_INVOICE_STATUS: {
      const { success, error } = action.payload;
      return {
        ...state,
        invoiceStatusFindAllProcess: false,
        invoiceStatusFindAllError: error,
        invoiceStatusFindAllData: success,
      };
    }
    default:
      return state;
  }
};