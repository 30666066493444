import React from 'react';
import { ProfileRouter } from '.';
import { DashboardHeader } from '../common';
import { PageHeader, EmployeePageHeader } from '../../data/ProfileData';
import { Container } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

export const ProfileMain = (props) => {
  const { userInfo } = useSelector(({ sign }) => ({
    ...sign,
  }));

  const headerData = {
    header: (userInfo?.data?.type_slug === 'employee' && EmployeePageHeader) || PageHeader,
  };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ProfileRouter />
    </Container>
  );
};
