import { deleteService, getService, postService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

/**
 * Find leave types
 */
const startFindLeaveTypes = () => ({
  type: Types.START_FIND_LEAVE_TYPES,
  payload: {},
});

const endFindLeaveTypes = (success, error) => ({
  type: Types.END_FIND_LEAVE_TYPES,
  payload: {
    success,
    error,
  },
});

export const findLeaveTypes = () => async (dispatch) => {
  dispatch(startFindLeaveTypes);
  const [response, error] = await getService(`${apiList.leaveTypes}`);
  dispatch(endFindLeaveTypes(response, error));
};

/**
 * Find leave approvers
 */
const startFindLeaveApprovers = () => ({
  type: Types.START_FIND_ALL_APPROVERS,
  payload: {},
});

const endFindLeaveApprovers = (success, error) => ({
  type: Types.END_FIND_ALL_APPROVERS,
  payload: {
    success,
    error,
  },
});

export const findLeaveApprovers = () => async (dispatch) => {
  dispatch(startFindLeaveApprovers);
  const [response, error] = await getService(`${apiList.leaveApprovers}`);
  dispatch(endFindLeaveApprovers(response, error));
};

/**
 * Create leave
 */
const startCreateLeave = () => ({
  type: Types.START_CREATE_LEAVE,
  payload: {},
});

const endCreateLeave = (success, error) => ({
  type: Types.END_CREATE_LEAVE,
  payload: {
    success,
    error,
  },
});

export const createLeave = (data) => async (dispatch) => {
  dispatch(startCreateLeave);
  const [response, error] = await postService(apiList.leaves, data);
  dispatch(endCreateLeave(response, error));
};

/**
 * Find all leaves
 */
const startFindAllLeaves = () => ({
  type: Types.START_FIND_ALL_LEAVES,
  payload: {},
});

const endFindAllLeaves = (success, error) => ({
  type: Types.END_FIND_ALL_LEAVES,
  payload: {
    success,
    error,
  },
});

export const findAllLeaves =
  (skip = 0, limit = 10, data) =>
  async (dispatch) => {
    dispatch(startFindAllLeaves);
    const [response, error] = await postService(
      `${apiList.leavesFilter}?skip=${skip}&limit=${limit}`,
      data,
    );
    dispatch(endFindAllLeaves(response, error));
  };

/**
 * Find Leave
 */
const startFindLeave = () => ({
  type: Types.START_FIND_LEAVE,
  payload: {},
});

const endFindLeave = (success, error) => ({
  type: Types.END_FIND_LEAVE,
  payload: {
    success,
    error,
  },
});

export const findLeave = (id) => async (dispatch) => {
  dispatch(startFindLeave);
  const [response, error] = await getService(`${apiList.leaves}/${id}`);
  dispatch(endFindLeave(response, error));
};

/**
 * Update leave
 */
const startUpdateLeave = () => ({
  type: Types.START_UPDATE_LEAVE,
  payload: {},
});

const endUpdateLeave = (success, error) => ({
  type: Types.END_UPDATE_LEAVE,
  payload: {
    success,
    error,
  },
});

export const updateLeave = (id, data) => async (dispatch) => {
  dispatch(startUpdateLeave);
  const [response, error] = await putService(`${apiList.leaves}/${id}`, data);
  dispatch(endUpdateLeave(response, error));
};

/**
 * Leave Action - Approve/Reject
 */
const startLeaveAction = () => ({
  type: Types.START_LEAVE_ACTION,
  payload: {},
});

const endLeaveAction = (success, error) => ({
  type: Types.END_LEAVE_ACTION,
  payload: {
    success,
    error,
  },
});

export const leaveUpdateAction = (param) => async (dispatch) => {
  dispatch(startLeaveAction);
  const [response, error] = await putService(apiList.leaveAction, param);
  dispatch(endLeaveAction(response, error));
};

/**
 * Leave Status Find All
 */
const startFindAllLeaveStatus = () => ({
  type: Types.START_FIND_ALL_LEAVE_STATUS,
  payload: {},
});

const endFindAllLeaveStatus = (success, error) => ({
  type: Types.END_FIND_ALL_LEAVE_STATUS,
  payload: {
    success,
    error,
  },
});

export const leaveStatusFindAll = (groupKey) => async (dispatch) => {
  dispatch(startFindAllLeaveStatus);
  const [response, error] = await getService(`${apiList.statuses}/${groupKey}`);
  dispatch(endFindAllLeaveStatus(response, error));
};

/**
 * Leave Delete
 */
const startDeleteLeave = () => ({
  type: Types.START_DELETE_LEAVE,
  payload: {},
});

const endDeleteLeave = (success, error) => ({
  type: Types.END_DELETE_LEAVE,
  payload: {
    success,
    error,
  },
});

export const deleteLeave = (id) => async (dispatch) => {
  dispatch(startDeleteLeave);
  const [response, error] = await deleteService(`${apiList.leaves}/${id}`);
  dispatch(endDeleteLeave(response, error));
};
