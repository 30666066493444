import React, { useEffect, useState } from 'react';
import { FormInputsData } from '../../../src/data/CreatePartnersData';
import { Tabs } from '../../../src/data/PartnersData';
import { ViewFormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { partnersFind } from '../../../src/redux/partners/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Box } from '@material-ui/core';

export const ViewPartners = ({ isEdit, isView, match, docErrorMessage = 'No document found' }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState([]);

  const { partnersFindError, partnersFindData, partnersFindProcess } = useSelector(
    ({ partners }) => ({
      ...partners,
    }),
  );

  console.log('partners', partnersFindData);

  // useEffect(() => {
  //   dispatch(partnersFind(match?.params?.id));
  // }, []);

  useEffect(() => {
    dispatch(partnersFind(match?.params?.id))
      .then((response) => {
        console.log('API Response:', response);
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
  }, []);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const formData = {
    list: FormInputsData,
    inProgress: partnersFindProcess,
    error:
      (partnersFindData?.data === null && partnersFindData?.message) || partnersFindError?.message,
    isEdit,
    isView,
    value: partnersFindData?.data,
    tabs: tabs,
  };

  console.log('form data', formData);

  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-partners') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/partners/edit/${match?.params?.id}`);
    } else if (value?.button?.key === 'close') {
      history.push(`/partners`);
    }
  };

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        {(partnersFindData?.data?.documents.length > 0 &&
          partnersFindData?.data?.documents?.map((list, inputListIndex) => (
            <Grid container spacing={1} key={inputListIndex}>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  <label className="interview-label">{list.document_type}</label>
                </Box>
              </Grid>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  {list?.document && (
                    <a href={list?.document} target="_blank">
                      {list.document_name}
                    </a>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))) || (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box pl={3}>
                <p className="error">{docErrorMessage}</p>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} renderer={{ UploadDocuments }} />
    </>
  );
};

export default ViewPartners;
