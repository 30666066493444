import { getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Vendor bank detail
const startFindVendorBank = () => ({
  type: Types.START_FIND_VENDOR_BANK_DETAIL,
  payload: {},
});

const endFindVendorBank = (success, error) => ({
  type: Types.END_FIND_VENDOR_BANK_DETAIL,
  payload: {
    success,
    error,
  },
});

// vendor bank detail
export const findVendorBankDetail = () => async (dispatch) => {
  dispatch(startFindVendorBank());
  const [response, error] = await getService(`${apiList.vendorBank}`);
  dispatch(endFindVendorBank(response, error));
};
