import { Types } from './types';

export const openSnackBarLayout = (snackBarMessage, snackBarSeverity, snackBarTime) => ({
  type: Types.START_SNACK_BAR,
  payload: { snackBarMessage, snackBarSeverity, snackBarTime },
});

export const closeSnackBarLayout = () => ({
  type: Types.END_SNACK_BAR,
  payload: {},
});

// sidebar collapsible
export const openSidebar = (status) => ({
  type: Types.START_TOGGLE_SIDEBAR,
  payload: {
    status
  },
});

export const closeSidebar = (status) => ({
  type: Types.END_TOGGLE_SIDEBAR,
  payload: {
    status
  },
});
