export const Types = {
    START_CITY_FIND_ALL: 'START_CITY_FIND_ALL',
    END_CITY_FIND_ALL: 'END_CITY_FIND_ALL',

    START_STATE_FIND_ALL: 'START_STATE_FIND_ALL',
    END_STATE_FIND_ALL: 'END_STATE_FIND_ALL',

    START_COUNTRY_FIND_ALL: 'START_COUNTRY_FIND_ALL',
    END_COUNTRY_FIND_ALL: 'END_COUNTRY_FIND_ALL',

    START_CREATE_COUNTRY: 'START_CREATE_COUNTRY',
    END_CREATE_COUNTRY: 'END_CREATE_COUNTRY',

    START_CREATE_STATE: 'START_CREATE_STATE',
    END_CREATE_STATE: 'END_CREATE_STATE',

    START_CREATE_CITY: 'START_CREATE_CITY',
    END_CREATE_CITY: 'END_CREATE_CITY'
};