import { Types } from './types';

const initState = {
  holidaysFindAllProcess: false,
  holidaysFindAllError: null,
  holidaysFindAllData: null,

  holidayCreateProcess: false,
  holidayCreateError: null,
  holidayCreateData: null,

  holidayFindProcess: false,
  holidayFindError: null,
  holidayFindData: null,

  holidayUpdateProcess: false,
  holidayUpdateError: null,
  holidayUpdateData: null,

  holidayDeleteProcess: false,
  holidayDeleteError: null,
  holidayDeleteData: null,

  holidaysNoteFindProcess: false,
  holidaysNoteFindError: null,
  holidaysNoteFindData: null,

  holidaysNoteUpdateProcess: false,
  holidaysNoteUpdateError: null,
  holidaysNoteUpdateData: null,
};

export const holidays = (state = initState, action) => {
  switch (action.type) {
    // holidays find all
    case Types.START_FIND_ALL_HOLIDAYS:
      return {
        ...state,
        holidaysFindAllProcess: true,
        holidaysFindAllError: null,
        holidaysFindAllData: null,
      };
    case Types.END_FIND_ALL_HOLIDAYS: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidaysFindAllProcess: false,
        holidaysFindAllError: error,
        holidaysFindAllData: success,
      };
    }

    // create holiday
    case Types.START_CREATE_HOLIDAY:
      return {
        ...state,
        holidayCreateProcess: true,
        holidayCreateError: null,
        holidayCreateError: null,
      };
    case Types.END_CREATE_HOLIDAY: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidayCreateProcess: false,
        holidayCreateError: error,
        holidayCreateData: success,
      };
    }

    // find holiday
    case Types.START_FIND_HOLIDAY:
      return {
        ...state,
        holidayFindProcess: true,
        holidayFindError: null,
        holidayFindData: null,
      };
    case Types.END_FIND_HOLIDAY: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidayFindProcess: false,
        holidayFindError: error,
        holidayFindData: success,
      };
    }

    // update holiday
    case Types.START_UPDATE_HOLIDAY:
      return {
        ...state,
        holidayUpdateProcess: true,
        holidayUpdateError: null,
        holidayUpdateData: null,
      };
    case Types.END_UPDATE_HOLIDAY: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidayUpdateProcess: false,
        holidayUpdateError: error,
        holidayUpdateData: success,
      };
    }

    // delete holiday
    case Types.START_DELETE_HOLIDAY:
      return {
        ...state,
        holidayDeleteProcess: true,
        holidayDeleteError: null,
        holidayDeleteData: null,
      };
    case Types.END_DELETE_HOLIDAY: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidayDeleteProcess: false,
        holidayDeleteError: error,
        holidayDeleteData: success,
      };
    }

    // holidays note find
    case Types.START_FIND_HOLIDAY_NOTE:
      return {
        ...state,
        holidaysNoteFindProcess: true,
        holidaysNoteFindError: null,
        holidaysNoteFindData: null,
      };
    case Types.END_FIND_HOLIDAY_NOTE: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidaysNoteFindProcess: false,
        holidaysNoteFindError: error,
        holidaysNoteFindData: success,
      };
    }

    // holidays note update
    case Types.START_UPDATE_HOLIDAY_NOTE:
      return {
        ...state,
        holidaysNoteUpdateProcess: true,
        holidaysNoteUpdateError: null,
        holidaysNoteUpdateData: null,
      };
    case Types.END_UPDATE_HOLIDAY_NOTE: {
      const { success, error } = action.payload;
      return {
        ...state,
        holidaysNoteUpdateProcess: false,
        holidaysNoteUpdateError: error,
        holidaysNoteUpdateData: success,
      };
    }
    default:
      return state;
  }
};
