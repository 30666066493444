import React from 'react';
import { Tabs, LeavesHead, ResponseData, HrmsLeavesTabs } from '../../data/LeavesData';
import { TabCustom, TableCustom, FilterCustom } from '.';
import { commonFn } from '../../util/commonFn';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { deleteLeave } from '../../redux/leaves/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const Leaves = ({
  match,
  data = [],
  head = [],
  tabs = [],
  module,
  count = 0,
  pagination = [],
  skip = 0,
  errorMessage,
  hiddenKeys = [],
  inProgress,
  filterData = [],
  filterFormSubmit = () => {},
  changeEvent = () => {},
  clickOnCell = () => {},
  hiddenAction = [],
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  // extract leaves data
  const leavesData =
    data &&
    data.map((leave) => {
      const approverEmail =
        leave?.approver_email &&
        leave?.approver_email.split(',').map((value) => {
          return { approverEmail: value };
        });

      const status = leave?.rejected_by_email
        ? 'Rejected'
        : leave?.approved_by_email
        ? 'Approved'
        : 'Pending';

      const statusAction = [
        { text: leave?.status },
        { text: leave?.approved_by_email ? `Approved By: ` + leave?.approved_by_email : '' },
        { text: leave?.rejected_by_email ? `Rejected By: ` + leave?.rejected_by_email : '' },
      ];
      const statusResult = statusAction.filter((value) => {
        return value.text;
      });
      return { ...leave, status: statusResult, approver_email: approverEmail };
    });

  // Table data
  const tableData = {
    head: head || [],
    count: count || 0,
    data: leavesData || [],
    skip: skip,
    clickOnCell: clickOnCell,
    pagination: pagination || [],
    errorMessage: errorMessage || 'No data found',
    hiddenKeys: hiddenKeys || [],
    hiddenAction: hiddenAction,
  };

  return (
    <>
      <TabCustom {...tabs} />

      {module === 'hrms' && (
        <Box mt={2} mb={4}>
          <FilterCustom
            {...filterData}
            formSubmit={filterFormSubmit}
            hiddenKeys={hiddenKeys}
            changeEvent={changeEvent}
          />
        </Box>
      )}

      {inProgress && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}

      {!inProgress && (
        <Box mb={4}>
          <TableCustom {...tableData} />
        </Box>
      )}
    </>
  );
};
