import React from 'react';
import { ProfileRouter } from '.';
import { DashboardHeader } from '../../common';
import { EmployeeProfilePageHeader } from '../../../data/EmployeeData';
import { Container } from '@material-ui/core';

export const EmployeeProfileMain = () => {
    const headerData = { header: EmployeeProfilePageHeader };

    return (
      <Container maxWidth="xl">
        <DashboardHeader {...headerData} />
        <ProfileRouter />
      </Container>
    );
};
