import { Edit, Info, Email, Print, Delete } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    profile_image: 'JD',
    company_logo: '',
    name: 'John Doe',
    from: 'Jan 01, 2021',
    to: 'Jan 05, 2021',
    total_days: 5,
    status: 'Approved',
    approver: 'HR',
  },
  {
    id: 2,
    profile_image: 'RN',
    company_logo: '',
    name: 'Raghu Nathan',
    from: 'Feb 10, 2021',
    to: 'Feb 12, 2021',
    total_days: 5,
    status: 'Pending',
    approver: 'HR',
  },
  {
    id: 3,
    profile_image: 'JD',
    company_logo: '',
    name: 'John Doe',
    from: 'Jan 01, 2021',
    to: 'Jan 05, 2021',
    total_days: 5,
    status: 'Approved',
    approver: 'HR',
  },
];

// holidays table header
export const LeavesHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'profile_image',
    hiddenKey: 'profile_image',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo profile-image',
  },
  {
    label: 'Company',
    key: 'company_logo',
    hiddenKey: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-100',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo profile-image',
  },
  {
    label: 'Name',
    key: 'full_name',
    hiddenKey: 'full_name',
    hide: false,
    headClass: 'w-200',
    type: 'text',
    link: '',
  },
  {
    label: 'Leave Type',
    key: 'leave_type',
    hiddenKey: 'leave_type',
    hide: false,
    headClass: 'w-150',
    type: 'text',
    link: '',
  },
  {
    label: 'From',
    key: 'from_date',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'To',
    key: 'to_date',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'No. of Days',
    key: 'total_days',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: '',
  },
  {
    label: 'Status',
    key: 'status',
    hide: false,
    type: 'array',
    headClass: 'w-200',
    keys: [
      { key: 'text', hide: false, arrayClass: 'badge-outlined badge-warning leave-status-badge' },
    ],
    bodyClass: '',
  },
  {
    label: 'Approver',
    key: 'approver_email',
    hide: false,
    type: 'array',
    headClass: 'w-200',
    bodyClass: '',
    keys: [
      {
        key: 'approverEmail',
        hide: false,
        arrayClass: 'badge-outlined badge-info approver-emails',
      },
    ],
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper edit-icon',
        key: 'edit'
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: Delete,
        text: 'Delete',
        click: '',
        url: '',
        actionClass: 'icon-wrapper delete-icon',
        key: 'delete'
      },
    ],
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Leaves',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Leaves', link: '', className: 'active' },
  ],
};

// Hrms page header
export const HrmsPageHeader = {
  className: 'page-header',
  page_title: 'Leaves',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Leaves', link: '', className: 'active' },
  ],
};

// Hrms page header
export const EmployeePageHeader = {
  className: 'page-header',
  page_title: 'Leaves',
  button: {
    text: 'Apply For Leave',
    className: 'link-button btn-sm btn-primary',
    url: '/employee/leaves/create',
    hiddenKey: 'create-client',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/employee/profile', className: '' },
    { text: 'Leaves', link: '', className: 'active' },
  ],
};

// HRMS Tabs data
export const HrmsLeavesTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Edit Employee Leave',
      key: 'edit',
      link: '/hrms/leaves/edit/:id',
      path: '/hrms/leaves/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-leave',
    },
    {
      label: 'View Employee Leave',
      key: 'view',
      link: '/hrms/leaves/view/:id',
      path: '/hrms/leaves/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
    {
      label: 'Employee Leaves',
      key: 'list-leaves',
      link: '/hrms/leaves',
      path: '/hrms/leaves',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Edit Employee Leave',
      key: 'edit',
      link: '/company/leaves/edit/:id',
      path: '/company/leaves/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-leave',
    },
    {
      label: 'View Employee Leave',
      key: 'view',
      link: '/company/leaves/view/:id',
      path: '/company/leaves/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
    {
      label: 'Employee Leaves',
      key: 'list-leaves',
      link: '/company/leaves',
      path: '/company/leaves',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
  ],
};

// Employee Leaves Tabs data
export const EmployeeTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Leave',
      key: 'create',
      link: '/employee/leaves/create',
      hiddenKey: 'create-leave',
      path: '/employee/leaves/create',
      className: 'tab-link',
    },
    {
      label: 'Edit Leave',
      key: 'edit',
      link: '/employee/leaves/edit/:id',
      path: '/employee/leaves/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-leave',
    },
    {
      label: 'View Leave',
      key: 'view',
      link: '/employee/leaves/view/:id',
      path: '/employee/leaves/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
    {
      label: 'Leaves',
      key: 'list-leaves',
      link: '/employee/leaves',
      path: '/employee/leaves',
      className: 'tab-link',
      hiddenKey: 'list-leaves',
    },
  ],
};

// main filter - Leaves filter
export const MainFilter = {
  filterClass: 'leaves-filter',
  list: [
    {
      label: 'Search',
      placeholder: 'Search',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'search',
      key: 'search',
      variant: 'outlined',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'Client',
      placeholder: 'Select Client(s)',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      name: 'client_id',
      key: 'clients',
      valueId: 'client_id',
      optionsKey: 'clients',
      hiddenKey: 'client_id',
      size: 'small',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [],
    },
    {
      label: 'Status',
      placeholder: 'Select Status',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      margin: 'dense',
      hide: false,
      type: 'select',
      size: 'small',
      name: 'status_id',
      key: 'leaveStatus',
      valueId: 'status_id',
      optionsKey: 'leaveStatus',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [],
    },
  ],
};

// Employee Leaves Tabs data
// export const EmployeeTabs = {
//   tabClass: 'dashboard-tab',
//   list: [
//     {
//       label: 'Leaves',
//       key: 'list-leaves',
//       link: '/employee/leaves',
//       path: '/employee/leaves',
//       className: 'tab-link',
//       hiddenKey: 'employee-leaves',
//     },
//     {
//       label: 'View Leave',
//       key: 'view',
//       link: '/employee/leaves/view/:id',
//       path: '/employee/leaves/view/:id',
//       className: 'tab-link',
//       hiddenKey: 'list-employees',
//     },
//     {
//       label: 'Edit Leave',
//       key: 'edit',
//       link: '/employee/leaves/edit/:id',
//       path: '/employee/leaves/edit/:id',
//       className: 'tab-link',
//       hiddenKey: 'edit-leaves',
//     },
//     // {
//     //   label: 'Edit Employee Leave',
//     //   key: 'edit',
//     //   link: '/company/leaves/edit/:id',
//     //   path: '/company/leaves/edit/:id',
//     //   className: 'tab-link',
//     //   hiddenKey: 'edit-employee-leave',
//     // },
    
//   ],
// };
