import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyHolidays } from '.';

export const HolidaysRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/company/holidays'} component={CompanyHolidays} />
      </Switch>
    </>
  );
};
