import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';

export const TabCustom = ({ list = [], tabClass = '' } = {}) => {
  const location = useLocation();
  const currentUrl = location.pathname;

  return (
    <div className={` ${tabClass}`}>
      {list?.map(
        (tab, index) =>
          !tab?.hide &&
          commonFn.menuHiddenKeys.indexOf(tab?.hiddenKey) === -1 && (
            <Link
              to={tab?.link && tab?.link}
              className={tab?.className + (currentUrl === tab?.link ? ' active' : '')}
              key={index}
            >
              {tab?.label}
            </Link>
          ),
      )}
    </div>
  );
};
