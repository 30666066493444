import { getService, postService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Create employee
const startCreateEmployee = () => ({
  type: Types.START_CREATE_EMPLOYEE,
  payload: {},
});

const endCreateEmployee = (success, error) => ({
  type: Types.END_CREATE_EMPLOYEE,
  payload: {
    success,
    error,
  },
});

export const createEmployee = (data) => async (dispatch) => {
  dispatch(startCreateEmployee());
  const [response, error] = await postService(apiList.employees, data);
  dispatch(endCreateEmployee(response, error));
};

// Employee Find All
const startEmployeeFindAll = () => ({
  type: Types.START_EMPLOYEE_FIND_ALL,
  payload: {},
});

const endEmployeeFindAll = (success, error) => ({
  type: Types.END_EMPLOYEE_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const employeeFindAll =
  (skip = 0, limit = 1000, data) =>
  async (dispatch) => {
    dispatch(startEmployeeFindAll());
    const [response, error] = await postService(
      `${apiList.employeesFilter}?skip=${skip}&limit=${limit}`,
      data,
    );
    dispatch(endEmployeeFindAll(response, error));
  };

// Employee Find
const startEmployeeFind = () => ({
  type: Types.START_EMPLOYEE_FIND,
  payload: {},
});

const endEmployeeFind = (success, error) => ({
  type: Types.END_EMPLOYEE_FIND,
  payload: {
    success,
    error,
  },
});

export const employeeFind = (id) => async (dispatch) => {
  dispatch(startEmployeeFind());
  const [response, error] = await getService(`${apiList.employees}/${id}`);
  dispatch(endEmployeeFind(response, error));
};

// Employee Update
const startEmployeeUpdate = () => ({
  type: Types.START_EMPLOYEE_UPDATE,
  payload: {},
});

const endEmployeeUpdate = (success, error) => ({
  type: Types.END_EMPLOYEE_UPDATE,
  payload: {
    success,
    error,
  },
});

export const updateEmployee = (id, data) => async (dispatch) => {
  dispatch(startEmployeeUpdate());
  const [response, error] = await putService(`${apiList.employees}/${id}`, data);
  dispatch(endEmployeeUpdate(response, error));
};

// Employee Profile Action - Invoke/Revoke
const startEmployeeProfileAction = () => ({
  type: Types.START_EMPLOYEE_PROFILE_ACTION,
  payload: {},
});

const endEmployeeProfileAction = (success, error) => ({
  type: Types.END_EMPLOYEE_PROFILE_ACTION,
  payload: {
    success,
    error,
  },
});

export const employeeProfileAction = (data) => async (dispatch) => {
  dispatch(startEmployeeProfileAction());
  const [response, error] = await putService(`${apiList.employeeProfileAction}`, data);
  dispatch(endEmployeeProfileAction(response, error));
};

// Employee Leave - Leave Type
const startEmployeeRemainingLeave = () => ({
  type: Types.START_FIND_REMAINING_LEAVE,
  payload: {},
});

const endEmployeeRemainingLeave = (success, error) => ({
  type: Types.END_FIND_REMAINING_LEAVE,
  payload: {
    success,
    error,
  },
});

export const employeeRemainingLeave = (slug) => async (dispatch) => {
  dispatch(startEmployeeRemainingLeave());
  const [response, error] = await getService(`${apiList.employeeRemainingLeave}/${slug}`);
  dispatch(endEmployeeRemainingLeave(response, error));
};
