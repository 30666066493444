import React, { useEffect, useState } from 'react';
import { ViewFormCustom, TabCustom } from '../common';
import { useHistory, useLocation } from 'react-router-dom';
import { HrmsPayrollTabs, EmployeeTabs } from '../../data/PayrollData';
import { commonFn } from '../../util/commonFn';
import { FormInputsData, PayslipUpload } from '../../data/CreatePayrollData';
import { findPayroll } from '../../redux/payrolls/action';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const ViewPayroll = ({ isEdit, isView, match, module }) => {
  let tabsData = [];
  const { push, history } = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hiddenKeys, setHiddenKeys] = useState([]);

  if (location?.pathname.indexOf('hrms') !== -1) {
    module = 'hrms';
    tabsData = HrmsPayrollTabs;
  } else if (location?.pathname.indexOf('employee') !== -1) {
    tabsData = EmployeeTabs;
    module = 'employee';
  }

  const { payrollFindProcess, payrollFindError, payrollFindData } = useSelector(({ payrolls }) => ({
    ...payrolls,
  }));

  // Set page title
  useEffect(() => {
    if (module && module === 'employee') {
      document.title = 'Quark - Employee | Payrolls';
    } else if (module && module === 'hrms') {
      document.title = 'Quark - HRMS | Employee Payrolls';
    }
  }, []);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  // set hidden keys
  useEffect(() => {
    if (module === 'employee') {
      setHiddenKeys(() => ['employee_detail', 'comment']);
    }
  }, [module]);

  // find leave
  useEffect(() => {
    dispatch(findPayroll(match?.params?.id));
  }, [match?.params?.id]);

  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (module === 'hrms') {
        push(`/hrms/payrolls/edit/${match?.params?.id}`);
      } else if (module === 'employee') {
        push(`/employee/payrolls/edit/${match?.params?.id}`);
      }
    } else if (value?.button?.key === 'close') {
      if (module === 'hrms') {
        push(`/hrms/payrolls`);
      } else if (module === 'employee') {
        push(`/employee/payrolls`);
      }
    }
  };

  //   form data
  const formData = {
    list: FormInputsData,
    inProgress: payrollFindProcess ? true : false,
    error: (!payrollFindData?.data && payrollFindData?.message) || payrollFindError?.message,
    isEdit,
    isView,
    value: payrollFindData?.data || {},
    tabs: tabs,
    module: module,
    hiddenKeys: hiddenKeys,
    hiddenButton: module === 'employee' && ['edit'] || [],
  };

  //   view payslip
  const UploadPayslip = () => {
    return (
      <>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={PayslipUpload?.xs || 12}
                sm={PayslipUpload?.sm || 12}
                md={PayslipUpload?.md || 6}
                lg={PayslipUpload?.lg || 4}
                xl={PayslipUpload?.xl || 4}
              >
                <Box className={PayslipUpload?.formElementClass}>
                  <label className="interview-label">{PayslipUpload.label}</label>
                  {formData.value?.payslip && (
                    <div className="file-section">
                      <a
                        href={formData.value?.payslip}
                        target="_blank"
                        className={PayslipUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.value?.payslip_name}
                      </a>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
      </>
    );
  };
  // End upload resume

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom
        {...formData}
        renderer={{
          UploadPayslip,
        }}
        clickOnBtn={clickOnBtn}
      />
    </>
  );
};
