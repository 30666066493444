import React from 'react';
import { LeavesRouter } from '.';
import { DashboardHeader } from '../../common';
import { EmployeePageHeader } from '../../../data/LeavesData';
import { Container } from '@material-ui/core';

export const EmployeeLeavesMain = () => {
  const headerData = { header: EmployeePageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <LeavesRouter />
    </Container>
  );
};
