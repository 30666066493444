import React from 'react';
import { PartnersRouter } from '.';
import { DashboardHeader } from '../common';
import { PageHeader } from '../../data/PartnersData';
import { Container } from '@material-ui/core';

export const PartnersMain = () => {
  const headerData = { header: PageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <PartnersRouter />
    </Container>
  );
};
