import { baseApiServer } from './environment';

export const apiList = {
  login: `${baseApiServer('USERS')}/login`,
  forgotPassword: `${baseApiServer('USERS')}/forgot-password`,
  resetPassword: `${baseApiServer('USERS')}/reset-password`,
  logout: `${baseApiServer('USERS')}/logout`,
  verify: `${baseApiServer('USERS')}/verify`,
  isAuth: `${baseApiServer('USERS')}/is-auth`,
  users: `${baseApiServer('USERS')}`,

  clients: `${baseApiServer('CLIENTS')}`,

  userTypes: `${baseApiServer('USERTYPES')}`,
  userRoles: `${baseApiServer('USERROLES')}`,
  permissions: `${baseApiServer('PERMISSIONS')}`,
  positionTypes: `${baseApiServer('POSITION_TYPES')}`,
  skills: `${baseApiServer('SKILLS')}`,
  locations: `${baseApiServer('LOCATIONS')}`,
  positionStatus: `${baseApiServer('POSITION_STATUS')}`,
  timezones: `${baseApiServer('TIMEZONES')}`,

  positions: `${baseApiServer('POSITIONS')}`,

  applicants: `${baseApiServer('APPLICANTS')}`,

  positionsByClient: `${baseApiServer('POSITIONS_BY_CLIENT')}`,
  applicantSources: `${baseApiServer('APPLICANT_SOURCES')}`,

  userProfile: `${baseApiServer('USER_PROFILE')}`,
  userPassword: `${baseApiServer('USER_PASSWORD')}`,

  positionsFilter: `${baseApiServer('POSITIONS_FILTER')}`,
  applicantsFilter: `${baseApiServer('APPLICANTS_FILTER')}`,

  fileUpload: `${baseApiServer('FILE_UPLOAD')}`,
  cities: `${baseApiServer('CITIES')}`,
  states: `${baseApiServer('STATES')}`,
  countries: `${baseApiServer('COUNTRIES')}`,
  interview_status: `${baseApiServer('INTERVIEW_STATUS')}`,

  employees: `${baseApiServer('EMPLOYEES')}`,

  documentTypes: `${baseApiServer('DOCUMENT_TYPES')}`,

  clientProfile: `${baseApiServer('CLIENT_PROFILE')}`,
  invoices: `${baseApiServer('INVOICES')}`,
  invoiceStatus: `${baseApiServer('INVOICE_STATUS')}`,
  holidays: `${baseApiServer('HOLIDAYS')}`,
  holidaysNote: `${baseApiServer('HOLIDAYS_NOTE')}`,
  vendorBank: `${baseApiServer('VENDOR_BANK')}`,
  sendInvoice: `${baseApiServer('SEND_INVOICE')}`,

  leaveTypes: `${baseApiServer('LEAVE_TYPES')}`,
  leaveApprovers: `${baseApiServer('LEAVE_APPROVERS')}`,
  leaves: `${baseApiServer('LEAVES')}`,
  leaveAction: `${baseApiServer('LEAVE_ACTION')}`,
  statuses: `${baseApiServer('STATUSES')}`,

  employeeProfile: `${baseApiServer('EMPLOYEE_PROFILE')}`,

  payrolls: `${baseApiServer('PAYROLL')}`,
  employeesFilter: `${baseApiServer('EMPLOYEES_FILTER')}`,
  invoicesFilter: `${baseApiServer('INVOICES_FILTER')}`,
  invoiceCreators: `${baseApiServer('INVOICE_CREATORS')}`,
  leavesFilter: `${baseApiServer('LEAVES_FILTER')}`,
  payrollsFilter: `${baseApiServer('PAYROLLS_FILTER')}`,
  employeeProfileAction: `${baseApiServer('EMPLOYEE_PROFILE_ACTION')}`,
  employeeRemainingLeave: `${baseApiServer('EMPLOYEE_REMAINING_LEAVE')}`,

  reimbursements: `${baseApiServer('REIMBURSEMENTS')}`,

  partners: `${baseApiServer('PARTNERS')}`,
  partnersProfile: `${baseApiServer('PARTNERS_PROFILE')}`,

  //partners: `http://localhost:3000/api/v1/partners`,
};
