import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { PartnersMain } from '../partners';

import { NotFound } from '../common';
import { PartnersProfileMain } from './profile';

export const PartnersLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/partners/profile`} component={PartnersProfileMain} />
        <Route path={`/partners`} component={PartnersMain} />

        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
