import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Partners, CreatePartners, ViewPartners } from '.';
import { commonFn } from '../../util/commonFn';
import { NotFound } from '../common';

export const PartnersRouter = (props) => {
  return (
    <>
      <Switch>
        {commonFn.menuHiddenKeys.indexOf('list-partners') === -1 && (
          <Route exact path={'/partners'} component={Partners} />
        )}
        {commonFn.menuHiddenKeys.indexOf('create-partners') === -1 && (
          <Route exact path={'/partners/create'} component={CreatePartners} />
        )}
        {commonFn.menuHiddenKeys.indexOf('list-partners') === -1 && (
          <Route
            path={'/partners/view/:id'}
            render={(props) => <ViewPartners isView={true} {...props} />}
          />
        )}
        {commonFn.menuHiddenKeys.indexOf('edit-partners') === -1 && (
          <Route
            path={'/partners/edit/:id'}
            render={(props) => <CreatePartners isEdit={true} {...props} />}
          />
        )}
        {/* {commonFn.menuHiddenKeys.indexOf('partners-profile') === -1 && (
          <Route
            path={'/partners/edit/:id'}
            render={(props) => <CreatePartners isEdit={true} {...props} />}
          />
        )} */}
        {/* <Route exact path={'/partners'} component={Profile} /> */}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
