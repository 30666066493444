import React from 'react';
import { ApplicantsRouter } from '.';
import { DashboardHeader } from '../common';
import { PageHeader } from '../../data/ApplicantsData';
import { Container } from '@material-ui/core';

export const ApplicantsMain = () => {
  const headerData = { header: PageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ApplicantsRouter />
    </Container>
  );
};
