import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { Card, CardContent } from '@material-ui/core';
import {
  TextField,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  ListItemText,
  Box,
  Grid,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MultiSelect from 'react-select';
import SingleSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { useForm, Controller } from 'react-hook-form';
import { commonFn } from '../../util/commonFn';

import PrimaryButton from './PrimaryButton';
import './Filter.scss';

const autoCompleteFilter = createFilterOptions();

export const FilterCustom = ({
  filter = [],
  filterClass = '',
  formSubmit = () => {},
  options = {},
  values = {},
  hiddenKeys = [],
  changeEvent = () => {},
} = {}) => {
  const [positionType, setPositionType] = useState([]);
  const [value, setValue] = React.useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  const onChangeValue = (data) => {
    changeEvent(data);
  };

  return (
    <>
      <Accordion className={'filter-accordion'}>
        {/* <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-header"
        >
          <Box px={2} className="accordion-title">
            <h4>Filter </h4>
          </Box>
        </AccordionSummary> */}

        <AccordionDetails className={'accordion-content'}>
          <form onSubmit={handleSubmit(formSubmit)} autoComplete={'off'}>
            <Card className={filter.filterClass} variant="outlined">
              <CardContent>
                <Grid container spacing={1}>
                  {filter.list?.map((itemVal, index) => {
                    if (hiddenKeys.indexOf(itemVal.hiddenKey) !== -1) {
                      return null;
                    }

                    return (
                      <Grid
                        key={index}
                        item
                        xs={itemVal?.xs || 12}
                        sm={itemVal?.sm || 12}
                        md={itemVal?.md || 4}
                        lg={itemVal?.lg || 2}
                        xl={itemVal?.xl || 2}
                      >
                        <>
                          {(itemVal?.type === 'text' || itemVal?.type === 'date') && (
                            <>
                              <label className={itemVal?.labelClass}> {itemVal?.label} </label>
                              <Box pt={1}>
                                <FormControl
                                  variant={itemVal?.variant}
                                  className={itemVal?.formControlClass}
                                >
                                  <TextField
                                    {...register(itemVal?.name)}
                                    className={itemVal?.fieldClass}
                                    placeholder={itemVal?.placeholder}
                                    variant={itemVal?.variant}
                                    margin={itemVal?.margin}
                                    defaultValue={values?.[itemVal?.valueKey] || []}
                                    required={itemVal?.isRequired}
                                    autoComplete={'new-password'}
                                    type={itemVal?.type}
                                  />
                                </FormControl>
                              </Box>
                            </>
                          )}

                          {/* autocompleteMultiSelect skill, position type and company */}
                          {(itemVal?.type === 'multiselect' || itemVal?.type === 'select') && (
                            <>
                              <label className={itemVal?.labelClass}> {itemVal?.label} </label>
                              <Box pt={1}>
                                <FormControl
                                  variant={itemVal?.variant}
                                  margin={itemVal?.margin}
                                  className={itemVal?.formControlClass}
                                >
                                  <MultiSelect
                                    isMulti={itemVal?.type === 'multiselect' ? true : false}
                                    components={makeAnimated}
                                    isClearable={true}
                                    menuPosition="fixed"
                                    name={itemVal?.name}
                                    defaultValue={commonFn.getMultiValueFromId(
                                      values?.[itemVal?.valueId],
                                      options?.[itemVal?.optionsKey],
                                    )}
                                    placeholder={itemVal?.placeholder}
                                    options={options?.[itemVal?.optionsKey] || []}
                                    className={itemVal?.fieldClass}
                                    onChange={(value) => {
                                      onChangeValue({ value: value, field: itemVal });
                                    }}
                                  />
                                </FormControl>
                              </Box>
                            </>
                          )}

                          {/* For autocomplete */}
                          {itemVal?.type === 'autocomplete' && (
                            <>
                              <label className={itemVal?.labelClass}> {itemVal?.label} </label>
                              <Box pt={1}>
                                <FormControl
                                  variant={itemVal?.variant}
                                  margin={itemVal?.margin}
                                  className={itemVal?.formControlClass}
                                >
                                  <Autocomplete
                                    value={value}
                                    size={itemVal?.size}
                                    className={itemVal?.fieldClass}
                                    onChange={(event, newValue) => {
                                      if (typeof newValue === 'string') {
                                        setValue({
                                          title: newValue,
                                        });
                                      } else {
                                        setValue(newValue);
                                      }
                                    }}
                                    // Filter values from list
                                    filterOptions={(options, params) => {
                                      const filtered = autoCompleteFilter(options, params);
                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    options={itemVal?.options}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }

                                      // Add "xxx" option created dynamically
                                      if (option?.inputValue) {
                                        return option.inputValue;
                                      }

                                      // Regular option
                                      return option?.title;
                                    }}
                                    renderOption={(option) => option.title}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={itemVal?.label}
                                        variant={itemVal?.variant}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </Box>
                            </>
                          )}
                        </>
                      </Grid>
                    );
                  })}

                  <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Box className="mt-36">
                      <PrimaryButton variant="outlined" text="Filter" />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
