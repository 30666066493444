import React, { useState, useEffect } from 'react';
import { Tabs, EmployeeHead, ResponseData, MainFilter } from '../../../data/EmployeeData';
import { TabCustom, TableCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { useHistory } from 'react-router-dom';
import { employeeFindAll } from '../../../redux/hrms/action';
import { useDispatch, useSelector } from 'react-redux';
import { Employees } from '../../common';
import { CircularProgress } from '@material-ui/core';
import { skillsFindAll } from '../../../redux/skills/action';

export const CompanyEmployees = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [hiddenColumn, setHiddenColumn] = useState([]);
  const [filterValues, setFilterValues] = useState([]);

  const { employeeFindAllProcess, employeeFindAllError, employeeFindAllData, skillsFindAllData } =
    useSelector(({ employees, skills }) => ({
      ...employees,
      ...skills,
    }));

  // Tabs
  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match?.match),
  };

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Company | Employees';
  }, []);

  // employees find all
  useEffect(() => {
    dispatch(employeeFindAll(skip, limit));
    dispatch(skillsFindAll());
  }, [skip]);

  // set hidden keys
  useEffect(() => {
    if (employeeFindAllData) {
      setHiddenColumn(() => ['company_logo', 'client_id']);
    }
  }, [employeeFindAllData]);

  // extract employee data from API response
  const empResponse = employeeFindAllData?.data?.list;
  const employeeData =
    empResponse &&
    empResponse.map((emp) => {
      const text = [{ text: emp?.full_name }, { text: emp?.company_email }];

      const skill =
        emp?.skills &&
        emp?.skills.split(',').map((value) => {
          return { skill: value };
        });

      return { ...emp, full_name: text, skills: skill };
    });

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
    dispatch(employeeFindAll(limit * (value - 1), limit));
  };

  // extract skills from api response
  const skills =
    skillsFindAllData &&
    skillsFindAllData?.data?.list?.map((skill) => {
      return { label: skill?.skill, key: skill?.skill, value: skill?.id };
    });

  // filter data
  const filterData = {
    filter: MainFilter,
    options: { skills },
  };

  // change event
  const changeEvent = (data) => {
    const filterArray = {};
    if (data?.field?.name === 'skill_id') {
      const skills = commonFn.getIdFromMultiValue(data?.value);
      filterArray.skill_id = skills;
    }

    setFilterValues((filterValue) => ({
      ...filterValue,
      ...filterArray,
    }));
  };

  // filter form submit
  const formSubmit = (data) => {
    const request = data;
    request.skill_id = filterValues.skill_id;
    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(employeeFindAll(0, limit, { ...filterValues, ...request }));
  };

  // Table data
  const tableData = {
    tabs: tabs,
    module: 'company',
    count: employeeFindAllData?.data?.count,
    skip: skip,
    data: employeeData,
    head: EmployeeHead,
    pagination: {
      numberOfPages: employeeFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    hiddenKeys: hiddenColumn,
    errorMessage: employeeFindAllError?.message,
    inProgress: employeeFindAllProcess,
    filterData: filterData || {},
    formSubmit: formSubmit,
    changeEvent: changeEvent,
  };

  return (
    <>
      <Employees {...tableData} />
    </>
  );
};
