import React from 'react';
import styled from 'styled-components/macro';

let Heading = styled.h2`
  text-align: center;
  margin-top: 50px;
`;

export const NotFound = () => {
  return <Heading> Please request permissions from Admin</Heading>;
};
