import React from 'react';
import { ReimbursementRouter } from '.';
import { DashboardHeader } from '../../common';
import { EmployeePageHeader } from '../../../data/ReimbursementData';
import { Container } from '@material-ui/core';

export const EmployeeReimbursementMain = () => {
  const headerData = { header: EmployeePageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ReimbursementRouter />
    </Container>
  );
};
