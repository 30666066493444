import { getService, putService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Logged User Profile
const startUserProfileFind = () => ({
  type: Types.START_USER_PROFILE_FIND,
  payload: {},
});

const endUserProfileFind = (success, error) => ({
  type: Types.END_USER_PROFILE_FIND,
  payload: {
    success,
    error,
  },
});

export const userProfileFind = () => async (dispatch) => {
    dispatch(startUserProfileFind());
    const [response, error] = await getService(`${apiList.userProfile}`);
    dispatch(endUserProfileFind(response, error));
  };

// Update user profile
const startUpdateUserProfile = () => ({
  type: Types.START_UPDATE_USER_PROFILE,
  payload: {},
});

const endUpdateUserProfile = (success, error) => ({
  type: Types.END_UPDATE_USER_PROFILE,
  payload: {
    success,
    error,
  },
});

export const updateUserProfile = (data) => async (dispatch) => {
    dispatch(startUpdateUserProfile());
    const [response, error] = await putService(`${apiList.userProfile}`, data);
    dispatch(endUpdateUserProfile(response, error));
  };


// Update user password
const startUpdateUserPassword = () => ({
  type: Types.START_UPDATE_USER_PASSWORD,
  payload: {},
});

const endUpdateUserPassword = (success, error) => ({
  type: Types.END_UPDATE_USER_PASSWORD,
  payload: {
    success,
    error,
  },
});

export const updateUserPassword = (passwordData) => async (dispatch) => {
    dispatch(startUpdateUserPassword());
    const [response, error] = await putService(`${apiList.userPassword}`, passwordData);
    dispatch(endUpdateUserPassword(response, error));
  };