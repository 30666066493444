export const Types = {
  START_FIND_ALL_HOLIDAYS: 'START_FIND_ALL_HOLIDAYS',
  END_FIND_ALL_HOLIDAYS: 'END_FIND_ALL_HOLIDAYS',

  START_CREATE_HOLIDAY: 'START_CREATE_HOLIDAY',
  END_CREATE_HOLIDAY: 'END_CREATE_HOLIDAY',

  START_FIND_HOLIDAY: 'START_FIND_HOLIDAY',
  END_FIND_HOLIDAY: 'END_FIND_HOLIDAY',

  START_UPDATE_HOLIDAY: 'START_UPDATE_HOLIDAY',
  END_UPDATE_HOLIDAY: 'END_UPDATE_HOLIDAY',

  START_DELETE_HOLIDAY: 'START_DELETE_HOLIDAY',
  END_DELETE_HOLIDAY: 'END_DELETE_HOLIDAY',

  START_FIND_HOLIDAY_NOTE: 'START_FIND_HOLIDAY_NOTE',
  END_FIND_HOLIDAY_NOTE: 'END_FIND_HOLIDAY_NOTE',

  START_UPDATE_HOLIDAY_NOTE: 'START_UPDATE_HOLIDAY_NOTE',
  END_UPDATE_HOLIDAY_NOTE: 'END_UPDATE_HOLIDAY_NOTE',
};
