import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    profile_avatar: 'JD',
    first_name: 'John',
    last_name: 'Doe',
    full_name: 'John Doe',
    account_name: 'Flipkoins',
    email: 'johndoe@test.com',
  },
];

// Applicants table header
export const PartnersHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Partner',
    key: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  { label: 'Partner Name', key: 'account_name', hide: true, headClass: 'w-200', type: 'text' },
  { label: 'Primary Email', key: 'primary_email', hide: false, type: 'text', headClass: 'w-200' },
  {
    label: 'Phone no.',
    key: 'phone',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  // { label: 'City', key: 'city', hide: false, type: 'text', headClass: 'w-150' },
  // { label: 'State', key: 'state', hide: false, type: 'text', headClass: 'w-150' },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: DeleteIcon,
        text: 'Delete',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];
// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Partners Management',
  button: {
    text: 'Create Partners Account',
    className: 'link-button btn-sm btn-primary',
    hiddenKey: 'create-partner',
    url: '/partners/create',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Partners', link: '', className: 'active' },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Partners Account',
      key: 'create',
      link: '/partners/create',
      hiddenKey: 'create-partners',
      path: '/partners/create',
      className: 'tab-link',
    },
    {
      label: 'Edit Partner',
      key: 'edit',
      hiddenKey: 'edit-partners',
      link: '/partners/edit/:id',
      path: '/partners/edit/:id',
      className: 'tab-link',
    },
    {
      label: 'View Partner Account',
      key: 'view',
      link: '/partners/view/:id',
      path: '/partners/view/:id',
      hiddenKey: 'list-partners',
      className: 'tab-link',
    },
    {
      label: 'Accounts',
      hiddenKey: 'list-partners',
      key: 'partners',
      link: '/partners',
      className: 'tab-link',
    },
  ],
};
