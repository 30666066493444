import React, { useEffect, useState } from 'react';
import {
  FormInputsData,
  FileUpload,
  ContractUpload,
  CompanyHrContractUpload,
  EmployeeProfileAccessButton,
} from '../../data/EditEmployeeData';
import { ViewFormCustom, TabCustom, PrimaryButton } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { employeeFind } from '../../redux/hrms/action';
import { commonFn } from '../../util/commonFn';
import { Tabs, HrmsTabs, EmployeeTabs } from '../../data/EmployeeData';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { employeeProfileAction } from '../../redux/hrms/action';
import { openSnackBarLayout } from '../../redux/common/action';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const ViewEmployee = ({
  isEdit,
  isView,
  match,
  module,
  docErrorMessage = 'No document found',
}) => {
  const dispatch = useDispatch();
  const { push, history } = useHistory();
  const location = useLocation();
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [inputList, setInputList] = useState([]);

  const MySwal = withReactContent(Swal);

  const {
    employeeFindProcess,
    employeeFindError,
    employeeFindData,
    userInfo,
    employeeProfileActionProcess,
    employeeProfileActionError,
    employeeProfileActionData,
  } = useSelector(({ employees, sign }) => ({
    ...employees,
    ...sign,
  }));

  // employee find
  useEffect(() => {
    const empId =
      (match?.params?.id && match?.params?.id) ||
      (userInfo?.data?.type_slug === 'employee' && userInfo?.data?.id);
    dispatch(employeeFind(empId));
  }, []);

  let tabsData = [];

  if (location?.pathname.indexOf('hrms') !== -1) {
    module = 'hrms';
    tabsData = HrmsTabs;
  } else if (location?.pathname.indexOf('company') !== -1) {
    tabsData = Tabs;
    module = 'company';
  } else if (location?.pathname.indexOf('employee') !== -1) {
    tabsData = EmployeeTabs;
    module = 'employee';
  }

  // set hidden key in form inputs
  useEffect(() => {
    if (FormInputsData && module && module === 'company') {
      setHiddenKeys(() => ['client_id', 'hr_detail', 'account_detail', 'employee_documents']);
    } else if (FormInputsData && module && module === 'employee') {
      setHiddenKeys(() => ['client_id', 'general_note', 'company_hr_detail']);
    }
  }, [module, FormInputsData]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  //
  useEffect(() => {
    if (employeeFindData?.data?.document) {
      const list = [];
      employeeFindData?.data?.interview_status.map((item, index) => {
        list.push({
          statusValue: item.status,
          commentValue: item.comment,
          interviewDateValue: item.interview_date,
          commentLabel: `Comment ${index + 1}`,
          statusLabel: `Status ${index + 1}`,
          interviewDateLabel: `Interview Date ${index + 1}`,
        });
        setInputList(list);
      });
    }
  }, [employeeFindError, employeeFindData, employeeFindProcess]);

  //   button click
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (module === 'hrms') {
        push(`/hrms/employees/edit/${match?.params?.id}`);
      } else if (module === 'company') {
        push(`/company/employees/edit/${match?.params?.id}`);
      } else if (module === 'employee') {
        push(`/employee/profile/edit`);
      }
    } else if (value?.button?.key === 'close') {
      if (module === 'hrms') {
        push(`/hrms/employees`);
      } else if (module === 'company') {
        push(`/company/employees`);
      }
    } else if (value?.button?.key === 'profile-action-button') {
      if (module === 'hrms') {
        const isRevoked = employeeFindData?.data?.is_revoked === 'N' ? 1 : 0;
        const actionType = isRevoked === 1 ? 'block' : 'un-block';

        const employeeAccessData = {
          employeeId: employeeFindData?.data?.id,
          isRevoked: isRevoked,
        };

        MySwal.fire({
          title: 'Are you sure?',
          text: `You want to ${actionType} employee profile access`,
          icon: 'warning',
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          result = result.isConfirmed && dispatch(employeeProfileAction(employeeAccessData));

          if (result) {
            Swal.fire({
              title: 'Success!',
              text: `Employee profile access has been ${actionType}ed`,
              icon: 'success',
              iconColor: '#3085d6',
            });
          }
        });
      }
    }
  };

  // response message after employee profile action
  useEffect(() => {
    if (!employeeProfileActionProcess && !employeeProfileActionError && employeeProfileActionData) {
      // dispatch(openSnackBarLayout(employeeProfileActionData?.message, 'success', 1000));
      dispatch(employeeFind(employeeProfileActionData?.data?.id));
    }
  }, [employeeProfileActionProcess, employeeProfileActionError, employeeProfileActionData]);

  //  form data
  const formData = {
    list: FormInputsData,
    inProgress: employeeFindProcess ? true : false,
    error: (!employeeFindData?.data && employeeFindData?.message) || employeeFindError?.message,
    isEdit,
    isView,
    value: employeeFindData?.data || {},
    tabs: tabs,
    groupLabelText: 'company_hr_detail',
    module: module,
    hiddenButton: (module && module === 'employee' && ['close']) || [],
  };

  // Upload Resume component
  const UploadResume = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={FileUpload?.xs || 12}
            sm={FileUpload?.sm || 12}
            md={FileUpload?.md || 6}
            lg={FileUpload?.lg || 4}
            xl={FileUpload?.xl || 4}
          >
            <Box className={FileUpload?.formElementClass}>
              <label className="interview-label">{FileUpload.label}</label>
              {formData.value?.resume && (
                <div className="file-section">
                  <a
                    href={formData.value?.resume}
                    target="_blank"
                    className={FileUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.resume_name}
                  </a>
                </div>
              )}
              {!formData.value?.resume && <div className="text-muted">-</div>}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload resume

  // Upload Contract Component
  const UploadContract = () => {
    return (
      <>
        {/* <Grid container spacing={1}> */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Box className={ContractUpload?.formElementClass}>
            <label className="interview-label">{ContractUpload.label}</label>
            {formData.value?.contract && (
              <div className="file-section">
                <a
                  href={formData.value?.contract}
                  target="_blank"
                  className={ContractUpload.uploadTextClass}
                >
                  <FileCopyIcon />
                  {formData.value?.contract_name}
                </a>
              </div>
            )}
            {!formData.value?.contract && <div className="text-muted">-</div>}
          </Box>
        </Grid>
        {/* </Grid> */}
      </>
    );
  };
  // End upload contract component

  // Upload Company HR Contract Component
  const UploadCompanyHrContract = () => {
    return (
      <>
        {/* <Grid container spacing={1}> */}
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Box className={CompanyHrContractUpload?.formElementClass}>
            <label className="interview-label">{CompanyHrContractUpload.label}</label>
            {formData.value?.company_hr_contract && (
              <div className="file-section">
                <a
                  href={formData.value?.company_hr_contract}
                  target="_blank"
                  className={CompanyHrContractUpload.uploadTextClass}
                >
                  <FileCopyIcon />
                  {formData.value?.company_hr_contract_name}
                </a>
              </div>
            )}
             {!formData.value?.company_hr_contract && <div className="text-muted">-</div>}
          </Box>
        </Grid>
        {/* </Grid> */}
      </>
    );
  };
  // End upload contract component

  // document/file upload
  const UploadDocuments = () => {
    return (
      <Grid container>
        {(employeeFindData?.data?.documents.length > 0 &&
          employeeFindData?.data?.documents?.map((list, inputListIndex) => (
            <Grid container spacing={1} key={inputListIndex}>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  <label className="interview-label">{list.document_type}</label>
                </Box>
              </Grid>
              <Grid
                item
                xs={list?.xs || 12}
                sm={list?.sm || 12}
                md={list?.md || 6}
                lg={list?.lg || 4}
                xl={list?.xl || 4}
              >
                <Box className="form-input-box">
                  {list?.document && (
                    <a href={list?.document} target="_blank">
                      {list.document_name}
                    </a>
                  )}
                </Box>
              </Grid>
            </Grid>
          ))) || (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Box pl={3}>
                <p className="error">{docErrorMessage}</p>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  // leave types
  const LeaveTypes = () => {
    return (
      <>
        <Grid container borderBottom={1}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box px={2} ml={1} className="form-input-box">
              <h5> Leave Type </h5>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box className="form-input-box">
              <h5> Initial Leave(s)</h5>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box className="form-input-box">
              <h5> Taken/Pending Leave(s)</h5>
            </Box>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <Box className="form-input-box">
              <h5> Available Leave(s)</h5>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          {employeeFindData?.data?.leave_types.map((leaveType, leaveIndex) => (
            <>
              {/* leave type */}
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box pt={2} className="form-input-box">
                  <p>{leaveType?.type}</p>
                </Box>
              </Grid>

              {/* leaves count */}
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box pt={2} pl={4} className="form-input-box">
                  <p>{leaveType?.count && (leaveType?.count > 0 ? leaveType?.count : '-') || '-'}</p>
                </Box>
              </Grid>

              {/* Applied leaves */}
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box pt={2} pl={4} className="form-input-box">
                  <p>{leaveType?.approved_leaves && (leaveType?.approved_leaves > 0 ? leaveType?.approved_leaves : '-') || '-'}</p>
                </Box>
              </Grid>

              {/* remaining leaves */}
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Box pt={2} pl={4} className="form-input-box">
                  <p>{leaveType?.remaining_leaves && (leaveType?.remaining_leaves > 0 ? leaveType?.remaining_leaves : '-') || '-'}</p>
                </Box>
              </Grid>
            </>
          ))}
        </Grid>
      </>
    );
  };

  // employee profile access revoke/invoke
  const EmployeeProfileAccessAction = () => {
    const actionButton = EmployeeProfileAccessButton?.button;
    const isRevoked = employeeFindData?.data?.is_revoked;

    return (
      <>
        {module === 'hrms' && (
          <Grid container borderBottom={1}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Box px={2} ml={1} className={EmployeeProfileAccessButton?.formControlClass}>
                <span
                  className="view-button"
                  onClick={(e) => clickOnBtn({ e, button: actionButton })}
                >
                  <PrimaryButton
                    text={
                      isRevoked === 'Y'
                        ? EmployeeProfileAccessButton?.invokeLabel
                        : EmployeeProfileAccessButton?.revokeLabel
                    }
                    title={
                      isRevoked === 'Y'
                        ? EmployeeProfileAccessButton?.invokeLabel
                        : EmployeeProfileAccessButton?.revokeLabel
                    }
                  />
                </span>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom
        {...formData}
        clickOnBtn={clickOnBtn}
        renderer={{
          UploadResume,
          UploadContract,
          UploadDocuments,
          UploadCompanyHrContract,
          LeaveTypes,
          EmployeeProfileAccessAction,
        }}
        hiddenKeys={hiddenKeys}
      />
    </>
  );
};
