export const Types = {
  START_USER_PROFILE_FIND: 'START_USER_PROFILE_FIND',
  END_USER_PROFILE_FIND: 'END_USER_PROFILE_FIND',

  START_UPDATE_USER_PROFILE: 'START_UPDATE_USER_PROFILE',
  END_UPDATE_USER_PROFILE: 'END_UPDATE_USER_PROFILE',

  START_UPDATE_USER_PASSWORD: 'START_UPDATE_USER_PASSWORD',
  END_UPDATE_USER_PASSWORD: 'END_UPDATE_USER_PASSWORD'
};
