import { getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Position Status Find All
const startPositionStatusFindAll = () => ({
  type: Types.START_STATUS_FIND_ALL,
  payload: {},
});

const endPositionStatusFindAll = (success, error) => ({
  type: Types.END_STATUS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const positionStatusFindAll = () => async (dispatch) => {
  dispatch(startPositionStatusFindAll());
  const [response, error] = await getService(`${apiList.positionStatus}`);
  dispatch(endPositionStatusFindAll(response, error));
};
