export const Types = {
  START_PARTNERS_FIND_ALL: 'START_PARTNERS_FIND_ALL',
  END_PARTNERS_FIND_ALL: 'END_PARTNERS_FIND_ALL',

  START_CREATE_PARTNER: 'START_CREATE_PARTNER',
  END_CREATE_PARTNER: 'END_CREATE_PARTNER',

  START_PARTNERS_FIND: 'START_PARTNERS_FIND',
  END_PARTNERS_FIND: 'END_PARTNERS_FIND',

  START_UPDATE_PARTNERS: 'START_UPDATE_PARTNERS',
  END_UPDATE_PARTNERS: 'END_UPDATE_PARTNERS',

  START_DELETE_PARTNERS: 'START_DELETE_PARTNERS',
  END_DELETE_PARTNERS: 'END_DELETE_PARTNERS',

  START_PARTNERS_PROFILE_FIND: 'START_PARTNERS_PROFILE_FIND',
  END_PARTNERS_PROFILE_FIND: 'END_PARTNERS_PROFILE_FIND',

  START_UPDATE_PARTNERS_PROFILE: 'START_UPDATE_PARTNERS_PROFILE',
  END_UPDATE_PARTNERS_PROFILE: 'END_UPDATE_PARTNERS_PROFILE',
};
