import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Dashboard } from './Dashboard';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HomeMain = (props) => {
  const { preview } = useSelector(({ common }) => ({ ...common }));

  return (
    <Wrapper>
      {preview && <div>Main</div>}
      <Dashboard />
      {/* <Footer /> */}
    </Wrapper>
  );
};

export default HomeMain;