import React, { useState, useEffect } from 'react';
import { FormCustom, TabCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { HrmsPayrollTabs } from '../../../data/PayrollData';
import { FormInputsData, PayslipUpload } from '../../../data/CreatePayrollData';
import axios from 'axios';
import { apiList } from '../../../util/apiList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBarLayout } from '../../../redux/common/action';
import { CircularProgress, Grid, Box } from '@material-ui/core';
import { employeeFindAll } from '../../../redux/hrms/action';
import { createPayroll, findPayroll, updatePayroll } from '../../../redux/payrolls/action';
import { useHistory } from 'react-router-dom';

export const AddOrEditPayroll = ({ match, isEdit, isView }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [fileUpload, setFileUpload] = useState(PayslipUpload);

  const {
    employeeFindAllProcess,
    employeeFindAllError,
    employeeFindAllData,
    payrollsCreateProcess,
    payrollsCreateError,
    payrollsCreateData,
    payrollFindProcess,
    payrollFindError,
    payrollFindData,
    payrollUpdateProcess,
    payrollUpdateError,
    payrollUpdateData,
  } = useSelector(({ employees, payrolls }) => ({
    ...employees,
    ...payrolls,
  }));

  // tabs
  const tabs = {
    ...HrmsPayrollTabs,
    list: commonFn.updateLink(HrmsPayrollTabs.list, match),
  };

  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    }));
    setIsRouteChange(true);
    match?.params?.id && dispatch(findPayroll(match?.params?.id));
    dispatch(employeeFindAll());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
  }, [match?.params?.id]);

  // set payroll data
  useEffect(() => {
    if (isEdit) {
      if (!payrollFindProcess && !payrollFindError && payrollFindData?.data) {
        setFormData((data) => ({ ...data, values: payrollFindData?.data, inProgress: false }));
      } else if (!payrollFindProcess && payrollFindError && !payrollFindData?.data) {
        const error = payrollFindData?.message || payrollFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [payrollFindProcess, payrollFindError, payrollFindData]);

  // employee
  useEffect(() => {
    if (!employeeFindAllProcess && !employeeFindAllError && employeeFindAllData?.data) {
      const employees =
        (employeeFindAllData &&
          employeeFindAllData?.data?.list?.map((emp) => {
            return { label: emp?.flipkoins_email, value: emp?.id };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          isEdit && payrollFindData?.data
            ? {
                ...data.values,
                employee_email: commonFn.getMultiValueFromId(
                  payrollFindData?.data?.user_id,
                  employees,
                )[0],
              }
            : data.values,
        options: { ...data.options, employees },
      }));
    }
  }, [employeeFindAllProcess, employeeFindAllError, employeeFindAllData, payrollFindData]);

  // form submit
  const formSubmit = (data) => {
    const request = data;
    request.user_id = commonFn.getIdFromMultiValue(data?.employee_email).value;

    setIsFormSubmitted(true);
    setIsLoading(() => true);

    if (isEdit) {
      dispatch(updatePayroll(match?.params?.id, request));
    } else {
      dispatch(createPayroll(request));
    }
  };

  // create payroll success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!payrollsCreateProcess && !payrollsCreateError && payrollsCreateData) {
        dispatch(openSnackBarLayout(payrollsCreateData?.message, 'success', 1000));
        setIsLoading(() => false);
        history.push(`/hrms/payrolls/view/${payrollsCreateData?.data?.id}`);
      } else if (payrollsCreateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(payrollsCreateProcess?.message, 'error', 1000));
      }
    }
  }, [payrollsCreateProcess, payrollsCreateError, payrollsCreateData]);

  // update payroll success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!payrollUpdateProcess && !payrollUpdateError && payrollUpdateData) {
        dispatch(openSnackBarLayout(payrollUpdateData.message, 'success', 1000));
        setIsLoading(() => false);
          history.push(`/hrms/payrolls/view/${payrollUpdateData?.data?.id}`);
      } else if (payrollUpdateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(payrollUpdateError?.message, 'error', 1000));
      }
    }
  }, [payrollUpdateProcess, payrollUpdateError, payrollUpdateData]);

  //   change event
  const changeEvent = async (data) => {
    console.log('data', data);
  };

  //   Upload Payslip
  const fileChange = async (e, watch) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'payslips');
      formData.append('file', file);
      setIsUploading(() => true);

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });
      const result = response?.data || {};

      setIsUploading(() => false);
      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            ...watch,
            payslip: result?.data?.uploaded_url,
            payslip_name: result?.data?.original_name,
          },
        }));
      } else {
        dispatch(openSnackBarLayout('Payslip is not uploaded', 'error', 1000));
      }
    }
  };

  // remove uploaded payslip
  const removePayslip = (watch) => {
    setFormData((data) => ({
      ...data,
      values: {
        ...data.values,
        ...watch,
        payslip: '',
        payslip_name: '',
      },
    }));
  };

  // Upload Payslip component
  const UploadPayslip = (form, watch) => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={fileUpload?.xs || 12}
            sm={fileUpload?.sm || 12}
            md={fileUpload?.md || 6}
            lg={fileUpload?.lg || 4}
            xl={fileUpload?.xl || 4}
          >
            <Box className={fileUpload?.formElementClass}>
              <label className="interview-label">{fileUpload.label}</label>
              {isUploading && (
                <div className="loader">
                  <CircularProgress />
                </div>
              )}

              {!isUploading && (
                <>
                  <input
                    accept={fileUpload?.accept}
                    className={fileUpload.fieldClass}
                    id={fileUpload?.id}
                    type={fileUpload?.type}
                    onChange={(e) => fileChange(e, watch)}
                  />
                  {!formData.values?.payslip && (
                    <label htmlFor={fileUpload?.id} className={fileUpload.uploadTextClass}>
                      <FileCopyIcon /> &nbsp; {fileUpload.placeholder}
                    </label>
                  )}
                  {formData.values?.payslip && (
                    <div className="file-section">
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removePayslip(watch);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                      <a
                        href={formData.values?.payslip}
                        target="_blank"
                        className={fileUpload.uploadTextClass}
                      >
                        <FileCopyIcon />
                        {formData.values?.payslip_name}
                      </a>
                    </div>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload payslip component

  return (
    <>
      <TabCustom {...tabs} />

      {!isRouteChange && !formData.inProgress && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          renderer={{ UploadPayslip }}
          isLoading={isLoading}
        />
      )}

      {isRouteChange && (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
