import { getService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Locations Find All
const startLocationsFindAll = () => ({
  type: Types.START_LOCATIONS_FIND_ALL,
  payload: {},
});

const endLocationsFindAll = (success, error) => ({
  type: Types.END_LOCATION_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const locationsFindAll = () => async (dispatch) => {
  dispatch(startLocationsFindAll());
  const [response, error] = await getService(`${apiList.locations}`);
  dispatch(endLocationsFindAll(response, error));
};
