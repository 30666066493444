import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Payroll Detail',
    groupLabelClass: 'fieldset-label',
    key: 'payroll_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Employee',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'employee_email',
        name: 'employee_email',
        groupLabelPlace: 'company_hr_detail',
        placeholder: 'Select Employee',
        hide: false,
        optionsKey: 'employees',
        valueId: 'employee_email',
        displayKey: 'flipkoins_email',
        hiddenKey: 'employee_email',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect textBoxStyle',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select an employee' }],
      },
      {
        label: 'Date',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'payslip_date',
        name: 'payslip_date',
        placeholder: 'Date',
        displayKey: 'payslip_date',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        key: 'UploadPayslip',
      },
    ],
  },
];

// upload resume
export const PayslipUpload = {
  label: 'Payslip',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
  uploadType: 'doc',
  key: 'payslip_name',
  placeholder: 'Upload Payslip',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'resume',
  fieldClass: 'input-file-button',
  id: 'resume-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  uploadTextClass: 'file-label',
};
