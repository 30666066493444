import { postService, getService, putService, deleteService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// Partners Find All
const startPartnersFindAll = () => ({
  type: Types.START_PARTNERS_FIND_ALL,
  payload: {},
});

const endPartnersFindAll = (success, error) => ({
  type: Types.END_PARTNERS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const partnersFindAll =
  (skip = 0, limit = 10) =>
  async (dispatch) => {
    dispatch(startPartnersFindAll());
    const [response, error] = await getService(`${apiList.partners}?skip=${skip}&limit=${limit}`);
    console.log('response on the partners find all', response, error);
    dispatch(endPartnersFindAll(response, error));
  };

// Create Partners
const startCreatePartners = () => ({
  type: Types.START_CREATE_PARTNER,
  payload: {},
});

const endCreatePartners = (success, error) => ({
  type: Types.END_CREATE_PARTNER,
  payload: {
    success,
    error,
  },
});

export const createPartners = (data) => async (dispatch) => {
  dispatch(startCreatePartners());
  const [response, error] = await postService(`${apiList.partners}/create`, data);
  dispatch(endCreatePartners(response, error));
};

// Find Partners
const startPartnersFind = () => ({
  type: Types.START_PARTNERS_FIND,
  payload: {},
});

export const endPartnersFind = (success = null, error = null) => ({
  type: Types.END_PARTNERS_FIND,
  payload: {
    success,
    error,
  },
});

export const partnersFind = (id) => async (dispatch) => {
  dispatch(startPartnersFind());
  const [response, error] = await getService(`${apiList.partners}/${id}`);
  dispatch(endPartnersFind(response, error));
};

// Partners Update
const startUpdatePartners = () => ({
  type: Types.START_UPDATE_PARTNERS,
  payload: {},
});

const endUpdatePartners = (success, error) => ({
  type: Types.END_UPDATE_PARTNERS,
  payload: {
    success,
    error,
  },
});

export const updatePartners = (id, data) => async (dispatch) => {
  dispatch(startUpdatePartners());
  const [response, error] = await putService(`${apiList.partners}/${id}`, data);
  dispatch(endUpdatePartners(response, error));
};

// partners profile find
const startPartnersProfileFind = () => ({
  type: Types.START_PARTNERS_PROFILE_FIND,
  payload: {},
});

const endPartnersProfileFind = (success, error) => ({
  type: Types.END_PARTNERS_PROFILE_FIND,
  payload: {
    success,
    error,
  },
});

export const partnersProfileFind = (id) => async (dispatch) => {
  dispatch(startPartnersProfileFind());
  console.log('id passing for the api', id);
  const [response, error] = await getService(`${apiList.partnersProfile}/${id}`);
  dispatch(endPartnersProfileFind(response, error));
};

// partners profile update
const startUpdatePartnersProfile = () => ({
  type: Types.START_UPDATE_PARTNERS_PROFILE,
  payload: {},
});

const endUpdatePartnersProfile = (success, error) => ({
  type: Types.END_UPDATE_PARTNERS_PROFILE,
  payload: {
    success,
    error,
  },
});

export const updatePartnersProfile = (id, data) => async (dispatch) => {
  dispatch(startUpdatePartnersProfile());
  const [response, error] = await putService(`${apiList.partnersProfile}/${id}`, data);
  dispatch(endUpdatePartnersProfile(response, error));
};

// Partners Delete
const startDeletePartners = () => ({
  type: Types.START_DELETE_PARTNERS,
  payload: {},
});

const endDeletePartners = (success, error) => ({
  type: Types.END_DELETE_PARTNERS,
  payload: {
    success,
    error,
  },
});

export const deletePartners = (id) => async (dispatch) => {
  dispatch(startDeletePartners());
  const [response, error] = await deleteService(`${apiList.partners}/${id}`);
  dispatch(endDeletePartners(response, error));
};
