export const Validation = {
  password: /^(?=.*[A-Z])(?=.*[<>{}\"/|;:.,~!?@#$%^=&*\]\\\()\[_+])(?=.*[a-z])(?=.*[0-9]).{8,}$/,
  mobile: /^\d{10}$/,
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberOnly: /^[0-9]+$/,
  alphanumeric: /^[0-9a-zA-Z]+$/,
  numberAndSpecial: /^[0-9-+()<>{}\"/|;:.,~!?@#$%^=&*\\\[\]_]*$/,
  numberAndPlus: /^[0-9-+]*$/,
  website:
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
};
