import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../theme';
import { HeaderListItems } from './HeaderListItems';

const HeaderWrapper = styled.div`
  color: ${colors.primary.black90};
  background: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 8;
  padding: 0px 10px;
  height: 82px;
  line-height: 0px;
  border-bottom: 2px solid ${colors.primary.yellow100};
  margin: 0;
  width: calc(100% - 240px);
`;

export const Header = (props) => {
  return (
    <HeaderWrapper className="header-wrapper">
      <HeaderListItems />
    </HeaderWrapper>
  );
};

Header.defaultProps = {};
