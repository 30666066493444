import React, { useState, useEffect } from 'react';
import { Reimbursements } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeTabs, ReimbursementHead } from '../../../data/ReimbursementData';
import { findAllReimbursement } from '../../../redux/reimbursements/action';

export const EmployeeReimbursement = ({ match }) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const { reimbursementFindAllProcess, reimbursementFindAllError, reimbursementFindAllData } =
    useSelector(({ reimbursements }) => ({
      ...reimbursements,
    }));

  useEffect(() => {
    document.title = 'Quark - Employee | Reimbursements';
  }, []);

  // tabs
  const tabs = {
    ...EmployeeTabs,
    list: commonFn.updateLink(EmployeeTabs.list, match),
  };

  useEffect(() => {
    dispatch(findAllReimbursement(skip, limit));
  }, [skip]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllReimbursement(limit * (value - 1), limit));
  };

  console.log('reimbursement', reimbursementFindAllData);

  const reimbursementResponse = reimbursementFindAllData?.data?.list;
  const reimbursementData =
    reimbursementResponse &&
    reimbursementResponse.map((reimbursement) => {
      return { ...reimbursement };
    });

  // table data
  const tableData = {
    tabs: tabs,
    module: 'employee',
    count: reimbursementFindAllData?.data?.count,
    skip: skip,
    data: reimbursementData,
    head: ReimbursementHead,
    pagination: {
      numberOfPages: reimbursementFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: reimbursementFindAllError?.message,
    hiddenKeys: ['delete'],
    inProgress: reimbursementFindAllProcess ? true : false,
  };

  console.log('Reimbursement', tableData);

  return (
    <>
      <Reimbursements {...tableData} />
    </>
  );
};
