import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Applicants, CreateApplicant, ViewApplicant } from '.';
import { commonFn } from '../../util/commonFn';
import { NotFound } from '../common';

export const ApplicantsRouter = (props) => {
  return (
    <>
      <Switch>
        {commonFn.menuHiddenKeys.indexOf('list-applicants') === -1 && (
          <Route exact path={'/applicants'} component={Applicants} />
        )}
        {commonFn.menuHiddenKeys.indexOf('create-applicant') === -1 && (
          <Route exact path={'/applicants/create'} component={CreateApplicant} />
        )}
        {commonFn.menuHiddenKeys.indexOf('list-applicants') === -1 && (
          <Route
            path={'/applicants/view/:id'}
            render={(props) => <ViewApplicant isView={true} {...props} />}
          />
        )}
        {commonFn.menuHiddenKeys.indexOf('edit-applicant') === -1 && (
          <Route
            path={'/applicants/edit/:id'}
            render={(props) => <CreateApplicant isEdit={true} {...props} />}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
