import { getService, postService, putService } from '../../services/httpService';
import { apiList } from '../../util/apiList';
import { Types } from './types';

// Create payroll
const startCreatePayroll = () => ({
  type: Types.START_CREATE_PAYROLL,
  payload: {},
});

const endCreatePayroll = (success, error) => ({
  type: Types.END_CREATE_PAYROLL,
  payload: {
    success,
    error,
  },
});

export const createPayroll = (data) => async (dispatch) => {
  dispatch(startCreatePayroll());
  const [response, error] = await postService(apiList.payrolls, data);
  dispatch(endCreatePayroll(response, error));
};

// Payrolls find all
const startFindAllPayrolls = () => ({
  type: Types.START_FIND_ALL_PAYROLL,
  payload: {},
});

const endFindAllPayrolls = (success, error) => ({
  type: Types.END_FIND_ALL_PAYROLL,
  payload: {
    success,
    error,
  },
});

export const findAllPayrolls =
  (skip = 0, limit = 10, data) =>
  async (dispatch) => {
    dispatch(startFindAllPayrolls());
    const [response, error] = await postService(`${apiList.payrollsFilter}?skip=${skip}&limit=${limit}`, data);
    dispatch(endFindAllPayrolls(response, error));
  };

// Payrolls find
const startFindPayroll = () => ({
  type: Types.START_FIND_PAYROLL,
  payload: {},
});

const endFindPayroll = (success, error) => ({
  type: Types.END_FIND_PAYROLL,
  payload: {
    success,
    error,
  },
});

export const findPayroll = (id) => async (dispatch) => {
  dispatch(startFindPayroll());
  const [response, error] = await getService(`${apiList.payrolls}/${id}`);
  dispatch(endFindPayroll(response, error));
};

/**
 * Update payroll
 */
const startUpdatePayroll = () => ({
  type: Types.START_UPDATE_PAYROLL,
  payload: {},
});

const endUpdatePayroll = (success, error) => ({
  type: Types.END_UPDATE_PAYROLL,
  payload: {
    success,
    error,
  },
});

export const updatePayroll = (id, data) => async (dispatch) => {
  dispatch(startUpdatePayroll);
  const [response, error] = await putService(`${apiList.payrolls}/${id}`, data);
  dispatch(endUpdatePayroll(response, error));
};
