import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Employee Detail',
    groupLabelClass: 'fieldset-label',
    key: 'employee_detail',
    hiddenKey: 'employee_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Employee ID',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'employee_id',
        name: 'employee_id',
        placeholder: 'Employee ID',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'First Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'first_name',
        name: 'first_name',
        placeholder: 'First Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Last Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'last_name',
        name: 'last_name',
        placeholder: 'Last Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Flipkoins Email',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'flipkoins_email',
        name: 'flipkoins_email',
        placeholder: 'Flipkoins Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Company',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_name',
        name: 'company_name',
        placeholder: 'Company',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
    ],
  },
  {
    groupLabel: 'Leave Detail',
    groupLabelClass: 'fieldset-label',
    key: 'leave_detail',
    name: 'leave_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Leave Type',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'leave_type_slug',
        name: 'leave_type_slug',
        placeholder: 'Select Leave Type',
        optionsKey: 'leaveTypes',
        valueId: 'leave_type_slug',
        displayKey: 'leave_type',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the Leave Type' }],
      },
      {
        label: 'From',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'from_date',
        name: 'from_date',
        placeholder: 'From',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        min: '1970-01-01',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the From date' }],
      },
      {
        label: 'To',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'to_date',
        name: 'to_date',
        placeholder: 'To',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'date',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the To date' }],
      },
      {
        label: 'No. of Days',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'total_days',
        name: 'total_days',
        placeholder: 'No. of Days',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Approver',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'approver_email',
        name: 'approver_email',
        placeholder: 'Select Approver(s)',
        optionsKey: 'leaveApprovers',
        valueId: 'approver_email',
        displayKey: 'approver_email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'multiselect',
        fieldClass: 'textBoxStyle multiSelectStyle z-index-4',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        errors: [{ type: 'required', message: 'Please select the leave approver(s)' }],
      },
      {
        label: 'Status',
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        key: 'status_slug',
        name: 'status_slug',
        hiddenKey: 'status_slug',
        placeholder: 'Select Status',
        hide: false,
        optionsKey: 'leaveStatus',
        valueId: 'status_slug',
        displayKey: 'status',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        options: [],
        size: 'small',
        isRequired: true,
        arrayClass: 'badge-outlined badge-warning text-warning',
        errors: [{ type: 'required', message: 'Select the status' }],
      },
      {
        label: 'Leave Reason',
        xs: 12,
        sm: 12,
        md: 4,
        lg: 4,
        xl: 4,
        key: 'leave_reason',
        name: 'leave_reason',
        placeholder: 'Reason For Leave',
        hide: false,
        displayKey: 'leave_reason',
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Reject Leave Comment/Reason',
        xs: 12,
        sm: 12,
        md: 8,
        lg: 8,
        xl: 8,
        key: 'comment',
        name: 'comment',
        hiddenKey: 'comment',
        displayKey: 'comment',
        placeholder: 'Reject Leave Comment/Reason',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        rows: 6,
        valueType: 'array',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        // isRequired: true,
        // errors: [{ type: 'required', message: 'Please enter the reason/comment' }],
      },
    ],
  },
];

// Leave rejection reason
export const LeaveRejectionInputsData = {
  groupLabel: 'Reject Leave',
  groupLabelClass: 'fieldset-label',
  key: 'leave_rejection',
  hiddenKey: 'leave_rejection',
  groupClass: 'form-fieldset',
  inputs: [
    {
      label: 'Reason/Comment',
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
      key: 'reason',
      name: 'reason',
      placeholder: 'Reason/Comment For Rejection',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'resizableTextArea',
      fieldClass: 'textBoxStyle resizableTextArea',
      rows: 6,
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
      isRequired: true,
        minLength: 3,
        maxLength: 4000,
        errors: [
          { type: 'required', message: 'Please enter the reason/comment' },
          { type: 'minLength', message: 'Reason is too short' },
        ],
    },
  ],
};