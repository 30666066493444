import React, { useState, useEffect } from 'react';
import { Payroll } from '../../common/Payroll';
import { PayrollHead, ResponseData, HrmsPayrollTabs, MainFilter } from '../../../data/PayrollData';
import { useDispatch, useSelector } from 'react-redux';
import { commonFn } from '../../../util/commonFn';
import { findAllPayrolls } from '../../../redux/payrolls/action';

export const HrmsPayroll = (match) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [filterValues, setFilterValues] = useState([]);

  const { payrollsFindAllProcess, payrollsFindAllError, payrollsFindAllData } = useSelector(
    ({ payrolls }) => ({
      ...payrolls,
    }),
  );

  useEffect(() => {
    document.title = 'Quark - HRMS | Payrolls';
  }, []);

  // tabs
  const tabs = {
    ...HrmsPayrollTabs,
    list: commonFn.updateLink(HrmsPayrollTabs.list, match),
  };

  useEffect(() => {
    dispatch(findAllPayrolls(skip, limit));
  }, [skip]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllPayrolls(limit * (value - 1), limit));
  };

  // payrolls data response
  const payrollResponse = payrollsFindAllData?.data?.list;
  const payrollData =
    payrollResponse &&
    payrollResponse.map((payroll) => {
      const text = [{ text: payroll?.full_name }, { text: payroll?.email }];
      return { ...payroll, full_name: text };
    });

  // change event
  const changeEvent = (data) => {};

  // filter data
  const filterData = {
    filter: MainFilter,
    options: {},
  };

  // filter form submit
  const filterFormSubmit = (data) => {
    const request = data;
    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(findAllPayrolls(0, limit, { ...filterValues, ...request }));
  };

  // table data
  const tableData = {
    tabs: tabs,
    module: 'hrms',
    count: payrollsFindAllData?.data?.count,
    skip: skip,
    data: payrollData,
    head: PayrollHead,
    pagination: {
      numberOfPages: payrollsFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: payrollsFindAllError?.message,
    hiddenKeys: [],
    inProgress: payrollsFindAllProcess ? true : false,
    filterData: filterData || {},
    filterFormSubmit: filterFormSubmit,
    changeEvent: changeEvent,
  };

  return (
    <>
      <Payroll {...tableData} />
    </>
  );
};
