import { postService, getService, putService, deleteService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

const startUsersFindAll = () => ({
  type: Types.START_USERS_FIND_ALL,
  payload: {},
});

const endUsersFindAll = (success, error) => ({
  type: Types.END_USERS_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const usersFindAll =
  (skip = 0, limit = 10) =>
  async (dispatch) => {
    dispatch(startUsersFindAll());
    const [response, error] = await getService(`${apiList.users}?skip=${skip}&limit=${limit}`);
    dispatch(endUsersFindAll(response, error));
  };

// Create user
const startCreateUser = () => ({
  type: Types.START_CREATE_USER,
  payload: {},
});

const endCreateUser = (success, error) => ({
  type: Types.END_CREATE_USER,
  payload: {
    success,
    error,
  },
});

export const createUser = (data) => async (dispatch) => {
  dispatch(startCreateUser());
  const [response, error] = await postService(apiList.users, data);
  dispatch(endCreateUser(response, error));
};

// Find user
const startFindUser = () => ({
  type: Types.START_FIND_USER,
  payload: {},
});

const endFindUser = (success, error) => ({
  type: Types.END_FIND_USER,
  payload: {
    success,
    error,
  },
});

export const findUser = (id) => async (dispatch) => {
  dispatch(startFindUser());
  const [response, error] = await getService(`${apiList.users}/${id}`);
  dispatch(endFindUser(response, error));
};

// Update user
const startUserUpdate = () => ({
  type: Types.START_UPDATE_USER,
  payload: {},
});

const endUserUpdate = (success, error) => ({
  type: Types.END_UPDATE_USER,
  payload: {
    success,
    error,
  },
});

export const updateUser = (id, data) => async (dispatch) => {
  dispatch(startUserUpdate());
  const [response, error] = await putService(`${apiList.users}/${id}`, data);
  dispatch(endUserUpdate(response, error));
};

// Delete user
const startUserDelete = () => ({
  type: Types.START_DELETE_USER,
  payload: {},
});

const endUserDelete = (success, error) => ({
  type: Types.END_DELETE_USER,
  payload: {
    success,
    error,
  },
});

export const deleteUser = (id) => async (dispatch) => {
  dispatch(startUserDelete());
  const [response, error] = await deleteService(`${apiList.users}/${id}`);
  dispatch(endUserDelete(response, error));
};
