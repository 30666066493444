import { getService, postService } from '../../services/httpService';
import { apiList } from '../../util/apiList';
import { Types } from './types';

// create reimbursement
const startCreateReimbursement = () => ({
  type: Types.START_CREATE_REIMBURSEMENT,
  payload: {},
});

const endCreateReimbursement = (success, error) => ({
  type: Types.END_CREATE_REIMBURSEMENT,
  payload: {
    success,
    error,
  },
});

export const createReimbursement = (data) => async (dispatch) => {
  dispatch(startCreateReimbursement);
  const [response, error] = await postService(apiList.reimbursements, data);
  dispatch(endCreateReimbursement(response, error));
};

// find all reimbursement
const startFindAllReimbursement = () => ({
  type: Types.START_FIND_ALL_REIMBURSEMENT,
  payload: {},
});

const endFindAllReimbursement = (success, error) => ({
  type: Types.END_FIND_ALL_REIMBURSEMENT,
  payload: {
    success,
    error,
  },
});

export const findAllReimbursement = () => async (dispatch) => {
  dispatch(startFindAllReimbursement);
  const [response, error] = await getService(apiList.reimbursements);
  dispatch(endFindAllReimbursement(response, error));
};
