import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Box,
  TextField,
  FormGroup,
  CircularProgress,
  TextareaAutosize,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { commonFn } from '../../util/commonFn';
import { PrimaryButton } from '.';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';

export const LeaveForm = ({
  list = [],
  formVariant = 'outlined',
  options = {},
  isView = false,
  isEdit = false,
  values = {},
  error = null,
  inProgress = false,
  hiddenKeys = [],
  aliasObject = {},
  readOnlyKeys = [],
  formSubmit = () => {},
  changeEvent = () => {},
  renderer = {},
  buttonSection = {
    isRequired: true,
    list: [{ viewText: 'Edit', editText: 'Update', createText: 'Save' }],
  },
  logo = '',
  isLoading = false,
  groupLabelText = '',
  isUploadDocument = false,
  hiddenButton = [],
} = {}) => {
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: values,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = form;

  // disable past dates
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  };

  useEffect(() => {
    if (Object.keys(values)?.length) {
      reset({ ...watch(), ...values });
    }
  }, [values]);

  const onChangeValue = (data) => {
    changeEvent(data);
  };

  const errorMessage = (itemVal = {}) => {
    return (
      <>
        {itemVal?.errors?.map((error, index) => (
          <span key={`span_${index}`}>
            {errors[itemVal?.name]?.type === error?.type && (
              <span className="error">{error?.message}</span>
            )}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
        <Card variant={formVariant}>
          <CardContent>
            {inProgress && (
              <div className="align-center mt-36">
                <CircularProgress />
              </div>
            )}
            {!inProgress && error && <div className="align-center mt-36">{error}</div>}
            {!inProgress &&
              !error &&
              list?.map((group, index) => (
                <div key={`list_${index}`}>
                  {group?.key && hiddenKeys.indexOf(group.hiddenKey) == -1 && (
                    <FormGroup className={group?.groupClass} key={`list_${index}`}>
                      <Grid container spacing={1}>
                        {group?.inputs?.map((itemVal, groupIndex) => (
                          <>
                            {hiddenKeys.indexOf(itemVal.hiddenKey) == -1 && (
                              <Grid
                                item
                                xs={itemVal?.xs || 12}
                                sm={itemVal?.sm || 12}
                                md={itemVal?.md || 6}
                                lg={itemVal?.lg || 4}
                                xl={itemVal?.xl || 4}
                                key={`grid_${groupIndex}`}
                              >
                                {/* Type: Text */}
                                {(itemVal?.type === 'date' || itemVal?.type === 'text') && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>
                                      {aliasObject?.[itemVal?.aliasLabel] || itemVal?.label}
                                    </label>
                                    <Box pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field, onChange }) => {
                                            return (
                                              <TextField
                                                {...field}
                                                defaultValue={field?.value}
                                                className={itemVal?.fieldClass}
                                                onChange={(value) => {
                                                  onChangeValue({
                                                    value: value,
                                                    field: itemVal,
                                                    watch: watch(),
                                                  });
                                                }}
                                                placeholder={itemVal?.placeholder}
                                                variant={itemVal?.variant}
                                                margin={itemVal?.margin}
                                                name={itemVal?.name}
                                                type={itemVal?.type}
                                                multiline={itemVal?.multiline}
                                                minRows={itemVal?.rows}
                                                autoComplete={'new-password'}
                                                inputProps={{
                                                  readOnly:
                                                    readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                    itemVal.readOnly,
                                                  maxLength: itemVal?.maxLength,
                                                  min: !isEdit && disablePastDate(),
                                                }}
                                                disabled={itemVal?.disabled}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required:
                                              isEdit &&
                                              itemVal?.type == 'password' &&
                                              !itemVal?.requiredOnEdit
                                                ? false
                                                : itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* Textarea resizable */}
                                {itemVal?.type === 'resizableTextArea' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>
                                      {aliasObject?.[itemVal?.aliasLabel] || itemVal?.label}
                                    </label>
                                    <Box pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field }) => {
                                            return (
                                              <TextareaAutosize
                                                {...field}
                                                placeholder={itemVal?.placeholder}
                                                className={itemVal?.fieldClass}
                                                variant={itemVal?.variant}
                                                margin={itemVal?.margin}
                                                name={itemVal?.name}
                                                type={itemVal?.type}
                                                minRows={itemVal?.rows}
                                                maxLength={itemVal?.maxLength}
                                                minLength={itemVal?.minLength}
                                                autoComplete={'new-password'}
                                                readOnly={
                                                  readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                  itemVal.readOnly
                                                }
                                                inputProps={{
                                                  readOnly:
                                                    readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                    itemVal.readOnly,
                                                }}
                                                disabled={itemVal?.disabled}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Multiselect */}
                                {(itemVal?.type === 'multiSelectCreate' ||
                                  itemVal?.type === 'singleSelectCreate') && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field: { onChange, value, name, ref } }) => {
                                            return (
                                              <CreatableSelect
                                                isClearable={true}
                                                inputRef={ref}
                                                defaultValue={commonFn.getMultiValueFromId(
                                                  values?.[itemVal?.valueId],
                                                  options?.[itemVal?.optionsKey],
                                                )}
                                                name={name}
                                                value={value}
                                                isMulti={true}
                                                options={options?.[itemVal?.optionsKey] || []}
                                                className={itemVal?.fieldClass}
                                                placeholder={itemVal?.placeholder}
                                                isDisabled={
                                                  isView
                                                    ? true
                                                    : '' ||
                                                      readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                    ? true
                                                    : ''
                                                }
                                                onChange={(value) => {
                                                  onChange(value);
                                                  onChangeValue({
                                                    value: value,
                                                    field: itemVal,
                                                    watch: watch(),
                                                  });
                                                }}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* select and multiselect */}
                                {(itemVal?.type === 'multiselect' ||
                                  itemVal?.type === 'select') && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field: { onChange, value, name, ref } }) => {
                                            return (
                                              <Select
                                                isClearable={true}
                                                inputRef={ref}
                                                defaultValue={commonFn.getMultiValueFromId(
                                                  values?.[itemVal?.valueId],
                                                  options?.[itemVal?.optionsKey],
                                                )}
                                                name={name}
                                                value={value}
                                                isMulti={
                                                  itemVal?.type === 'multiselect' ? true : false
                                                }
                                                options={options?.[itemVal?.optionsKey] || []}
                                                className={itemVal?.fieldClass}
                                                placeholder={itemVal?.placeholder}
                                                isDisabled={
                                                  isView
                                                    ? true
                                                    : '' ||
                                                      readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                    ? true
                                                    : ''
                                                }
                                                onChange={(value) => {
                                                  onChange(value);
                                                  onChangeValue({
                                                    value: value,
                                                    field: itemVal,
                                                    watch: watch(),
                                                  });
                                                }}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                      {itemVal?.name === 'leave_type_slug' && (
                                        <small className="text-success">{values?.message}</small>
                                      )}
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Button  */}
                                {itemVal?.type === 'button' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <PrimaryButton text={itemVal?.label} />
                                  </Box>
                                )}
                                {/* End: Button */}
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    </FormGroup>
                  )}
                </div>
              ))}

            {!isLoading &&
              !inProgress &&
              !error &&
              buttonSection.isRequired &&
              buttonSection?.list?.map((button, buttonIndex) => (
                <Grid container spacing={1} key={`button_${buttonIndex}`}>
                  {button?.createKey &&
                    hiddenButton &&
                    hiddenButton.indexOf(button?.createKey) == -1 && (
                      <Grid item xs={2}>
                        <Box mt={2}>
                          <PrimaryButton
                            text={
                              isView
                                ? button.viewText
                                : isEdit
                                ? button.editText
                                : button.createText
                            }
                          />
                        </Box>
                      </Grid>
                    )}
                </Grid>
              ))}

            {isLoading && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box mt={2}>{isLoading && <CircularProgress />}</Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </form>
    </>
  );
};
