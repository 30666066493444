import React from 'react';
import { DashboardHeader } from '../../common';
import { HolidaysRouter } from '.';
import { HrmsPageHeader } from '../../../data/HolidaysData';
import { Container } from '@material-ui/core';

export const HrmsHolidaysMain = () => {
  const headerData = { header: HrmsPageHeader };

  return (
    <>
      <Container maxWidth="xl">
        <DashboardHeader {...headerData} />
        <HolidaysRouter />
      </Container>
    </>
  );
};
