import React from 'react';
import styled from 'styled-components/macro';
import { device } from '../../theme';


const FooterWrapper = styled.div`
  background: #333333;
  display: flex;
  padding: 10px;
  justify-content:center;
  flex-wrap: wrap;

  @media ${device.tablet} {
		padding: 13px 18  px;
	}
`

export const Footer = (props) => {
  return <FooterWrapper>
    <div>Footer</div>
  </FooterWrapper>;
};

Footer.defaultProps = {};
