import { Validation } from './CommonData';
import { Edit, Delete } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    day: 'Monday',
    date: 'Jan 01, 2021',
    holiday: `New Year's Day`,
  },
  {
    id: 2,
    day: 'Wednesday',
    date: 'Jan 14, 2021',
    holiday: 'Makar Sankranti / Pongal',
  },
  {
    id: 3,
    day: 'Friday',
    date: 'Jan 26, 2021',
    holiday: 'Republic Day',
  },
];

// reimbursements table header
export const ReimbursementHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: '', bodyClass: '' },
  {
    label: 'Company',
    key: 'company_logo',
    hiddenKey: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-100',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'From',
    key: 'from_date',
    hide: false,
    headClass: 'w-100',
    type: 'text',
    link: '',
  },
  {
    label: 'To',
    key: 'to_date',
    hide: false,
    headClass: 'w-100',
    type: 'text',
    link: '',
  },
  {
    label: 'Status',
    key: 'status',
    hide: false,
    headClass: 'w-100',
    type: 'text',
    link: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hiddenKey: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: Delete,
        text: 'Delete',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// company reimbursement page header
export const PageHeader = {
  className: 'page-header',
  page_title: 'Holidays',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Reimbursements', link: '', className: 'active' },
  ],
};

// Hrms reimbursement  page header
export const HrmsPageHeader = {
  className: 'page-header',
  page_title: 'Reimbursements',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Reimbursements', link: '', className: 'active' },
  ],
};

// employee reimbursement page header
export const EmployeePageHeader = {
  className: 'page-header',
  page_title: 'Reimbursements',
  button: {
    text: 'Create Reimbursement',
    className: 'link-button btn-sm btn-primary',
    url: '/employee/reimbursements/create',
    hiddenKey: 'create-reimbursement',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/employee/profile', className: '' },
    { text: 'Reimbursements', link: '', className: 'active' },
  ],
};

// Tabs data
export const CompanyTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Reimbursements',
      key: 'reimbursements',
      link: '/company/reimbursement',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
  ],
};

// employee rei tab
export const EmployeeTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Reimbursement',
      key: 'create',
      link: '/employee/reimbursements/create',
      path: '/employee/reimbursements/create',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
    {
      label: 'Reimbursements',
      key: 'reimbursements',
      link: '/employee/reimbursements',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
    {
      label: 'View Reimbursement',
      key: 'view',
      link: '/employee/reimbursements/view/:id',
      path: '/employee/reimbursements/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
    {
      label: 'Edit Reimbursement',
      key: 'edit',
      link: '/employee/reimbursements/edit/:id',
      path: '/employee/reimbursements/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-reimbursement',
    },
  ],
};

// invoice tabs data
export const HrmsTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Reimbursements',
      key: 'reimbursements',
      link: '/hrms/reimbursements',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
    {
      label: 'View Reimbursement',
      key: 'view',
      link: '/hrms/reimbursements/view/:id',
      path: '/hrms/reimbursements/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-reimbursement',
    },
    {
      label: 'Edit Reimbursement',
      key: 'edit',
      link: '/hrms/reimbursements/edit/:id',
      path: '/hrms/reimbursements/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-reimbursement',
    },
  ],
};
