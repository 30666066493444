import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HrmsLeaves } from '.';
import { ViewLeave } from '../../common';
import { AddOrEditLeave } from '../../common';

export const LeavesRouter = () => {
  return (
    <>
      {/* <Switch>
        <Route exact path={'/hrms/leaves'} component={HrmsLeaves} />
        <Route
          path={'/hrms/leaves/view/:id'}
          render={(props) => <ViewLeave isView={true} {...props} />}
        />
        <Route
          path={'/hrms/leaves/edit/:id'}
          render={(props) => <AddOrEditLeave isEdit={true} {...props} />}
        />
      </Switch> */}
    </>
  );
};
