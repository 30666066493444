import React, { useEffect } from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Box,
  Tooltip,
  TextField,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Edit, Delete } from '@material-ui/icons';
import './Table.scss';
import { colors } from '../../theme';
import { PrimaryButton } from '.';
import { commonFn } from '../../util/commonFn';
import Select from 'react-select';
import './Table.scss';

export const Holidays = ({
  data = [],
  head = [],
  key = 'key',
  clickOnCell = () => {},
  errorMessage = 'No data found',
  pagination = {},
  hiddenKeys = [],
  aliasObject = {},
  count = 0,
  skip = 0,
  hiddenAction = [],
  tableClass = '',
  formInputs = [],
  error = null,
  inProgress = true,
  isLoading = false,
  values = {},
  isEdit = false,
  editIndex = '',
  formSubmitHandler = () => {},
  changeEvent = () => {},
  skipButtonClick = () => {},
  module = '',
  options = [],
  holidayNote = {},
} = {}) => {
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: values,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = form;

  useEffect(() => {
    if (Object.keys(values)?.length) {
      reset(values);
    }
  }, [values]);

  const onChangeValue = (data) => {
    changeEvent(data);
  };

  const closeBtnClick = (data) => {
    skipButtonClick(data);
  };

  const formSubmit = (data) => {
    formSubmitHandler(data, reset);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ width: '100%', marginTop: '20px' }}>
        <form method="POST" onSubmit={handleSubmit(formSubmit)}>
          <Table className={'table ' + tableClass}>
            <TableHead>
              <TableRow>
                {head &&
                  head.map((thead, thIndex) => (
                    <>
                      {hiddenKeys && hiddenKeys.indexOf(thead.hiddenKey) === -1 && (
                        <TableCell
                          hidden={thead?.hide}
                          key={`thead_${thIndex}`}
                          className={thead?.headClass}
                        >
                          {thead?.label}
                        </TableCell>
                      )}
                    </>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {/* Create Holiday */}
                {module === 'hrms' && !isEdit && (
                  <TableRow>
                    <TableCell className="w-100"></TableCell>
                    {formInputs[0]?.inputs &&
                      formInputs[0]?.inputs.map((input, inputIndex) => (
                        <TableCell key={inputIndex}>
                          {(input?.type === 'text' || input?.type === 'date') && (
                            <FormControl className={input?.formControlClass}>
                              <TextField
                                className={input?.formElementClass}
                                placeholder={input?.placeholder}
                                variant={input?.variant}
                                margin={input?.margin}
                                name={input?.name}
                                type={input?.type}
                                multiline={input?.multiline}
                                autoComplete={'off'}
                                rows={input?.rows}
                                {...register(input?.name)}
                                inputProps={{
                                  readOnly: input.readOnly,
                                  maxLength: input?.maxLength,
                                }}
                                disabled={input?.disabled}
                              />
                            </FormControl>
                          )}

                          {/* if select */}
                          {input?.type === 'select' && (
                            <FormControl className={input?.formControlClass}>
                              <Select
                                isClearable={true}
                                name={input?.name}
                                options={(options && options) || []}
                                className={input?.fieldClass}
                                placeholder={input?.placeholder}
                                onChange={(value) => {
                                  onChangeValue({
                                    value: value,
                                    field: input,
                                    watch: watch(),
                                  });
                                }}
                              />
                            </FormControl>
                          )}

                          {/* Type: Button  */}
                          {!isLoading && !inProgress && !error && input?.type === 'button' && (
                            <Box className={input?.formElementClass}>
                              <PrimaryButton text={input?.label} />
                            </Box>
                          )}
                        </TableCell>
                      ))}
                  </TableRow>
                )}
                {/* End Create Holiday */}

                {/* mapping holidays data from api response */}
                {data &&
                  !inProgress &&
                  data.map((holiday, valueIndex) => (
                    <TableRow key={valueIndex}>
                      <TableCell key={`day_${valueIndex}`}>
                        <span key={valueIndex}>{commonFn.getDayFromDate(holiday?.date)}</span>
                      </TableCell>

                      {/* date td cell */}
                      <TableCell key={`date_${valueIndex}`}>
                        {editIndex !== valueIndex && <span> {holiday?.date}</span>}
                        {isEdit && editIndex === valueIndex && (
                          <Box pt={0}>
                            <TextField
                              className={formInputs[0]?.inputs[0]?.formElementClass}
                              placeholder={formInputs[0]?.inputs[0]?.placeholder}
                              variant={formInputs[0]?.inputs[0]?.variant}
                              margin={formInputs[0]?.inputs[0]?.margin}
                              name={formInputs[0]?.inputs[0]?.name}
                              id={formInputs[0]?.inputs[0]?.name + valueIndex}
                              defaultValue={holiday?.date}
                              type={formInputs[0]?.inputs[0]?.type}
                              multiline={formInputs[0]?.inputs[0]?.multiline}
                              rows={formInputs[0]?.inputs[0]?.rows}
                              onChange={(e) => onChangeValue(e)}
                            />
                          </Box>
                        )}
                      </TableCell>

                      {/* holiday td cell */}
                      <TableCell>
                        {editIndex !== valueIndex && <span> {holiday?.holiday} </span>}

                        {isEdit && editIndex === valueIndex && (
                          <Box pt={0}>
                            <TextField
                              className={formInputs[0]?.inputs[1]?.formElementClass}
                              placeholder={formInputs[0]?.inputs[1]?.placeholder}
                              variant={formInputs[0]?.inputs[1]?.variant}
                              margin={formInputs[0]?.inputs[1]?.margin}
                              name={formInputs[0]?.inputs[1]?.name}
                              id={formInputs[0]?.inputs[0]?.name + valueIndex}
                              defaultValue={holiday?.holiday}
                              type={formInputs[0]?.inputs[1]?.type}
                              multiline={formInputs[0]?.inputs[1]?.multiline}
                              rows={formInputs[0]?.inputs[1]?.rows}
                              onChange={(event) => onChangeValue(event)}
                            />
                          </Box>
                        )}
                      </TableCell>

                      {/* type */}
                      <TableCell>
                        {editIndex !== valueIndex && (
                          <span
                            className={
                              holiday.type == 'optional'
                                ? 'badge-outlined badge-success'
                                : holiday.type == 'mandatory'
                                ? 'badge-outlined badge-warning'
                                : ''
                            }
                          >
                            {holiday?.type}
                          </span>
                        )}

                        {isEdit && editIndex === valueIndex && (
                          <Box pt={0}>
                            <Select
                              isClearable={true}
                              name={formInputs[0]?.inputs[2]?.name}
                              options={(options && options) || []}
                              defaultValue={commonFn.getMultiValueFromId(
                                holiday?.type,
                                options && options,
                              )}
                              className={formInputs[0]?.inputs[2]?.fieldClass}
                              placeholder={formInputs[0]?.inputs[2]?.placeholder}
                              onChange={(value) => {
                                onChangeValue({
                                  value: value,
                                  field: formInputs[0]?.inputs[2],
                                  watch: watch(),
                                });
                              }}
                            />
                          </Box>
                        )}
                      </TableCell>

                      {/* edit action */}
                      {hiddenKeys && hiddenKeys.indexOf('actions') === -1 && (
                        <TableCell>
                          {editIndex !== valueIndex && (
                            <>
                              {/* Edit icon */}
                              <span
                                className={'icon-wrapper'}
                                onClick={(event) =>
                                  clickOnCell(
                                    { action: 'edit', row: holiday, index: valueIndex },
                                    event,
                                  )
                                }
                              >
                                <Tooltip title={'Edit'} arrow>
                                  <Edit className={'icon-success'} />
                                </Tooltip>
                              </span>

                              {/* delete icon */}
                              <span
                                className={'icon-wrapper'}
                                onClick={(event) =>
                                  clickOnCell(
                                    { action: 'delete', row: holiday, index: valueIndex },
                                    event,
                                  )
                                }
                              >
                                <Tooltip title={'Delete'} arrow>
                                  <Delete className={'icon-danger'} />
                                </Tooltip>
                              </span>
                            </>
                          )}

                          {/* update button */}
                          {isEdit &&
                            editIndex === valueIndex &&
                            !isLoading &&
                            !inProgress &&
                            !error && (
                              <Box pt={0}>
                                <PrimaryButton text={'Update'} key={`update_${valueIndex}`} />

                                <span
                                  className={'view-button ml-10'}
                                  onClick={(e) => closeBtnClick({ e, action: 'close' })}
                                  key={`close_action_${valueIndex}`}
                                >
                                  <PrimaryButton
                                    class={'view-close'}
                                    text={'Close'}
                                    key={`close_btn_${valueIndex}`}
                                  />
                                </span>
                              </Box>
                            )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                {(data && data.length) === 0 && errorMessage && !inProgress && (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="center"
                      style={{ color: colors?.primary?.orange100 }}
                    >
                      {errorMessage}
                    </TableCell>
                  </TableRow>
                )}

                {inProgress && (
                  <TableRow>
                    <TableCell
                      colSpan={4}
                      align="center"
                      style={{ color: colors?.primary?.orange100 }}
                    >
                      <div className="loader">
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </form>
      </TableContainer>
    </>
  );
};
