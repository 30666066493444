import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../theme';
import { NavBrand } from '../../data/HeaderData';
import { Link } from 'react-router-dom';
import { Accordion } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { HighlightOff } from '@mui/icons-material';
import { openSidebar } from '../../redux/common/action';

const SidebarWrapper = styled.div`
  color: ${colors.primary.black90};
  background: ${colors.white};
  transition: all 0.3s ease-in-out;
  width: 240px;
  height: 100%;
  position: fixed;
  left: 0px;
  z-index: 9;
  border-right: 1px solid ${colors.lightversion};
`;

const NavbarBrand = styled.div`
  display: block;
  vertical-align: middle;
  padding: 10px 13px;
  text-align: center;
  border-bottom: 2px solid ${colors.primary.yellow100};
`;

const BrandImage = styled.img`
  width: 86%;
  position: relative !important;
`;

const LeftSidebarNav = styled.div`
  padding: 0px;

  .accordion-menu {
    padding: 0px;
  }

  .accordion-item {
    padding: 10px 10px;
  }

  .accordion-item a {
    color: ${colors.primary.black90};
    font-size: 16px;
  }
`;

export const Sidebar = (props) => {
  const { sidebarOpenStatus } = useSelector(({ common }) => ({
    ...common,
  }));

  const dispatch = useDispatch();

  useEffect(() => {}, [sidebarOpenStatus?.status]);

  const closeSidebar = () => {
    dispatch(openSidebar(false));
  };

  return (
    <SidebarWrapper
      className={sidebarOpenStatus?.status ? 'sidebar-wrapper collapsed' : 'sidebar-wrapper'}
    >
      <NavbarBrand className="navbar-brand">
        <Link to={NavBrand?.url || ''} className="brand-link">
          <BrandImage src={NavBrand.logo.dark} />
        </Link>

        <Link to={'#'} className="sidebar-close" onClick={() => closeSidebar()}>
          <HighlightOff />
        </Link>
      </NavbarBrand>

      <div className="sidebar-scroll-div">
        <LeftSidebarNav className="left-sidebar-nav">
          <Accordion />
        </LeftSidebarNav>
      </div>
    </SidebarWrapper>
  );
};

Sidebar.defaultProps = {};
