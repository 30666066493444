import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Users, CreateUser, ViewUser } from '.';
import { commonFn } from '../../util/commonFn';
import { NotFound } from '../common';

export const UsersRouter = (props) => {
  return (
    <>
      <Switch>
        {commonFn.menuHiddenKeys.indexOf('list-users') === -1 && (
          <Route exact path={'/users'} component={Users} />
        )}
        {commonFn.menuHiddenKeys.indexOf('create-user') === -1 && (
          <Route path={'/users/create'} component={CreateUser} />
        )}
        <Route path={'/users/view/:id'} render={(props) => <ViewUser isView={true} {...props} />} />
        {commonFn.menuHiddenKeys.indexOf('edit-user') === -1 && (
          <Route
            path={'/users/edit/:id'}
            render={(props) => <CreateUser isEdit={true} {...props} />}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
