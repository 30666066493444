import React, { useState, useEffect } from 'react';
import 'date-fns';
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Box,
  TextField,
  InputLabel,
  MenuItem,
  FormGroup,
  Avatar,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
  TextareaAutosize,
} from '@material-ui/core';

import { LinkPrimaryButton, PrimaryButton } from '.';
import { DropzoneArea } from 'material-ui-dropzone';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import './FormCustom.scss';
import { Link, useLocation } from 'react-router-dom';
import PrimaryOutlinedButton from './PrimaryOutlinedButton';
import { useForm, Controller } from 'react-hook-form';
import { commonFn } from '../../util/commonFn';
import { colors } from '../../theme';
import { withStyles } from '@material-ui/core/styles';

const YellowSwitch = withStyles({
  switchBase: {
    color: colors?.primary?.yellowp1,
    '&$checked': {
      color: colors?.primary?.yellowp1,
    },
    '&$checked + $track': {
      backgroundColor: colors?.primary?.yellowp1,
    },
  },
  checked: {},
  track: {},
})(Switch);

export const FormCustom = ({
  list = [],
  formClass = '',
  formVariant = 'outlined',
  options = {},
  isView = false,
  isEdit = false,
  values = {},
  error = null,
  inProgress = false,
  hiddenKeys = [],
  aliasObject = {},
  readOnlyKeys = [],
  formSubmit = () => {},
  changeEvent = () => {},
  renderer = {},
  buttonSection = {
    isRequired: true,
    list: [{ viewText: 'Edit', editText: 'Update', createText: 'Save' }],
  },
  logo = '',
  isLoading = false,
  groupLabelText = '',
  isUploadDocument = false,
} = {}) => {
  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: values,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = form;

  useEffect(() => {
    console.log('Current selected dropdown value:', selectedDropdownValue);
    console.log('Current selected dropdown value:', selectedDropdownValue.value);
    if (Object.keys(values)?.length) {
      reset({ ...watch(), ...values });
    }
  }, [values]);

  const [selectedDropdownValue, setSelectedDropdownValue] = useState('');

  const onChangeValue = (data) => {
    if (data) {
      changeEvent(data);
      console.log('Dropdown onchange event:', data);
      console.log('Dropdown onchange event:', data.field.label);
      if (data.field.label === 'User Type') {
        setSelectedDropdownValue(data.value);
      }
    }
  };

  const errorMessage = (itemVal = {}) => {
    return (
      <>
        {itemVal?.errors?.map((error, index) => (
          <span key={`span_${index}`}>
            {errors[itemVal?.name]?.type === error?.type && (
              <span className="error">{error?.message}</span>
            )}
          </span>
        ))}
      </>
    );
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(formSubmit)}>
        <Card className={`${formClass}`} variant={formVariant}>
          <CardContent>
            {/* Form elements grouped based on the data */}
            {inProgress && (
              <div className="align-center mt-36">
                <CircularProgress />
              </div>
            )}
            {!inProgress && error && <div className="align-center mt-36">{error}</div>}
            {!inProgress &&
              !error &&
              list?.map((group, index) => (
                <div key={`list_${index}`}>
                  {group?.key && hiddenKeys.indexOf(group.hiddenKey) == -1 && (
                    <FormGroup className={group?.groupClass}>
                      {/*  Company avatar */}
                      <div className={group?.avatar?.formControlClass}>
                        {(watch().company_id?.logo && (
                          <img src={watch().company_id?.logo} style={{ maxWidth: '100px' }} />
                        )) ||
                          (group?.avatar && (
                            <Avatar
                              variant={group?.avatar?.variant}
                              className={group.avatar.className}
                            >
                              {group?.avatar?.logo}
                            </Avatar>
                          ))}

                        <Box pb={2}>
                          <h5 className={group?.groupLabelClass}>
                            {/* {group?.key.indexOf(groupLabelText) !== -1 &&
                              watch()?.client_id?.label &&
                              watch()?.client_id?.label} */}
                            {group?.groupLabel}
                          </h5>
                        </Box>
                      </div>

                      <Grid container spacing={1}>
                        {group?.inputs?.map((itemVal, groupIndex) => (
                          <>
                            {hiddenKeys.indexOf(itemVal.hiddenKey) == -1 && (
                              <Grid
                                item
                                xs={itemVal?.xs || 12}
                                sm={itemVal?.sm || 12}
                                md={itemVal?.md || 6}
                                lg={itemVal?.lg || 4}
                                xl={itemVal?.xl || 4}
                                key={`grid_${groupIndex}`}
                              >
                                {/* Type: Text */}
                                {(itemVal?.type === 'text' ||
                                  itemVal?.type === 'date' ||
                                  itemVal?.type === 'password') && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>
                                      {aliasObject?.[itemVal?.aliasLabel] || itemVal?.label}
                                    </label>
                                    <Box pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field }) => {
                                            return (
                                              <TextField
                                                {...field}
                                                defaultValue={field?.value}
                                                className={itemVal?.fieldClass}
                                                placeholder={
                                                  (itemVal?.type === 'text' ||
                                                    itemVal?.type === 'password') &&
                                                  (aliasObject?.[itemVal?.aliasPlaceholder] ||
                                                    itemVal?.placeholder)
                                                }
                                                variant={itemVal?.variant}
                                                margin={itemVal?.margin}
                                                name={itemVal?.name}
                                                type={itemVal?.type}
                                                multiline={itemVal?.multiline}
                                                rows={itemVal?.rows}
                                                autoComplete={'new-password'}
                                                inputProps={{
                                                  readOnly:
                                                    readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                    itemVal.readOnly,

                                                  maxLength: itemVal?.maxLength,
                                                }}
                                                disabled={itemVal?.disabled}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required:
                                              isEdit &&
                                              itemVal?.type == 'password' &&
                                              !itemVal?.requiredOnEdit
                                                ? false
                                                : itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Multiselect with create  */}
                                {(itemVal?.type === 'multiSelectCreate' ||
                                  itemVal?.type === 'singleSelectCreate') && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field: { onChange, value, name, ref } }) => {
                                            return (
                                              <CreatableSelect
                                                inputRef={ref}
                                                defaultValue={commonFn.getMultiValueFromId(
                                                  values?.[itemVal?.valueId],
                                                  options?.[itemVal?.optionsKey],
                                                )}
                                                isMulti={
                                                  itemVal?.type === 'multiSelectCreate'
                                                    ? true
                                                    : false
                                                }
                                                isClearable={true}
                                                value={value}
                                                name={name}
                                                className={itemVal?.fieldClass}
                                                placeholder={itemVal?.placeholder}
                                                isDisabled={
                                                  isView
                                                    ? true
                                                    : '' ||
                                                      readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                    ? true
                                                    : ''
                                                }
                                                options={options?.[itemVal?.optionsKey] || []}
                                                onChange={(value) => {
                                                  onChange(value);
                                                  onChangeValue({
                                                    value: value,
                                                    field: itemVal,
                                                    watch: watch(),
                                                  });
                                                }}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* Textarea resizable */}
                                {itemVal?.type === 'resizableTextArea' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>
                                      {aliasObject?.[itemVal?.aliasLabel] || itemVal?.label}
                                    </label>
                                    <Box pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field }) => {
                                            return (
                                              <TextareaAutosize
                                                {...field}
                                                placeholder={itemVal?.placeholder}
                                                className={itemVal?.fieldClass}
                                                variant={itemVal?.variant}
                                                margin={itemVal?.margin}
                                                name={itemVal?.name}
                                                type={itemVal?.type}
                                                minRows={itemVal?.rows}
                                                maxLength={itemVal?.maxLength}
                                                minLength={itemVal?.minLength}
                                                autoComplete={'new-password'}
                                                readOnly={
                                                  readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                  itemVal.readOnly
                                                }
                                                inputProps={{
                                                  readOnly:
                                                    readOnlyKeys.indexOf(itemVal?.name) !== -1 ||
                                                    itemVal.readOnly,
                                                }}
                                                disabled={itemVal?.disabled}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}
                                {/* Partner Selection */}
                                {selectedDropdownValue?.value === 'partners' &&
                                  itemVal?.label === 'Partners Account Name' && (
                                    <>
                                      <Box className={itemVal?.formElementClass}>
                                        <label className={itemVal?.labelClass}>
                                          {itemVal?.label}
                                        </label>
                                        <Box pt={1} pb={1}>
                                          <FormControl className={itemVal?.formControlClass}>
                                            <Controller
                                              render={({
                                                field: { onChange, value, name, ref },
                                              }) => {
                                                return (
                                                  <Select
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    defaultValue={commonFn.getMultiValueFromId(
                                                      values?.[itemVal?.valueId],
                                                      options?.[itemVal?.optionsKey],
                                                    )}
                                                    name={name}
                                                    value={value}
                                                    isMulti={
                                                      itemVal?.type === 'multiselect' ? true : false
                                                    }
                                                    options={options?.[itemVal?.optionsKey] || []}
                                                    className={itemVal?.fieldClass}
                                                    placeholder={itemVal?.placeholder}
                                                    isDisabled={
                                                      isView
                                                        ? true
                                                        : '' ||
                                                          readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                        ? true
                                                        : ''
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      onChangeValue({
                                                        value: value,
                                                        field: itemVal,
                                                        watch: watch(),
                                                      });
                                                    }}
                                                  />
                                                );
                                              }}
                                              control={control}
                                              name={itemVal?.name}
                                              rules={{
                                                required: itemVal?.isRequired,
                                                pattern: itemVal?.pattern,
                                                minLength: itemVal?.minLength,
                                              }}
                                            />
                                          </FormControl>
                                          {errorMessage(itemVal)}
                                        </Box>
                                      </Box>
                                    </>
                                  )}
                                {/* Client seelction */}
                                {(selectedDropdownValue?.value === 'client' ||
                                  selectedDropdownValue?.value === 'super-admin') &&
                                  itemVal?.label === 'Company Name' && (
                                    <>
                                      <Box className={itemVal?.formElementClass}>
                                        <label className={itemVal?.labelClass}>
                                          {itemVal?.label}
                                        </label>
                                        <Box pt={1} pb={1}>
                                          <FormControl className={itemVal?.formControlClass}>
                                            <Controller
                                              render={({
                                                field: { onChange, value, name, ref },
                                              }) => {
                                                return (
                                                  <Select
                                                    isClearable={true}
                                                    inputRef={ref}
                                                    defaultValue={commonFn.getMultiValueFromId(
                                                      values?.[itemVal?.valueId],
                                                      options?.[itemVal?.optionsKey],
                                                    )}
                                                    name={name}
                                                    value={value}
                                                    isMulti={
                                                      itemVal?.type === 'multiselect' ? true : false
                                                    }
                                                    options={options?.[itemVal?.optionsKey] || []}
                                                    className={itemVal?.fieldClass}
                                                    placeholder={itemVal?.placeholder}
                                                    isDisabled={
                                                      isView
                                                        ? true
                                                        : '' ||
                                                          readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                        ? true
                                                        : ''
                                                    }
                                                    onChange={(value) => {
                                                      onChange(value);
                                                      onChangeValue({
                                                        value: value,
                                                        field: itemVal,
                                                        watch: watch(),
                                                      });
                                                    }}
                                                  />
                                                );
                                              }}
                                              control={control}
                                              name={itemVal?.name}
                                              rules={{
                                                required: itemVal?.isRequired,
                                                pattern: itemVal?.pattern,
                                                minLength: itemVal?.minLength,
                                              }}
                                            />
                                          </FormControl>
                                          {errorMessage(itemVal)}
                                        </Box>
                                      </Box>
                                    </>
                                  )}

                                {/* Partner and client Selection */}
                                {selectedDropdownValue?.value === 'partners_client' &&
                                  (itemVal?.label === 'Company Name' ||
                                    itemVal?.label === 'Partners Account Name') && (
                                    <Box className={itemVal?.formElementClass}>
                                      <label className={itemVal?.labelClass}>
                                        {itemVal?.label}
                                      </label>
                                      <Box pt={1} pb={1}>
                                        <FormControl className={itemVal?.formControlClass}>
                                          <Controller
                                            render={({ field: { onChange, value, name, ref } }) => {
                                              return (
                                                <Select
                                                  isClearable={true}
                                                  inputRef={ref}
                                                  defaultValue={commonFn.getMultiValueFromId(
                                                    values?.[itemVal?.valueId],
                                                    options?.[itemVal?.optionsKey],
                                                  )}
                                                  name={name}
                                                  value={value}
                                                  isMulti={
                                                    itemVal?.type === 'multiselect' ? true : false
                                                  }
                                                  options={options?.[itemVal?.optionsKey] || []}
                                                  className={itemVal?.fieldClass}
                                                  placeholder={itemVal?.placeholder}
                                                  isDisabled={
                                                    isView
                                                      ? true
                                                      : '' ||
                                                        readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                      ? true
                                                      : ''
                                                  }
                                                  onChange={(value) => {
                                                    onChange(value);
                                                    onChangeValue({
                                                      value: value,
                                                      field: itemVal,
                                                      watch: watch(),
                                                    });
                                                  }}
                                                />
                                              );
                                            }}
                                            control={control}
                                            name={itemVal?.name}
                                            rules={{
                                              required: itemVal?.isRequired,
                                              pattern: itemVal?.pattern,
                                              minLength: itemVal?.minLength,
                                            }}
                                          />
                                        </FormControl>
                                        {errorMessage(itemVal)}
                                      </Box>
                                    </Box>
                                  )}

                                {/* Type: Multiselect */}
                                {(itemVal?.type === 'multiselect' ||
                                  (itemVal?.type === 'select' &&
                                    itemVal?.label !== 'Company Name' &&
                                    itemVal?.label !== 'Partners Account Name')) && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Controller
                                          render={({ field: { onChange, value, name, ref } }) => {
                                            return (
                                              <Select
                                                isClearable={true}
                                                inputRef={ref}
                                                defaultValue={commonFn.getMultiValueFromId(
                                                  values?.[itemVal?.valueId],
                                                  options?.[itemVal?.optionsKey],
                                                )}
                                                name={name}
                                                value={value}
                                                isMulti={
                                                  itemVal?.type === 'multiselect' ? true : false
                                                }
                                                options={options?.[itemVal?.optionsKey] || []}
                                                className={itemVal?.fieldClass}
                                                placeholder={itemVal?.placeholder}
                                                isDisabled={
                                                  isView
                                                    ? true
                                                    : '' ||
                                                      readOnlyKeys.indexOf(itemVal?.name) !== -1
                                                    ? true
                                                    : ''
                                                }
                                                onChange={(value) => {
                                                  onChange(value);
                                                  onChangeValue({
                                                    value: value,
                                                    field: itemVal,
                                                    watch: watch(),
                                                  });
                                                }}
                                              />
                                            );
                                          }}
                                          control={control}
                                          name={itemVal?.name}
                                          rules={{
                                            required: itemVal?.isRequired,
                                            pattern: itemVal?.pattern,
                                            minLength: itemVal?.minLength,
                                          }}
                                        />
                                      </FormControl>
                                      {errorMessage(itemVal)}
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Avatar  */}
                                {itemVal?.type === 'avatar' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl
                                        className={itemVal?.formControlClass}
                                        variant={itemVal?.variant}
                                        size={itemVal?.size}
                                      >
                                        <Avatar variant={itemVal?.variant}>{itemVal?.logo}</Avatar>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Link */}
                                {isEdit && itemVal?.type === 'link' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Link
                                          to={values?.[itemVal.name]}
                                          target={itemVal?.target}
                                          name={values?.[itemVal.displayKey]}
                                        >
                                          {values?.[itemVal.displayKey]}
                                        </Link>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: Link */}
                                {!isEdit && itemVal?.type === 'link' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <label className={itemVal?.labelClass}>{itemVal?.label}</label>
                                    <Box pt={1} pb={1}>
                                      <FormControl className={itemVal?.formControlClass}>
                                        <Link
                                          to={values?.[itemVal.name]}
                                          target={itemVal?.target}
                                          name={values?.[itemVal.displayKey]}
                                        >
                                          {values?.[itemVal.displayKey]}
                                        </Link>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                )}

                                {/* Type: File Upload */}
                                {itemVal?.type === 'file' && (
                                  <Box
                                    className={itemVal?.formElementClass}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box pt={1} pb={1}>
                                      <label>{itemVal?.placeholder}</label>
                                      <Box pt={1} pb={1} className="file-container">
                                        <FormControl className={itemVal?.formControlClass}>
                                          <input
                                            accept={itemVal?.accept}
                                            className={itemVal.fieldClass}
                                            id={itemVal?.id}
                                            type={itemVal?.type}
                                            disabled={isView && true}
                                            onChange={(e) =>
                                              onChangeValue({
                                                value: e,
                                                field: itemVal,
                                                watch: watch(),
                                              })
                                            }
                                          />

                                          {isUploadDocument && (
                                            <div className="align-center w-100">
                                              <CircularProgress />
                                            </div>
                                          )}

                                          {!isUploadDocument && (
                                            <label htmlFor={itemVal?.id}>
                                              <IconButton component="span">
                                                {(values?.[itemVal.name] &&
                                                  values?.[itemVal.name]?.length > 2 &&
                                                  itemVal?.uploadType !== 'doc' && (
                                                    <img
                                                      className={itemVal.imageClass}
                                                      src={values?.[itemVal.name]}
                                                    />
                                                  )) ||
                                                  ''}
                                                {(!values?.[itemVal.name] ||
                                                  values?.[itemVal.name]?.length <= 2) && (
                                                  <itemVal.icon />
                                                )}
                                              </IconButton>
                                            </label>
                                          )}
                                        </FormControl>
                                      </Box>
                                    </Box>

                                    {/* Upload button */}
                                    {!isView && !isUploadDocument && (
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="end"
                                      >
                                        <label
                                          htmlFor={itemVal?.id}
                                          onClick={() =>
                                            document.getElementById(itemVal?.id).click()
                                          }
                                        >
                                          <PrimaryButton
                                            hidden={itemVal?.hideUploadButton}
                                            htmlFor={itemVal?.id}
                                            text={itemVal?.buttonText}
                                            type="button"
                                          />
                                        </label>
                                        <span
                                          hidden={itemVal?.hideRemoveButton}
                                          onClick={() =>
                                            onChangeValue({
                                              value: '',
                                              field: { name: 'remove' },
                                              watch: watch(),
                                            })
                                          }
                                        >
                                          <PrimaryOutlinedButton text="Remove" type="button" />
                                        </span>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                                {/* End: File Upload */}

                                {itemVal?.type === 'renderer' && (
                                  <>{renderer?.[itemVal.key]?.(form, watch())}</>
                                )}

                                {/* End: Toggle Switch  */}

                                {/* Type: Button  */}
                                {itemVal?.type === 'button' && (
                                  <Box className={itemVal?.formElementClass}>
                                    <PrimaryButton text={itemVal?.label} />
                                  </Box>
                                )}
                                {/* End: Button */}
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    </FormGroup>
                  )}
                </div>
              ))}

            {!isLoading &&
              !inProgress &&
              !error &&
              buttonSection.isRequired &&
              buttonSection?.list?.map((button, buttonIndex) => (
                <Grid container spacing={1} key={`button_${buttonIndex}`}>
                  <Grid item xs={2}>
                    <Box mt={2}>
                      <PrimaryButton
                        text={
                          isView ? button.viewText : isEdit ? button.editText : button.createText
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}

            {isLoading && (
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box mt={2}>{isLoading && <CircularProgress />}</Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </form>
    </>
  );
};
