export const Types = {
  START_LOGIN: 'START_LOGIN',
  END_LOGIN: 'END_LOGIN',
  START_RESET_PASSWORD: 'START_RESET_PASSWORD',
  END_RESET_PASSWORD: 'END_RESET_PASSWORD',
  START_FORGOT_PASSWORD: 'START_FORGOT_PASSWORD',
  END_FORGOT_PASSWORD: 'END_FORGOT_PASSWORD',
  START_VERIFY: 'START_VERIFY',
  END_VERIFY: 'END_VERIFY',
  START_IS_AUTH: 'START_IS_AUTH',
  END_IS_AUTH: 'END_IS_AUTH',
  START_LOGOUT: 'START_LOGOUT',
  END_LOGOUT: 'END_LOGOUT',
};
