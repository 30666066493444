import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyLeaves } from '.';
import { AttendanceRegister } from '.';
import { ViewLeave, AddOrEditLeave } from '../../common';

export const LeavesRouter = () => {
  return (
    <>
      {/* <Switch>
        <Route exact path={'/company/leaves'} component={CompanyLeaves} />
        <Route
          path={'/company/leaves/view/:id'}
          render={(props) => <ViewLeave isView={true} {...props} />}
        />
        <Route
          path={'/company/leaves/edit/:id'}
          render={(props) => <AddOrEditLeave isEdit={true} {...props} />}
        />
        <Route exact path={'/company/attendance'} component={AttendanceRegister} />
      </Switch> */}
    </>
  );
};
