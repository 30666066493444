import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmployeeHolidays } from '.';

export const HolidaysRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/employee/holidays'} component={EmployeeHolidays} />
      </Switch>
    </>
  );
};
