import { Types } from './types';

const initState = {
  loginProcess: false,
  loginError: null,
  loginSuccess: null,
  verifyProcess: false,
  verifyError: null,
  verifySuccess: null,
  userInfo: null,
  isAuthicatedProcess: false,
  isAuthicatedError: null,
  userLogoutPorcess: false,
  logoutSuccess: null,
  logoutError: null,
  forgotProcess: false,
  forgotError: null,
  forgotSuccess: null,
  resetProcess: false,
  resetError: null,
  resetSuccess: null,
};

export function sign(state = initState, action) {
  switch (action.type) {
    case Types.START_LOGIN:
      return {
        ...state,
        loginProcess: true,
        loginError: null,
        loginSuccess: null,
        logoutSuccess: null,
      };
    case Types.END_LOGIN: {
      const { success, error } = action.payload;
      return {
        ...state,
        loginProcess: false,
        loginError: error,
        loginSuccess: success,
        userInfo: success,
      };
    }

    case Types.START_FORGOT_PASSWORD:
      return {
        ...state,
        forgotProcess: true,
        forgotError: null,
        forgotSuccess: null,
        logoutSuccess: null,
      };
    case Types.END_FORGOT_PASSWORD: {
      const { success, error } = action.payload;
      return {
        ...state,
        forgotProcess: false,
        forgotError: error,
        forgotSuccess: success,
        userInfo: success,
      };
    }

    case Types.START_RESET_PASSWORD:
      return {
        ...state,
        resetProcess: true,
        resetError: null,
        resetSuccess: null,
        logoutSuccess: null,
      };
    case Types.END_RESET_PASSWORD: {
      const { success, error } = action.payload;
      return {
        ...state,
        resetProcess: false,
        resetError: error,
        resetSuccess: success,
        userInfo: success,
      };
    }
    case Types.START_VERIFY:
      return {
        ...state,
        verifyProcess: true,
        verifyError: null,
        verifySuccess: null,
      };
    case Types.END_VERIFY: {
      const { success, error } = action.payload;
      return {
        ...state,
        verifyProcess: false,
        verifyError: error,
        verifySuccess: success,
      };
    }
    case Types.START_IS_AUTH:
      return {
        ...state,
        isAuthicatedProcess: true,
        logoutSuccess: null,
      };
    case Types.END_IS_AUTH: {
      const { success, error } = action.payload;
      return {
        ...state,
        isAuthicatedProcess: false,
        userInfo: success,
        isAuthicatedError: error,
      };
    }
    case Types.START_LOGOUT:
      return {
        ...state,
        userLogoutPorcess: true,
        logoutSuccess: null,
        logoutError: null,
      };
    case Types.END_LOGOUT: {
      const { success, error } = action.payload;
      return {
        ...state,
        userLogoutPorcess: false,
        logoutSuccess: success,
        logoutError: error,
        userInfo: null,
      };
    }
    default:
      return state;
  }
}
