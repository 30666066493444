import { clients } from '../redux/clients/reducer';
import { Validation } from './CommonData';
import { AttachFile } from '@material-ui/icons';

export const FormInputsData = [
  {
    groupLabel: 'Company',
    groupLabelClass: 'fieldset-label',
    key: 'personal',
    groupClass: 'form-fieldset',
    avatar: {
      key: 'company_logo',
      name: 'avatar',
      hide: false,
      formControlClass: 'company-avatar-wrapper',
      type: 'file',
      className: 'form-avatar',
      variant: 'rounded',
      logo: 'FK',
    },
    inputs: [
      {
        label: 'Company Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'company_id',
        name: 'company_id',
        placeholder: 'Company Name',
        hide: false,
        optionsKey: 'clients',
        valueId: 'company_id',
        displayKey: 'company_name',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        options: [],
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Select the company' }],
      },
      {
        label: 'Position',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'position',
        name: 'position',
        placeholder: 'Position',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Enter the position' },
          { type: 'minLength', message: 'Position name is too short' },
        ],
      },
      {
        label: 'Position Type',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'position_type_id',
        name: 'position_type_id',
        placeholder: 'Select Position Type',
        hide: false,
        optionsKey: 'positionType',
        valueId: 'position_type_id',
        displayKey: 'position_type',
        formControlClass: 'input-form-control',
        type: 'multiselect', // multiSelectCreate
        fieldClass: 'textBoxStyle multiSelectStyle z-index-2', // 'textBoxStyle multiSelectStyle z-index-2',
        variant: 'outlined',
        options: [],
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        size: 'small',
        errors: [{ type: 'required', message: 'Select the position type' }],
      },
      {
        label: 'Skills',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 4,
        xl: 4,
        key: 'skill_id',
        name: 'skill_id',
        displayKey: 'skills',
        placeholder: 'Select Skills',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'multiSelectCreate',
        optionsKey: 'skills',
        valueId: 'skill_id',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        options: [],
        size: 'small',
        isRequired: true,
        errors: [{ type: 'required', message: 'Select or create skill' }],
      },
      {
        label: 'Min Experience',
        xs: 6,
        sm: 6,
        md: 3,
        lg: 2,
        xl: 2,
        key: 'min_experience',
        name: 'min_experience',
        placeholder: 'Min Exp.',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.numberOnly,
        minLength: 1,
        maxLength: 2,
        errors: [
          { type: 'required', message: 'Min experience' },
          { type: 'pattern', message: 'Experience (eg. 6)' },
        ],
      },
      {
        label: 'Exp Type',
        labelClass: 'hide-label',
        xs: 6,
        sm: 3,
        md: 3,
        lg: 2,
        xl: 2,
        key: 'min_exp_type_id',
        name: 'min_exp_type_id',
        placeholder: 'Select',
        hide: false,
        optionsKey: 'min_exp_type',
        valueId: 'min_experience_type',
        displayKey: 'min_experience_type',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        isClearable: false,
        margin: 'dense',
        formElementClass: 'form-input-box px-0',
        isRequired: true,
        minLength: 1,
        maxLength: 20,
        options: [],
        errors: [{ type: 'required', message: 'Experience type' }],
      },
      {
        label: 'Max Experience',
        xs: 6,
        sm: 6,
        md: 3,
        lg: 2,
        xl: 2,
        key: 'max_experience',
        name: 'max_experience',
        placeholder: 'Max Exp.',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.numberOnly,
        errors: [
          { type: 'required', message: 'Max experience' },
          { type: 'pattern', message: 'Experience (eg. 4)' },
        ],
      },

      {
        label: 'Exp Type',
        labelClass: 'hide-label',
        xs: 6,
        sm: 3,
        md: 3,
        lg: 2,
        xl: 2,
        key: 'max_exp_type_id',
        name: 'max_exp_type_id',
        placeholder: 'Select',
        hide: false,
        optionsKey: 'max_exp_type',
        valueId: 'max_experience_type',
        displayKey: 'max_experience_type',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        isClearable: false,
        margin: 'dense',
        formElementClass: 'form-input-box px-0',
        isRequired: true,
        errors: [{ type: 'required', message: 'Experience type' }],
      },
      {
        label: 'Status',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'status_id',
        name: 'status_id',
        placeholder: 'Status',
        hide: false,
        optionsKey: 'status',
        valueId: 'status_id',
        displayKey: 'status',
        formControlClass: 'input-form-control',
        type: 'select', // singleSelectCreate
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        options: [{ label: 'Open' }],
        isRequired: true,
        size: 'small',
        minLength: 1,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create status' }],
      },
      {
        label: 'No. of Position',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'no_of_position',
        name: 'total_position',
        placeholder: 'No. of Position',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 1,
        maxLength: 5,
        pattern: Validation.numberOnly,
        errors: [
          { type: 'required', message: 'Enter total no. of positions. (eg. 20)' },
          { type: 'pattern', message: 'Positions must be a valid number. (eg. 20)' },
        ],
      },
      {
        label: 'Maximum Salary',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'max_salary',
        name: 'max_salary',
        placeholder: 'Maximum Salary',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle multiSelectStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberOnly,
        minLength: 4,
        maxLength: 20,
        errors: [
          { type: 'pattern', message: 'Salary must be a valid number (eg. 500000)' },
          { type: 'minLength', message: 'Salary must be a atleast 4 digit number' },
        ],
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'country_id',
        name: 'country_id',
        placeholder: 'Select Country',
        hide: false,
        optionsKey: 'country',
        valueId: 'country_id',
        displayKey: 'country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        // minLength: 3,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the country' }],
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'state_id',
        name: 'state_id',
        placeholder: 'Select State',
        hide: false,
        optionsKey: 'state',
        valueId: 'state_id',
        displayKey: 'state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'city_id',
        name: 'city_id',
        placeholder: 'Select City',
        hide: false,
        optionsKey: 'city',
        valueId: 'city_id',
        displayKey: 'city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },

      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadJD',
      },

      // {
      //   label: 'Upload JD',
      //   xs: 12,
      //   sm: 12,
      //   md: 6,
      //   lg: 4,
      //   xl: 4,
      //   uploadType: 'doc',
      //   key: 'job_description',
      //   placeholder: 'Upload JD',
      //   hide: false,
      //   formControlClass: 'input-form-control file-upload-control',
      //   type: 'file',
      //   imageClass: 'form-control-image',
      //   displayImageClass: 'display-avatar-logo',
      //   name: 'job_description',
      //   fieldClass: 'input-file-button',
      //   id: 'company-avatar-upload',
      //   variant: 'outlined',
      //   margin: 'dense',
      //   formElementClass: 'form-input-box file-upload-element jd-upload',
      //   icon: AttachFile,
      //   accept: '.pdf,.docx',
      //   buttonText: 'Upload JD',
      //   uploadText: 'Upload JD',
      //   uploadTextClass: 'file-upload-text',
      //   // hideRemoveButton: true,
      //   // hideUploadButton: true,
      // },

      // {
      //   label: 'Job Description',
      //   xs: 12,
      //   sm: 12,
      //   md: 12,
      //   lg: 4,
      //   xl: 4,
      //   key: 'job_description',
      //   name: 'job_description',
      //   placeholder: 'Open JD',
      //   displayKey: 'job_description_original',
      //   hide: false,
      //   formControlClass: 'input-form-control',
      //   type: 'link',
      //   fieldClass: '',
      //   variant: '',
      //   margin: '',
      //   formElementClass: 'form-input-box',
      //   url: '',
      //   target: '_blank',
      // },
    ],
  },
];

export const FileUpload = {
  label: 'Job Description',
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 4,
  uploadType: 'doc',
  key: 'job_description_orignial',
  placeholder: 'Upload JD',
  formControlClass: 'input-form-control file-upload-control',
  type: 'file',
  imageClass: 'form-control-image',
  displayImageClass: 'display-avatar-logo',
  name: 'job_description',
  fieldClass: 'input-file-button',
  id: 'company-avatar-upload',
  variant: 'outlined',
  margin: 'dense',
  formElementClass: 'form-input-box file-upload-element jd-upload',
  // accept: '.pdf,.docx',
  uploadTextClass: 'file-label',
};

export const expType = [
  { label: 'Month(s)', value: 1 },
  { label: 'Year(s)', value: 2 },
];
