import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyEmployees } from '.';
import { commonFn } from '../../../util/commonFn';
import { AddOrEditEmployee, ViewEmployee } from '../../common';
import { NotFound } from '../../common';

export const EmployeesRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/company/employees'} component={CompanyEmployees} />
        <Route
          path={'/company/employees/view/:id'}
          render={(props) => <ViewEmployee isView={true} {...props} />}
        />
        <Route
          path={'/company/employees/edit/:id'}
          render={(props) => <AddOrEditEmployee isEdit={true} {...props} />}
        />

        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
