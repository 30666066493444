import React, { useEffect, useState } from 'react';
import { FormInputsData, FileUpload, ClientCVUpload } from '../../data/CreateApplicantData';
import { Tabs } from '../../data/ApplicantsData';
import { ViewFormCustom, TabCustom } from '../common';
import { commonFn } from '../../util/commonFn';
import { applicantFind } from '../../redux/applicants/action';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid, TextField, Link, Box, FormControl } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export const ViewApplicant = ({ isEdit, isView, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState([]);
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [aliasObject, setAliasObject] = useState({});

  const { applicantFindError, applicantFindData, applicantFindProcess, userInfo } = useSelector(
    ({ applicants, sign }) => ({
      ...applicants,
      ...sign,
    }),
  );

  useEffect(() => {
    if (userInfo?.data?.type_slug === 'client') {
      setHiddenKeys(() => [
        'current_ctc',
        'expected_ctc',
        'phone',
        'email',
        'linkedin',
        'upload_cv',
        'internal_hr_comments',
      ]);

      if (
        userInfo?.data?.role_slug === 'user' &&
        userInfo?.data?.permissions_slug?.indexOf('show-client ctc') === -1
      ) {
        setHiddenKeys((data) => [...data, 'client_ctc']);
      }

      setAliasObject(() => ({
        client_ctc_label: 'CTC',
        client_ctc_placehoder: 'CTC',
        client_cv_label: 'CV',
        client_cv_placeholder: 'Upload CV',
      }));
    }

    if (
      userInfo?.data?.type_slug === 'super-admin' &&
      userInfo?.data?.role_slug === 'user' &&
      userInfo?.data?.permissions_slug?.indexOf('show-client ctc') === -1
    ) {
      setHiddenKeys((data) => [...data, 'client_ctc']);
    }
  }, [userInfo]);

  useEffect(() => {
    match?.params?.id && dispatch(applicantFind(match?.params?.id));
  }, []);

  useEffect(() => {
    if (applicantFindData?.data?.interview_status) {
      const list = [];
      applicantFindData?.data?.interview_status.map((item, index) => {
        list.push({
          statusValue: item.status,
          commentValue: item.comment,
          interviewDateValue: item.interview_date,
          commentLabel: `Comment ${index + 1}`,
          statusLabel: `Status ${index + 1}`,
          interviewDateLabel: `Interview Date ${index + 1}`,
        });
        setInputList(list);
      });
    }
    if (applicantFindData?.data && userInfo?.data.type_slug === 'client') {
      if (!applicantFindData?.data?.client_ctc) {
        setHiddenKeys((data) => [...data, 'client_ctc']);
      }
    }
  }, [applicantFindError, applicantFindData, applicantFindProcess, userInfo]);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const formData = {
    list: FormInputsData,
    tabs: tabs,
    inProgress: applicantFindProcess,
    error:
      (applicantFindData?.data === null && applicantFindData?.message) ||
      applicantFindError?.message,
    isEdit,
    isView,
    value: applicantFindData?.data,
  };

  // Edit Button Action
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-applicant') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/applicants/edit/${match?.params?.id}`);
    } else if (value?.button?.key === 'close') {
      history.push(`/applicants`);
    }
  };

  // interview status
  const InterviewStatus = () => {
    return (
      <>
        {inputList.map((list, inputListIndex) => (
          <Grid container spacing={1} key={inputListIndex}>
            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                <label className="interview-label">{list.statusLabel}</label>
                <label className="text-muted">{list.statusValue}</label>
              </Box>
            </Grid>

            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                <label className="interview-label">{list.commentLabel}</label>
                <label className="text-muted">{list.commentValue}</label>
              </Box>
            </Grid>

            <Grid
              item
              xs={list?.xs || 12}
              sm={list?.sm || 12}
              md={list?.md || 6}
              lg={list?.lg || 4}
              xl={list?.xl || 4}
            >
              <Box className="form-input-box">
                <label className="interview-label">{list.interviewDateLabel}</label>
                <label className="text-muted">{list.interviewDateValue}</label>
              </Box>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  // display cv
  const UploadCV = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={FileUpload?.xs || 12}
            sm={FileUpload?.sm || 12}
            md={FileUpload?.md || 6}
            lg={FileUpload?.lg || 4}
            xl={FileUpload?.xl || 4}
          >
            <Box className={FileUpload?.formElementClass}>
              <label className="interview-label">{FileUpload.label}</label>
              {formData.value?.cv && (
                <div className="file-section">
                  <a
                    href={formData.value?.cv}
                    target="_blank"
                    className={FileUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.cv_name}
                  </a>
                </div>
              )}
              {!formData.value?.cv && <div className="text-muted">-</div>}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  // display client cv
  const UploadClientCV = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={ClientCVUpload?.xs || 12}
            sm={ClientCVUpload?.sm || 12}
            md={ClientCVUpload?.md || 6}
            lg={ClientCVUpload?.lg || 4}
            xl={ClientCVUpload?.xl || 4}
          >
            <Box className={ClientCVUpload?.formElementClass}>
              <label className="interview-label">
                {aliasObject?.[ClientCVUpload?.clientCvLabel] || ClientCVUpload.label}
              </label>
              {formData.value?.client_cv && (
                <div className="file-section">
                  <a
                    href={formData.value?.client_cv}
                    target="_blank"
                    className={ClientCVUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.client_cv_name}
                  </a>
                </div>
              )}
              {!formData.value?.client_cv && <div className="text-muted">-</div>}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom
        {...formData}
        clickOnBtn={clickOnBtn}
        renderer={{ InterviewStatus, UploadCV, UploadClientCV }}
        hiddenKeys={hiddenKeys}
        aliasObject={aliasObject}
      />
    </>
  );
};

export default ViewApplicant;
