import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmployeeLeaves } from '.';
import { ViewLeave } from '../../common';
import { AddOrEditLeave } from '../../common';

export const LeavesRouter = () => {
  return (
    <>
      {/* <Switch>
        <Route exact path={'/employee/leaves'} component={EmployeeLeaves} />
        <Route path={'/employee/leaves/create'} render={(props) => <AddOrEditLeave {...props} />} />
        <Route
          path={'/employee/leaves/view/:id'}
          render={(props) => <ViewLeave isView={true} {...props} />}
        />
        <Route
          path={'/employee/leaves/edit/:id'}
          render={(props) => <AddOrEditLeave isEdit={true} {...props} />}
        />
      </Switch> */}
    </>
  );
};
