import React from 'react';
import { PayrollRouter } from '.';
import { DashboardHeader } from '../../common';
import { HrmsPageHeader } from '../../../data/PayrollData';
import { Container } from '@material-ui/core';

export const HrmsPayrollMain = () => {
  const headerData = { header: HrmsPageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <PayrollRouter />
    </Container>
  );
};
