import React, { Suspense } from 'react';
import { MainRouter } from './components/Router.jsx';
import { host } from './util/environment';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router } from 'react-router-dom';
import { CustomSnackbar } from './components/common';

export const App = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Router basename={host()} history={createBrowserHistory()}>
          <MainRouter />
        </Router>
        <CustomSnackbar />
      </Suspense>
    </>
  );
};
