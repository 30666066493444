import React from 'react';
import styled from 'styled-components';

let MainContentWrapper = styled.div `
    margin-top: 61px;
    h2 {
        text-align: center;
    }
`;

export const Dashboard = () => {
    return (
        <MainContentWrapper>
            <h2> Dashboard component </h2>
        </MainContentWrapper>
    )
}