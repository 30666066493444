import { Types } from './types';

const initState = {
  vendorBankFindProcess: false,
  vendorBankFindError: null,
  vendorBankFindData: null,
};

export const vendorBankDetail = (state = initState, action) => {
  switch (action.type) {
    case Types.START_FIND_VENDOR_BANK_DETAIL:
      return {
        ...state,
        vendorBankFindProcess: true,
        vendorBankFindError: null,
        vendorBankFindData: null,
      };
    case Types.END_FIND_VENDOR_BANK_DETAIL: {
      const { success, error } = action.payload;
      return {
        ...state,
        vendorBankFindProcess: false,
        vendorBankFindError: error,
        vendorBankFindData: success,
      };
    }
    default:
      return state;
  }
};
