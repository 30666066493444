import { Types } from './types';

const initState = {
  payrollsCreateProcess: false,
  payrollsCreateError: null,
  payrollsCreateData: null,

  payrollsFindAllProcess: false,
  payrollsFindAllError: null,
  payrollsFindAllData: null,

  payrollFindProcess: false,
  payrollFindError: null,
  payrollFindData: null,

  payrollUpdateProcess: false,
  payrollUpdateError: null,
  payrollUpdateData: null,
};

export const payrolls = (state = initState, action) => {
  switch (action.type) {
    // create payroll
    case Types.START_CREATE_PAYROLL:
      return {
        ...state,
        payrollsCreateProcess: true,
        payrollsCreateError: null,
        payrollsCreateData: null,
      };
    case Types.END_CREATE_PAYROLL: {
      const { success, error } = action.payload;
      return {
        ...state,
        payrollsCreateProcess: false,
        payrollsCreateError: error,
        payrollsCreateData: success,
      };
    }

    // find all payrolls
    case Types.START_FIND_ALL_PAYROLL:
      return {
        ...state,
        payrollsFindAllProcess: true,
        payrollsFindAllError: null,
        payrollsFindAllData: null,
      };
    case Types.END_FIND_ALL_PAYROLL: {
      const { success, error } = action.payload;
      return {
        ...state,
        payrollsFindAllProcess: false,
        payrollsFindAllError: error,
        payrollsFindAllData: success,
      };
    }

    // find payroll
    case Types.START_FIND_PAYROLL:
      return {
        ...state,
        payrollFindProcess: true,
        payrollFindError: null,
        payrollFindData: null,
      };
    case Types.END_FIND_PAYROLL: {
      const { success, error } = action.payload;
      return {
        ...state,
        payrollFindProcess: false,
        payrollFindError: error,
        payrollFindData: success,
      };
    }

     // update payroll
     case Types.START_UPDATE_PAYROLL:
      return {
        ...state,
        payrollUpdateProcess: true,
        payrollUpdateError: null,
        payrollUpdateData: null,
      };
    case Types.END_UPDATE_PAYROLL: {
      const { success, error } = action.payload;
      return {
        ...state,
        payrollUpdateProcess: false,
        payrollUpdateError: error,
        payrollUpdateData: success,
      };
    }
    default:
      return state;
  }
};
