import React from 'react';
import { EmployeesRouter } from '.';
import { DashboardHeader } from '../../common';
import { PageHeader } from '../../../data/EmployeeData';
import { Container } from '@material-ui/core';

export const EmployeesMain = () => {
  const headerData = { header: PageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <EmployeesRouter />
    </Container>
  );
};
