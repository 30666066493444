import { getService, postService } from '../../services/httpService';
import { Types } from './types';
import { apiList } from '../../util/apiList';

// City Find All
const startCitiesFindAll = () => ({
  type: Types.START_CITY_FIND_ALL,
  payload: {},
});

const endCitiesFindAll = (success, error) => ({
  type: Types.END_CITY_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const citiesFindAll = () => async (dispatch) => {
  dispatch(startCitiesFindAll());
  const [response, error] = await getService(`${apiList.cities}`);
  dispatch(endCitiesFindAll(response, error));
};


// States Find All
const startStateFindAll = () => ({
  type: Types.START_STATE_FIND_ALL,
  payload: {},
});

const endStateFindAll = (success, error) => ({
  type: Types.END_STATE_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const statesFindAll = () => async (dispatch) => {
  dispatch(startStateFindAll());
  const [response, error] = await getService(`${apiList.states}`);
  dispatch(endStateFindAll(response, error));
};

// countries Find All
const startCountriesFindAll = () => ({
  type: Types.START_COUNTRY_FIND_ALL,
  payload: {},
});

const endCountriesFindAll = (success, error) => ({
  type: Types.END_COUNTRY_FIND_ALL,
  payload: {
    success,
    error,
  },
});

export const countriesFindAll = () => async (dispatch) => {
  dispatch(startCountriesFindAll());
  const [response, error] = await getService(`${apiList.countries}`);
  dispatch(endCountriesFindAll(response, error));
};

// create country
const startCreateCountry = () => ({
  type: Types.START_CREATE_COUNTRY,
  payload: {},
});

const endCreateCountry = (success, error) => ({
  type: Types.END_CREATE_COUNTRY,
  payload: {
    success,
    error,
  },
});

export const createCountry = (data) => async (dispatch) => {
  dispatch(startCreateCountry());
  const [response, error] = await postService(apiList.countries, data);
  dispatch(endCreateCountry(response, error));
};

// create state
const startCreateState = () => ({
  type: Types.START_CREATE_STATE,
  payload: {},
});

const endCreateState = (success, error) => ({
  type: Types.END_CREATE_STATE,
  payload: {
    success,
    error,
  },
});

export const createState = (data) => async (dispatch) => {
  dispatch(startCreateState());
  const [response, error] = await postService(apiList.states, data);
  dispatch(endCreateState(response, error));
};

// create city
const startCreateCity = () => ({
  type: Types.START_CREATE_CITY,
  payload: {},
});

const endCreateCity = (success, error) => ({
  type: Types.END_CREATE_CITY,
  payload: {
    success,
    error,
  },
});

export const createCity = (data) => async (dispatch) => {
  dispatch(startCreateCity());
  const [response, error] = await postService(apiList.cities, data);
  dispatch(endCreateCity(response, error));
};