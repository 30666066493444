import React from 'react';
import { UsersRouter } from '.';
import { DashboardHeader } from '../common';
import { PageHeader } from '../../data/UsersData';
import { Container } from '@material-ui/core';

export const UsersMain = () => {
  const headerData = { header: PageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <UsersRouter />
    </Container>
  );
};
