import React from "react";
import styled from 'styled-components';
import { colors } from '../../theme';
import { Button } from '@material-ui/core';

const ButtonStyled = styled(Button)`
    background-color: ${colors.primary.white} !important;
    border-color: ${colors.primary.black90} !important;
    color: ${colors.black90} !important;
    text-transform: capitalize !important;
    padding: .25rem .5rem !important;
    line-height: 1.5 !important;
    height: 38px !important;
    font-size: 14px !important;
    margin-top: 3px !important;
    border-radius: .2rem !important;
    font-weight: 600;

    &:hover {
        background-color: ${colors.primary.black90} !important;
        color: ${colors.white} !important;
    }
`;

export const PrimaryOutlinedButton = (props) => {
    return <ButtonStyled variant="outlined"> {props.text} </ButtonStyled>
}

export default PrimaryOutlinedButton;