import React, { useState, useEffect } from 'react';
import { Holidays, TabCustom, HolidaysNote } from '../../common';
import { openSnackBarLayout } from '../../../redux/common/action';
import {
  HolidaysHead,
  ResponseData,
  HrmsHolidaysTabs,
  HolidayInputsData,
} from '../../../data/HolidaysData';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  findAllHolidays,
  createHoliday,
  holidayFind,
  updateHoliday,
  holidayDelete,
  findHolidaysNote,
  updateHolidayNote,
} from '../../../redux/holidays/action';
import { CircularProgress } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const HrmsHolidays = (match) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [holidayNoteEdit, setHolidayNoteEdit] = useState(false);
  const [editIndex, setEditIndex] = useState('');
  const [formData, setFormData] = useState({});
  const [noteContent, setNoteContent] = useState({});

  const MySwal = withReactContent(Swal);

  // Set page title
  useEffect(() => {
    document.title = 'Quark - HRMS | Holidays';
  }, []);

  const {
    holidaysFindAllProcess,
    holidaysFindAllError,
    holidaysFindAllData,
    holidayCreateProcess,
    holidayCreateError,
    holidayCreateData,
    holidayFindProcess,
    holidayFindError,
    holidayFindData,
    holidayUpdateProcess,
    holidayUpdateError,
    holidayUpdateData,
    holidayDeleteProcess,
    holidayDeleteError,
    holidayDeleteData,
    holidaysNoteFindProcess,
    holidaysNoteFindError,
    holidaysNoteFindData,
    holidaysNoteUpdateProcess,
    holidaysNoteUpdateError,
    holidaysNoteUpdateData,
  } = useSelector(({ holidays }) => ({
    ...holidays,
  }));

  useEffect(() => {
    dispatch(findAllHolidays());
    dispatch(findHolidaysNote());
  }, [skip]);

  //   tabs
  const tabs = {
    ...HrmsHolidaysTabs,
    list: commonFn.updateLink(HrmsHolidaysTabs.list, match),
  };

  //   map holidays data from API response
  const holidaysResponse = holidaysFindAllData && holidaysFindAllData?.data?.list;
  const holidaysData =
    holidaysResponse &&
    holidaysResponse.map((holiday) => {
      return { ...holiday };
    });

  // edit action - onclick
  const clickOnCell = (data) => {
    const holidayId = data && data?.row?.id;
    if (data?.action === 'edit') {
      setIsEdit(() => true);
      if (data?.index == 0) {
        setEditIndex(() => 0);
      } else {
        data?.index && setEditIndex(() => data?.index);
      }
      dispatch(holidayFind(holidayId));
    } else if (data?.action === 'delete') {
      MySwal.fire({
        title: 'Are you sure?',
        text: 'You want to delete the holiday',
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        result = result.isConfirmed && holidayId && dispatch(holidayDelete(holidayId));
        result &&
          MySwal.fire({
            title: 'Success!',
            text: 'Holiday has been deleted',
            icon: 'success',
            iconColor: '#3085d6',
          });
      });
    }
  };

  // holiday note action
  const holidayNoteAction = (data) => {
    const noteId = data && data?.row?.id;
    if (data?.action === 'edit') {
      setHolidayNoteEdit(() => true);
    }
  };

  // set edit holiday data
  useEffect(() => {
    if (!holidayFindProcess && !holidayFindError && holidayFindData) {
      const holidayData = holidayFindData?.data;
      delete holidayData.created_at;
      delete holidayData.updated_at;

      setFormData({
        ...formData,
        values: holidayData,
      });
    }
  }, [holidayFindProcess, holidayFindError, holidayFindData]);

  //   table data
  const tableData = {
    tabs: tabs,
    module: 'hrms',
    count: holidaysFindAllData?.data?.count,
    skip: skip,
    data: holidaysData,
    head: HolidaysHead,
    formInputs: HolidayInputsData,
    isEdit: isEdit,
    editIndex: editIndex,
    clickOnCell: clickOnCell,
    inProgress: holidaysFindAllProcess,
    errorMessage: holidaysFindAllError?.message,
    hiddenKeys: [],
  };

  // create holiday success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!holidayCreateProcess && !holidayCreateError && holidayCreateData) {
        dispatch(openSnackBarLayout(holidayCreateData?.message, 'success', 1000));
        setIsLoading(() => false);
      } else if (holidayCreateError) {
        dispatch(openSnackBarLayout(holidayCreateError?.message, 'error', 1000));
        setIsLoading(() => false);
      }
    }
  }, [holidayCreateProcess, holidayCreateError, holidayCreateData]);

  // update holiday reponse message
  useEffect(() => {
    if (isFormSubmitted) {
      if (
        (!holidayUpdateProcess && !holidayUpdateError && holidayUpdateData) ||
        (!holidaysNoteUpdateProcess && !holidaysNoteUpdateError && holidaysNoteUpdateData)
      ) {
        const successMessage =
          (holidayUpdateData && holidayUpdateData?.message) || holidaysNoteUpdateData?.message;
        dispatch(openSnackBarLayout(successMessage, 'success', 1000));
        setIsLoading(() => false);
        setIsEdit(() => false);
        setHolidayNoteEdit(() => false);
        setEditIndex(() => '');
      } else if (holidayUpdateError || holidaysNoteUpdateError) {
        const errorMessage =
          (holidayUpdateError?.message && holidayUpdateError?.message) ||
          holidaysNoteUpdateError?.message;
        dispatch(openSnackBarLayout(errorMessage, 'error', 1000));
        setIsLoading(() => false);
        setIsEdit(() => false);
        setHolidayNoteEdit(() => false);
        setEditIndex(() => '');
      }
    }
  }, [
    holidayUpdateProcess,
    holidayUpdateError,
    holidayUpdateData,
    holidaysNoteUpdateProcess,
    holidaysNoteUpdateError,
    holidaysNoteUpdateData,
  ]);

  // refresh holiday data table after create
  useEffect(() => {
    ((!holidayCreateProcess && !holidayCreateError && holidayCreateData) ||
      (!holidayUpdateProcess && !holidayUpdateError && holidayUpdateData) ||
      (!holidayDeleteProcess && !holidayDeleteError && holidayDeleteData)) &&
      dispatch(findAllHolidays(skip, limit));
  }, [
    holidayCreateProcess,
    holidayCreateError,
    holidayCreateData,
    holidayUpdateProcess,
    holidayUpdateError,
    holidayUpdateData,
    holidayDeleteProcess,
    holidayDeleteError,
    holidayDeleteData,
  ]);

  // refresh holiday note data after update
  useEffect(() => {
    if (!holidaysNoteUpdateProcess && !holidaysNoteUpdateError && holidaysNoteUpdateData) {
      dispatch(findHolidaysNote());
    }
  }, [holidaysNoteUpdateProcess, holidaysNoteUpdateError, holidaysNoteUpdateData]);

  // form submit
  const formSubmit = (data, reset) => {
    const request = data;
    request.date = (data?.date && data?.date) || formData?.values?.date;
    request.holiday = (data?.holiday && data.holiday) || formData?.values?.holiday;
    request.type = (data?.type && data?.type) || formData?.values?.type;

    if (!isEdit && data && !data.date) {
      dispatch(openSnackBarLayout('Please choose the date', 'error', 1000));
      return false;
    }

    if (!isEdit && data && !data.holiday) {
      dispatch(openSnackBarLayout('Please enter the holiday', 'error', 1000));
      return false;
    }

    setFormData((formData) => ({
      ...formData,
      values: {
        ...formData.values,
        type: '',
      },
    }));

    setIsFormSubmitted(() => true);
    setIsLoading(() => true);
    reset({});

    if (!isEdit) {
      dispatch(createHoliday(request));
    } else {
      formData?.values?.id && dispatch(updateHoliday(formData?.values?.id, request));
    }
  };

  // holiday note submit
  const holidayNoteSubmit = (data) => {
    if (data && !data.holidayNote) {
      dispatch(openSnackBarLayout('Please enter the holiday note', 'error', 1000));
      return false;
    }

    setIsFormSubmitted(() => true);
    setIsLoading(() => true);
    data.holidayNote = (data?.holidayNote && data?.holidayNote) || noteContent?.note;
    dispatch(updateHolidayNote(noteContent?.id, data));
  };

  // onchange event
  const changeEvent = async (data) => {
    const date = data && data?.target?.name === 'date' && data?.target?.value;
    const holiday = data && data?.target?.name === 'holiday' && data?.target?.value;
    const holidayType = data && data?.field?.name === 'holidayType' && data?.value?.value;

    setFormData({
      ...formData,
      values: {
        ...formData.values,
        date: date ? date : formData?.values?.date,
        holiday: holiday ? holiday : formData?.values?.holiday,
        type: holidayType ? holidayType : formData?.values?.type,
      },
    });
  };

  // skip button click
  const skipButtonClick = (data) => {
    if (data && data?.action === 'close') {
      setIsEdit(() => false);
      setEditIndex(() => '');
    }
  };

  // holiday type options
  const holidayTypes = [
    { label: 'Optional', value: 'optional' },
    { label: 'Mandatory', value: 'mandatory' },
  ];

  const actionCloseClick = (data) => {
    if (data && data?.action === 'close') {
      setHolidayNoteEdit(() => false);
    }
  };

  // holiday note find data
  const holidayNote =
    !holidaysNoteFindProcess &&
    !holidaysNoteFindError &&
    holidaysNoteFindData &&
    holidaysNoteFindData?.data;

  const holidayNoteContent = {
    content: noteContent,
    hiddenKeys: [],
    module: 'hrms',
    clickOnCell: holidayNoteAction,
    actionCloseClick: actionCloseClick,
  };

  useEffect(() => {
    !holidaysNoteFindProcess &&
      !holidaysNoteFindError &&
      holidaysNoteFindData &&
      setNoteContent(() => holidayNote);
  }, [holidaysNoteFindProcess, holidaysNoteFindError, holidaysNoteFindData]);

  return (
    <>
      {holidaysNoteFindData && (
        <HolidaysNote
          {...holidayNoteContent}
          formSubmit={holidayNoteSubmit}
          isEdit={holidayNoteEdit}
        />
      )}
      <TabCustom {...tabs} />

      <Box mb={4}>
        <Holidays
          {...tableData}
          formSubmitHandler={formSubmit}
          isLoading={isLoading}
          changeEvent={changeEvent}
          skipButtonClick={skipButtonClick}
          options={holidayTypes}
        />
      </Box>
    </>
  );
};
