import React from 'react';
import { DashboardHeader } from '../../common';
import { AccountsInvoicesRouter } from './InvoicesRouter';
import { InvoicePageHeader } from '../../../data/AccountsData';
import { Container } from '@material-ui/core';

export const AccountsInvoicesMain = () => {
  const headerData = { header: InvoicePageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <AccountsInvoicesRouter />
    </Container>
  );
};
