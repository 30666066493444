import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

// position table data
export const ResponseData = [
  {
    id: 1,
    company_logo: 'FK',
    company_name: 'Flipkoins',
    position: 'Laravel Developer',
    position_type: 'Full-Time',
    skills: 'PHP',
    application_count: 4,
    location: 'Bengaluru',
    status: 'Active',
  },
];

// Positions table header
export const PositionHead = [
  {
    label: 'id',
    key: 'id',
    hide: true,
    type: 'text',
    headClass: 'w-80',
    bodyClass: '',
  },
  {
    label: 'company_id',
    key: 'company_id',
    hide: true,
    type: 'text',
    headClass: 'w-80',
    bodyClass: '',
  },
  {
    label: 'Company',
    key: 'company_logo',
    hiddenKey: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  {
    label: 'Position',
    key: 'position',
    hide: false,
    type: 'text',
    toolTipKey: 'position',
    toolTipText: 'View Position',
    headClass: 'w-150',
    bodyClass: 'highlighted-link',
  },
  {
    label: 'Position Type',
    key: 'position_types',
    hide: false,
    type: 'array',
    keys: [{ key: 'position_type', hide: false, arrayClass: 'badge-outlined badge-success' }],
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Skills',
    key: 'skills',
    hide: false,
    type: 'array',
    keys: [{ key: 'skill', hide: false, arrayClass: 'badge-outlined badge-warning' }],
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Application Count',
    key: 'application_count',
    hide: false,
    type: 'text',
    toolTipKey: 'application_count',
    toolTipText: 'All Applicants',
    headClass: 'w-200',
    bodyClass: 'link-color',
  },
  // { label: 'Location', key: 'location', hide: false, type: 'text', headClass: 'w-150' },
  // { label: 'City', key: 'city', hide: false, type: 'text', headClass: 'w-150' },
  // { label: 'State', key: 'state', hide: false, type: 'text', headClass: 'w-150' },
  {
    label: 'Status',
    key: 'status',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: 'badge-outlined badge-warning',
  },
  {
    label: 'Min Experience',
    key: 'min_experience',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Max Experience',
    key: 'max_experience',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Max Salary',
    key: 'max_salary',
    hide: false,
    type: 'text',
    headClass: 'w-150',
    bodyClass: '',
  },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-warning',
        type: 'open',
        icon: GetAppIcon,
        text: 'Open JD',
        name: 'job_description',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: DeleteIcon,
        text: 'Delete',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
  {
    label: 'JD',
    key: 'job_description',
    hide: true,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
];

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Positions',
  button: {
    text: 'Create Position',
    hide: false,
    hiddenKey: 'create-position',
    className: 'link-button btn-sm btn-primary',
    url: '/positions/create',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Positions', link: '', className: 'active' },
  ],
};

// Filter data
export const MainFilter = {
  filterClass: '',
  list: [
    {
      label: 'Search',
      placeholder: 'Search',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'search',
      key: 'search',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'Position type',
      placeholder: 'Position type',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      variant: 'outlined',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      name: 'position_type',
      key: 'position_type',
      valueId: 'position_type_id',
      optionsKey: 'position_type',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [{ label: 'Part time', id: 1 }],
    },
    {
      label: 'Skills',
      placeholder: 'Skills',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      variant: 'outlined',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      name: 'skills',
      key: 'skills',
      valueId: 'skill_id',
      optionsKey: 'skills',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
      options: [],
    },
    {
      label: 'Company Name',
      placeholder: 'Company',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle multiSelectStyle z-index-5',
      margin: 'dense',
      hide: false,
      type: 'multiselect',
      name: 'clients',
      key: 'clients',
      valueId: 'client_id',
      optionsKey: 'clients',
      hiddenKey: 'clients',
      size: 'small',
      options: [],
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'Position',
      placeholder: 'Position',
      variant: 'outlined',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'position',
      key: 'position',
      valueId: 'position_id',
      optionsKey: 'positions',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    // {
    //   label: 'Min Experience',
    //   xs: 12,
    //   sm: 2,
    //   md: 2,
    //   lg: 2,
    //   xl: 2,
    //   key: 'min_experience',
    //   name: 'min_experience',
    //   placeholder: 'Min',
    //   hide: false,
    //   formControlClass: 'filter-formControl',
    //   type: 'text',
    //   fieldClass: 'textBoxStyle',
    //   variant: 'outlined',
    //   margin: 'dense',
    //   formElementClass: 'form-input-box',
    //   min: 1,
    //   max: 2,
    // },
    // {
    //   label: 'Exp Type',
    //   labelClass: 'hide-label',
    //   xs: 12,
    //   sm: 12,
    //   md: 3,
    //   lg: 2,
    //   xl: 2,
    //   key: 'min_exp_type',
    //   name: 'min_exp_type',
    //   placeholder: 'Select',
    //   hide: false,
    //   optionsKey: 'min_exp_type',
    //   valueId: 'min_exp_type_id',
    //   displayKey: 'min_experience_type',
    //   formControlClass: 'filter-formControl',
    //   type: 'select',
    //   fieldClass: 'textBoxStyle multiSelectStyle z-index-3',
    //   variant: 'outlined',
    //   isClearable: false,
    //   margin: 'dense',
    //   formElementClass: 'form-input-box px-0',
    //   min: 1,
    //   max: 20,
    //   options: [],
    // },
    // {
    //   label: 'Max Experience',
    //   placeholder: 'Max',
    //   xs: 6,
    //   sm: 6,
    //   md: 4,
    //   lg: 2,
    //   xl: 2,
    //   formControlClass: 'filter-formControl',
    //   fieldClass: 'textBoxStyle',
    //   labelClass: 'filter-label-min',
    //   variant: 'outlined',
    //   margin: 'dense',
    //   hide: false,
    //   type: 'text',
    //   name: 'max_experience',
    //   key: 'max_experience',
    // },
    // {
    //   label: 'Exp Type',
    //   labelClass: 'hide-label',
    //   xs: 12,
    //   sm: 12,
    //   md: 3,
    //   lg: 2,
    //   xl: 2,
    //   key: 'max_exp_type',
    //   name: 'max_exp_type',
    //   placeholder: 'Select',
    //   hide: false,
    //   optionsKey: 'max_exp_type',
    //   valueId: 'max_experience_type',
    //   displayKey: 'max_experience_type',
    //   formControlClass: 'filter-formControl',
    //   type: 'select',
    //   fieldClass: 'textBoxStyle multiSelectStyle z-index-3',
    //   variant: 'outlined',
    //   isClearable: false,
    //   margin: 'dense',
    //   formElementClass: 'form-input-box px-0',
    //   min: 1,
    //   max: 20,
    //   options: [],
    // },

    {
      label: 'Salary',
      placeholder: 'Min',
      xs: 12,
      sm: 3,
      md: 3,
      lg: 2,
      xl: 2,
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'min_salary',
      key: 'min_salary',
    },
    {
      label: '',
      placeholder: 'Max',
      xs: 12,
      sm: 3,
      md: 3,
      lg: 2,
      xl: 2,
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      labelClass: 'filter-label-min',
      variant: 'outlined',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'max_salary',
      key: 'max_salary',
    },
  ],
};

// Tabs data
export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Position',
      key: 'create',
      link: '/positions/create',
      hiddenKey: 'create-position',
      className: 'tab-link',
    },
    {
      label: 'Edit Position',
      key: 'edit',
      hiddenKey: 'edit-position',
      link: '/positions/edit/:id',
      path: '/positions/edit/:id',
      className: 'tab-link',
    },
    {
      label: 'View Position',
      key: 'view',
      link: '/positions/view/:id',
      path: '/positions/view/:id',
      hiddenKey: 'list-positions',
      className: 'tab-link',
      hiddenKey: '',
    },
    {
      label: 'Positions',
      key: 'positions',
      link: '/positions',
      className: 'tab-link',
      hiddenKey: 'list-positions',
    },
  ],
};
