import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { EmployeePayroll } from '.';
import { ViewPayroll } from '../../common';

export const PayrollRouter = () => {
  return (
    <Switch>
      <Route exact path={'/employee/payrolls'} component={EmployeePayroll} />
      <Route
        path={'/employee/payrolls/view/:id'}
        render={(props) => <ViewPayroll isView={true} {...props} />}
      />
    </Switch>
  );
};
