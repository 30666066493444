import React from 'react';
import { TabCustom, TableCustom, FilterCustom } from '.';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export const Reimbursements = ({
  match,
  data = [],
  head = [],
  tabs = [],
  module,
  count = 0,
  pagination = [],
  skip = 0,
  errorMessage,
  hiddenKeys = [],
  inProgress,
  filterData = [],
  filterFormSubmit = () => {},
  changeEvent = () => {},
}) => {
  const history = useHistory();

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const reimbursementId = data.row[0].value;

    if (module === 'hrms') {
      if (
        (reimbursementId && data.action?.key) ||
        (reimbursementId && data.action?.type === 'view')
      ) {
        history.push(`/hrms/reimbursements/view/${reimbursementId}`);
      }
      if (reimbursementId && data?.action?.type === 'edit') {
        history.push(`/hrms/reimbursements/edit/${reimbursementId}`);
      }
    } else if (module === 'employee') {
      if (
        (reimbursementId && data.action?.key) ||
        (reimbursementId && data.action?.type === 'view')
      ) {
        history.push(`/employee/reimbursements/view/${reimbursementId}`);
      }
    }
  };

  const tableData = {
    head: head || [],
    count: count || 0,
    data: data || [],
    skip: skip,
    clickOnCell: clickOnCell,
    pagination: pagination || [],
    errorMessage: errorMessage || 'No data found',
    hiddenKeys: hiddenKeys || [],
  };

  return (
    <>
      <TabCustom {...tabs} />

      {inProgress && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}

      {!inProgress && (
        <Box mb={4}>
          <TableCustom {...tableData} />
        </Box>
      )}
    </>
  );
};
