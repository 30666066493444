import { Types } from './types';

const initState = {
  interviewStatusFindProcess: false,
  interviewStatusFindError: null,
  interviewStatusFindData: null,

  interviewStatusCreateProcess: false,
  interviewStatusCreateError: null,
  interviewStatusCreateData: null
};

export const interviewStatus = (state = initState, action) => {
  switch (action.type) {
    case Types.START_FIND_INTERVIEW_STATUS:
      return {
        ...state,
        interviewStatusFindProcess: true,
        interviewStatusFindError: null,
        interviewStatusFindData: null,
      };
    case Types.END_FIND_INTERVIEW_STATUS: {
      const { success, error } = action.payload;
      return {
        ...state,
        interviewStatusFindProcess: false,
        interviewStatusFindError: error,
        interviewStatusFindData: success,
      };
    }

    // create interview status
    case Types.START_CREATE_INTERVIEW_STATUS:
      return {
        ...state,
        interviewStatusCreateProcess: true,
        interviewStatusCreateError: null,
        interviewStatusCreateData: null,
      };
    case Types.END_CREATE_INTERVIEW_STATUS: {
      const { success, error } = action.payload;
      return {
        ...state,
        interviewStatusCreateProcess: false,
        interviewStatusCreateError: error,
        interviewStatusCreateData: success,
      };
    }
    default:
      return state;
  }
};
