import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmployeeLeavesMain } from './leaves';
import { EmployeeProfileMain } from './profile';
import { EmployeePayrollMain } from './payrolls';
import { EmployeeHolidaysMain } from './holidays';
import { EmployeeReimbursementMain } from './reimbursement';
import { NotFound } from '../common';

export const EmployeeLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/employee/profile`} component={EmployeeProfileMain} />
        <Route path={`/employee/leaves`} component={EmployeeLeavesMain} />
        <Route path={`/employee/holidays`} component={EmployeeHolidaysMain} />
        <Route path={`/employee/payrolls`} component={EmployeePayrollMain} />
        <Route path={`/employee/reimbursements`} component={EmployeeReimbursementMain} />
        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
