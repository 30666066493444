import { Types } from './types';

const initState = {
  employeeDetailFindProcess: false,
  employeeDetailFindError: null,
  employeeDetailFindData: null,
};

export const employeeProfile = (state = initState, action) => {
  switch (action.type) {
    case Types.START_FIND_EMPLOYEE_DETAIL:
      return {
        ...state,
        employeeDetailFindProcess: true,
        employeeDetailFindError: null,
        employeeDetailFindData: null,
      };
    case Types.END_FIND_EMPLOYEE_DETAIL: {
      const { success, error } = action.payload;
      return {
        ...state,
        employeeDetailFindProcess: false,
        employeeDetailFindError: error,
        employeeDetailFindData: success,
      };
    }
    default:
      return state;
  }
};
