import React, { useState, useEffect } from 'react';
import { Payroll } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeeTabs, PayrollHead } from '../../../data/PayrollData';
import { findAllPayrolls } from '../../../redux/payrolls/action';

export const EmployeePayroll = ({ match }) => {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;

  const { payrollsFindAllProcess, payrollsFindAllError, payrollsFindAllData } = useSelector(
    ({ payrolls }) => ({
      ...payrolls,
    }),
  );

  useEffect(() => {
    document.title = 'Quark - Employee | Payrolls';
  }, []);

  // tabs
  const tabs = {
    ...EmployeeTabs,
    list: commonFn.updateLink(EmployeeTabs.list, match),
  };

  useEffect(() => {
    dispatch(findAllPayrolls(skip, limit));
  }, [skip]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllPayrolls(limit * (value - 1), limit));
  };

  const payrollResponse = payrollsFindAllData?.data?.list;
  const payrollData =
    payrollResponse &&
    payrollResponse.map((payroll) => {
      const text = [{ text: payroll?.full_name }, { text: payroll?.email }];
      return { ...payroll, full_name: text };
    });

  // table data
  const tableData = {
    tabs: tabs,
    module: 'employee',
    count: payrollsFindAllData?.data?.count,
    skip: skip,
    data: payrollData,
    head: PayrollHead,
    pagination: {
      numberOfPages: payrollsFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: payrollsFindAllError?.message,
    hiddenKeys: ['actions', 'full_name'],
    inProgress: payrollsFindAllProcess ? true : false,
  };

  return (
    <>
      <Payroll {...tableData} />
    </>
  );
};
