import React from 'react';
import { EmployeePageHeader } from '../../../data/PayrollData';
import { Container } from '@material-ui/core';
import { PayrollRouter } from '.';
import { DashboardHeader } from '../../common';

export const EmployeePayrollMain = () => {
  const headerData = { header: EmployeePageHeader };
  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <PayrollRouter />
    </Container>
  );
};
