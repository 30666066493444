import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Invoices } from '../../common';
import { Tabs, AccountsHead, ResponseData, MainFilter } from '../../../data/AccountsData';
import { TabCustom, TableCustom } from '../../common';
import { commonFn } from '../../../util/commonFn';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { findAllInvoices } from '../../../redux/invoices/action';
import { invoiceStatusFindAll } from '../../../redux/master/action';

export const Accounts = (match) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [filterValues, setFilterValues] = useState([]);

  const {
    invoiceFindAllProcess,
    invoiceFindAllError,
    invoiceFindAllData,
    invoiceStatusFindAllData,
  } = useSelector(({ invoices, invoiceStatus }) => ({
    ...invoices,
    ...invoiceStatus,
  }));

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Company | Accounts';
  }, []);

  useEffect(() => {
    dispatch(findAllInvoices(skip, limit));
    dispatch(invoiceStatusFindAll());
  }, [skip]);

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  const invoicesResponse = invoiceFindAllData && invoiceFindAllData?.data?.list;
  const invoiceData =
    invoicesResponse &&
    invoicesResponse.map((invoice) => {
      return { ...invoice };
    });

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(() => limit * (value - 1));
    dispatch(findAllInvoices(limit * (value - 1), limit));
  };

  // invoice status
  const invoiceStatus =
    invoiceStatusFindAllData &&
    invoiceStatusFindAllData?.data?.list?.map((status) => {
      return { label: status?.status, value: status?.id };
    });

  // filter data
  const filterData = {
    filter: MainFilter,
    options: { invoiceStatus },
  };

  // change event
  const changeEvent = (data) => {
    const filterArray = {};

    if (data?.field?.name === 'status_id') {
      const status = commonFn.getIdFromMultiValue(data?.value?.value);
      filterArray.status_id = status;
    }

    if (data?.field?.name === 'creator_id') {
      const creator = commonFn.getIdFromMultiValue(data?.value?.value);
      filterArray.creator_id = creator;
    }

    setFilterValues((filterValue) => ({
      ...filterValue,
      ...filterArray,
    }));
  };

  // filter form submit
  const filterFormSubmit = (data) => {
    const request = data;
    request.status_id = filterValues?.status_id;
    request.creator_id = filterValues?.creator_id;

    setFilterValues((filterValue) => ({ ...filterValue, ...request }));
    setSkip(() => 0);
    dispatch(findAllInvoices(0, limit, { ...filterValues, ...request }));
  };

  // table data
  const tableData = {
    tabs: tabs,
    module: 'company',
    count: invoiceFindAllData?.data?.count,
    skip: skip,
    data: invoiceData,
    head: AccountsHead,
    pagination: {
      numberOfPages: invoiceFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    errorMessage: invoiceFindAllError?.message,
    hiddenKeys: [
      'company_logo',
      'actions',
      'created_by_user_name',
      'creator_id',
      'client_id',
      'triggered_at',
    ],
    inProgress: invoiceFindAllProcess ? true : false,
    filterData: filterData || {},
    filterFormSubmit: filterFormSubmit,
    changeEvent: changeEvent,
  };

  return (
    <>
      <Invoices {...tableData} />
    </>
  );
};
