export const Types = {
  START_USERS_FIND_ALL: 'START_USERS_FIND_ALL',
  END_USERS_FIND_ALL: 'END_USERS_FIND_ALL',

  START_CREATE_USER: 'START_CREATE_USER',
  END_CREATE_USER: 'END_CREATE_USER',

  START_FIND_USER: 'START_FIND_USER',
  END_FIND_USER: 'END_FIND_USER',

  START_UPDATE_USER: 'START_UPDATE_USER',
  END_UPDATE_USER: 'END_UPDATE_USER',

  START_DELETE_USER: 'START_DELETE_USER',
  END_DELETE_USER: 'END_DELETE_USER'
};
