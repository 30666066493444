import { Types } from './types';

const initState = {
  partnersFindAllProcess: false,
  partnersFindAllError: null,
  partnersFindAllData: null,

  createPartnersProcess: false,
  createPartnersError: null,
  createPartnersData: null,

  updatePartnersProcess: false,
  updatePartnersError: null,
  updatePartnersData: null,

  partnersFindProcess: false,
  partnersFindError: null,
  partnersFindData: null,

  partnersDeleteProcess: false,
  partnersDeleteError: null,
  partnersDeleteData: null,

  partnersProfileFindProcess: false,
  partnersProfileFindError: null,
  partnersProfileFindData: null,

  updatePartnersProfileProcess: false,
  updatePartnersProfileError: null,
  updatePartnersProfileData: null,
};

export const partners = (state = initState, action) => {
  switch (action.type) {
    // Find all partners
    case Types.START_PARTNERS_FIND_ALL:
      return {
        ...state,
        partnersFindAllProcess: true,
        partnersFindAllError: null,
        partnersFindAllData: null,
        createPartnersError: null,
        createPartnersData: null,
        partnersDeleteError: null,
        partnersDeleteData: null,
      };
    case Types.END_PARTNERS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        partnersFindAllProcess: false,
        partnersFindAllError: error,
        partnersFindAllData: success,
      };
    }

    // Create partners
    case Types.START_CREATE_PARTNERS:
      return {
        ...state,
        createPartnersProcess: true,
        createPartnersError: null,
        createPartnersData: null,
      };
    case Types.END_CREATE_PARTNERS: {
      const { success, error } = action.payload;
      return {
        ...state,
        createPartnersProcess: false,
        createPartnersError: error,
        createPartnersData: success,
      };
    }

    // Update partners
    case Types.START_UPDATE_PARTNERS:
      return {
        ...state,
        updatePartnersProcess: true,
        updatePartnersError: null,
        updatePartnersData: null,
      };
    case Types.END_UPDATE_PARTNERS: {
      const { success, error } = action.payload;
      return {
        ...state,
        updatePartnersProcess: false,
        updatePartnersError: error,
        updatePartnersData: success,
      };
    }

    // Find partners
    case Types.START_PARTNERS_FIND:
      return {
        ...state,
        partnersFindProcess: true,
        partnersFindError: null,
        partnersFindData: null,
        updatePartnersProcess: false,
        updatePartnersError: null,
        updatePartnersData: null,
      };
    case Types.END_PARTNERS_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        partnersFindProcess: false,
        partnersFindError: error,
        partnersFindData: success,
      };
    }

    // Delete partners
    case Types.START_DELETE_PARTNERS:
      return {
        ...state,
        partnersDeleteProcess: true,
        partnersDeleteError: null,
        partnersDeleteData: null,
      };
    case Types.END_DELETE_PARTNERS: {
      const { success, error } = action.payload;
      return {
        ...state,
        partnersDeleteProcess: false,
        partnersDeleteError: error,
        partnersDeleteData: success,
      };
    }

    // Find partners profile find
    case Types.START_PARTNERS_PROFILE_FIND:
      return {
        ...state,
        partnersProfileFindProcess: true,
        partnersProfileFindError: null,
        partnersProfileFindData: null,
      };
    case Types.END_PARTNERS_PROFILE_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        partnersProfileFindProcess: false,
        partnersProfileFindError: error,
        partnersProfileFindData: success,
      };
    }

    // update partners profile
    case Types.START_UPDATE_PARTNERS_PROFILE:
      return {
        ...state,
        updatePartnersProfileProcess: true,
        updatePartnersProfileError: null,
        updatePartnersProfileData: null,
      };
    case Types.END_UPDATE_PARTNERS_PROFILE: {
      const { success, error } = action.payload;
      return {
        ...state,
        updatePartnersProfileProcess: false,
        updatePartnersProfileError: error,
        updatePartnersProfileData: success,
      };
    }

    default:
      return state;
  }
};
