import React, { useEffect } from 'react';
import { ViewFormCustom } from '.';
import { TabCustom } from '.';
import { FormInputsData, FileUpload } from '../../data/CreateInvoiceData';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, InvoiceTabs } from '../../data/AccountsData';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceFind } from '../../redux/invoices/action';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Grid, Box } from '@material-ui/core';

export const ViewInvoice = ({ isEdit, isView, match, module }) => {
  const location = useLocation();
  const { push, history } = useHistory();
  const dispatch = useDispatch();

  const { invoiceFindProcess, invoiceFindError, invoiceFindData } = useSelector(({ invoices }) => ({
    ...invoices,
  }));

  // find invoice
  useEffect(() => {
    dispatch(invoiceFind(match?.params?.id));
  }, [match?.params?.id]);

  let tabsData = [];

  if (location?.pathname.indexOf('company') !== -1) {
    module = 'company';
    tabsData = Tabs;
  } else if (location?.pathname.indexOf('accounts') !== -1) {
    tabsData = InvoiceTabs;
    module = 'accounts';
  }

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  //   button click
  const clickOnBtn = (value) => {
    if (value?.button?.key === 'edit') {
      if (module === 'accounts') {
        push(`/accounts/invoices/edit/${match?.params?.id}`);
      } else if (module === 'company') {
        push(`/company/invoices/edit/${match?.params?.id}`);
      }
    } else if (value?.button?.key === 'close') {
      if (module === 'accounts') {
        push(`/accounts/invoices`);
      } else if (module === 'company') {
        push(`/company/invoices`);
      }
    }
  };

  //   set form data
  const formData = {
    list: FormInputsData,
    inProgress: invoiceFindProcess,
    error:
      (invoiceFindData?.data === null && invoiceFindData?.message) || invoiceFindError?.message,
    isEdit,
    isView,
    value: invoiceFindData?.data,
    tabs: tabs,
  };

  // Upload invoice component
  const UploadInvoice = () => {
    return (
      <>
        <Grid container spacing={1}>
          <Grid
            item
            xs={FileUpload?.xs || 12}
            sm={FileUpload?.sm || 12}
            md={FileUpload?.md || 6}
            lg={FileUpload?.lg || 4}
            xl={FileUpload?.xl || 4}
          >
            <Box className={FileUpload?.formElementClass}>
              <label className="interview-label">{FileUpload.label}</label>
              {formData.value?.invoice_attachment && (
                <div className="file-section">
                  <a
                    href={formData.value?.invoice_attachment}
                    target="_blank"
                    className={FileUpload.uploadTextClass}
                  >
                    <FileCopyIcon />
                    {formData.value?.invoice_attachment_name}
                  </a>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  // End upload invoice

  return (
    <>
      <TabCustom {...tabs} />
      <ViewFormCustom {...formData} clickOnBtn={clickOnBtn} renderer={{ UploadInvoice }} />
    </>
  );
};
