import {
  Add,
  Remove,
  VerifiedUser,
  Description,
  SupervisedUserCircle,
  PostAdd,
  AllInbox,
  Work,
  Business,
  Contacts,
  PermContactCalendar,
  AccountBalance,
  AccountCircle,
} from '@material-ui/icons';
import {
  HolidayVillage,
  DateRange,
  CreditScore,
  Payment,
  AssignmentReturned,
  Handshake,
} from '@mui/icons-material';
import { FcLeave } from 'react-icons/fa';

export const Data = [
  {
    menu: 'Job Portal',
    icon: Work,
    url: '/positions',
    key: 'jobPortal',
    hiddenKeys: ['positions', 'applicants'],
    submenu: [
      {
        title: 'Positions',
        icon: PostAdd,
        url: '/positions',
        group: 'positions',
        key: 'positions',
        hide: 'true',
      },
      {
        title: 'Applicants',
        icon: AllInbox,
        url: '/applicants',
        group: 'applicants',
        key: 'applicants',
        hide: 'true',
      },
    ],
  },
  {
    menu: 'Admin',
    icon: VerifiedUser,
    key: 'admin',
    hiddenKeys: ['clients', 'users'],
    submenu: [
      {
        title: 'User Management',
        icon: SupervisedUserCircle,
        url: '/users',
        group: 'users',
        key: 'users',
        hide: 'true',
      },
    ],
  },
  {
    menu: 'Company',
    icon: Business,
    key: 'company',
    hiddenKeys: ['profile', 'employees', 'accounts'],
    submenu: [
      {
        title: 'Profile',
        icon: PermContactCalendar,
        url: '/company/profile',
        group: 'profile',
        key: 'profile',
        hide: 'true',
      },
      {
        title: 'Employees',
        icon: SupervisedUserCircle,
        url: '/company/employees',
        group: 'employees',
        key: 'employees',
        hide: 'true',
      },
      {
        title: 'Accounts',
        icon: CreditScore,
        url: '/company/invoices',
        group: 'invoices',
        key: 'invoices',
        hide: 'true',
      },
      {
        title: 'Holidays',
        icon: DateRange,
        url: '/company/holidays',
        group: 'holidays',
        key: 'holidays',
        hide: 'true',
      },
      // {
      //   title: 'Leaves',
      //   icon: HolidayVillage,
      //   url: '/company/leaves',
      //   group: 'leaves',
      //   key: 'leaves',
      //   hide: 'true',
      // },
    ],
  },
  {
    menu: 'HRMS',
    icon: PermContactCalendar,
    key: 'hrms',
    hiddenKeys: ['clients', 'employees'],
    submenu: [
      {
        title: 'Clients',
        icon: Contacts,
        url: '/hrms/clients',
        group: 'clients',
        key: 'clients',
        hide: 'true',
      },
      {
        title: 'Employees',
        icon: SupervisedUserCircle,
        url: '/hrms/employees',
        group: 'employee',
        key: 'employees',
        hide: 'true',
      },
      {
        title: 'Holidays',
        icon: DateRange,
        url: '/hrms/holidays',
        group: 'holidays',
        key: 'holidays',
        hide: 'true',
      },
      // {
      //   title: 'Leaves',
      //   icon: HolidayVillage,
      //   url: '/hrms/leaves',
      //   group: 'leaves',
      //   key: 'leaves',
      //   hide: 'true',
      // },
      {
        title: 'Payrolls',
        icon: Payment,
        url: '/hrms/payrolls',
        group: 'payrolls',
        key: 'payrolls',
        hide: 'true',
      },
    ],
  },
  {
    menu: 'Accounts',
    icon: CreditScore,
    key: 'accounts',
    hiddenKeys: ['clients', 'employees'],
    submenu: [
      {
        title: 'Invoices',
        icon: Description,
        url: '/accounts/invoices',
        group: 'invoices',
        key: 'invoices',
        hide: 'true',
      },
      // {
      //   title: 'Expenses',
      //   icon: SupervisedUserCircle,
      //   url: '/accounts/expenses',
      //   group: 'expenses',
      //   key: 'expenses',
      //   hide: 'true',
      // },
      // {
      //   title: 'Salary',
      //   icon: AccountBalance,
      //   url: '/accounts/salary',
      //   group: 'salary',
      //   key: 'salary',
      //   hide: 'true',
      // },
    ],
  },
  {
    menu: 'Partner Details',
    icon: Handshake,
    key: 'partners-details',
    hiddenKeys: ['profile'],
    submenu: [
      {
        title: 'Profile',
        icon: AccountCircle,
        url: '/partners/profile',
        group: 'profile',
        key: 'profile',
        hide: 'true',
      },
      {
        title: 'Companies Refered',
        icon: Description,
        url: '',
        group: 'refered',
        key: 'refered',
        hide: 'true',
      },
    ],
  },
  // {
  //   menu: 'Your Details',
  //   icon: AccountCircle,
  //   url: '/partners/profile',
  //   group: 'partners',
  //   key: 'partners-detail',
  //   hide: 'true',
  // },
  {
    menu: 'Partners',
    icon: Handshake,
    key: 'partner',
    // hiddenKeys: ['profile'],
    submenu: [
      {
        title: 'Partner',
        icon: Description,
        url: '/partners',
        group: 'partner',
        key: 'partner',
        hide: 'true',
      },
      // {
      //   title: 'Your Details',
      //   icon: AccountCircle,
      //   url: '/partners/profile',
      //   group: 'partners',
      //   key: 'partners',
      //   hide: 'true',
      // },
      // {
      //   title: 'Companies Refered',
      //   icon: Description,
      //   url: '',
      //   group: 'partners',
      //   key: 'partners',
      //   hide: 'true',
      // },
    ],
  },
  {
    menu: 'Your Details',
    icon: AccountCircle,
    url: '/employee/profile',
    group: 'expenses',
    key: 'employee-detail',
    hide: 'true',
  },

  // {
  //   menu: 'Leaves',
  //   icon: HolidayVillage,
  //   url: '/employee/leaves',
  //   group: 'leaves',
  //   key: 'employee-leaves',
  //   hide: 'true',
  // },
  {
    menu: 'Holidays',
    icon: DateRange,
    url: '/employee/holidays',
    group: 'holidays',
    key: 'employee-holidays',
    hide: 'true',
  },
  {
    menu: 'Payrolls',
    icon: Payment,
    url: '/employee/payrolls',
    group: 'payrolls',
    key: 'employee-payrolls',
    hide: 'true',
  },
  {
    menu: 'Reimbursements',
    icon: AssignmentReturned,
    url: '/employee/reimbursements',
    group: 'reimbursements',
    key: 'employee-reimbursements',
    hide: 'true',
  },
  // {
  //   menu: 'Expenses',
  //   icon: SupervisedUserCircle,
  //   url: '/employee/expenses',
  //   group: 'expenses',
  //   key: 'employee-expenses',
  //   hide: 'true',
  // },
  // {
  //   menu: 'Salary',
  //   icon: AccountBalance,
  //   url: '/employee/salary',
  //   group: 'salary',
  //   key: 'employee-salary',
  //   hide: 'true',
  // },
];

export const AccordionIcon = {
  plusIcon: Add,
  minusIcon: Remove,
};
