import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { HrmsHolidays } from '.';

export const HolidaysRouter = () => {
    return (
        <>
          <Switch>
            <Route exact path={'/hrms/holidays'} component={HrmsHolidays} />
            {/* <Route
              path={'/accounts/invoices/create'}
              render={(props) => <AddOrEditInvoice {...props} />}
            />
            <Route
              path={'/accounts/invoices/view/:id'}
              render={(props) => <ViewInvoice isView={true} {...props} />}
            />
            <Route
              path={'/accounts/invoices/edit/:id'}
              render={(props) => <AddOrEditInvoice isEdit={true} {...props} />}
            /> */}
          </Switch>
        </>
    );
}