import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileData, PasswordData } from '../../data/ProfileData';
import { FormCustom } from '../common';
import { userProfileFind, updateUserProfile, updateUserPassword } from '../../redux/profile/action';
import { CircularProgress } from '@material-ui/core';
import { openSnackBarLayout } from '../../redux/common/action';
import axios from 'axios';
import { apiList } from '../../util/apiList';

export const Profile = (isEdit) => {
  const dispatch = useDispatch();

  const {
    userProfileFindProcess,
    userProfileFindError,
    userProfileFindData,
    userProfileUpdateProcess,
    userProfileUpdateError,
    userProfileUpdateData,
    userPasswordUpdateProcess,
    userPasswordUpdateError,
    userPasswordUpdateData,
    userInfo,
  } = useSelector(({ userProfile, sign }) => ({
    ...userProfile,
    ...sign,
  }));

  const [formData, setFormData] = useState({
    list: ProfileData,
    isEdit,
    values: {},
    error: '',
    inProgress: isEdit ? userProfileFindProcess : false,
    buttonSection: {
      isRequired: false,
    },
  });

  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isPasswordFormSubmit, setIsPasswordFormSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);

  useEffect(() => {
    dispatch(userProfileFind());
  }, []);

  useEffect(() => {
   if (userInfo?.data?.type_slug === 'employee') {
     setReadOnlyKeys(() => ['designation']);
   }
  }, [userInfo])

  // Reset user data in state after update
  useEffect(() => {
    if (!userProfileUpdateProcess && !userProfileUpdateError && userProfileUpdateData?.data) {
      setFormData((data) => ({ ...data, values: userProfileUpdateData?.data, inProgress: false }));
    } else if (
      !userProfileUpdateProcess &&
      userProfileUpdateError &&
      !userProfileUpdateData?.data
    ) {
      const error = userProfileUpdateData?.message || userProfileUpdateError?.message;
      setFormData((data) => ({ ...data, error, inProgress: false }));
    }
  }, [userProfileUpdateProcess, userProfileUpdateError, userProfileUpdateData]);

  // User find
  useEffect(() => {
    if (userProfileFindData && userProfileFindData?.data) {
      setFormData((data) => ({ ...data, values: userProfileFindData?.data, inProgress: false }));
    } else if (!userProfileFindProcess && userProfileFindError && !userProfileFindData?.data) {
      const error = userProfileFindData?.message || userProfileFindError?.message;
      setFormData((data) => ({ ...data, error, inProgress: false }));
    }
  }, [userProfileFindProcess, userProfileFindError, userProfileFindData]);

  // form data
  const error =
    (userProfileFindData?.data === null && userProfileFindData?.message) ||
    userProfileFindError?.message;

  // password data
  const PasswordFormData = {
    list: PasswordData,
    isEdit,
    values: {},
    error: error,
    inProgress: isEdit ? userProfileFindProcess : false,
    buttonSection: {
      isRequired: false,
    },
  };

  // file upload event
  const changeEvent = async (data) => {
    if (
      data.field?.name === 'profile_image' &&
      data?.value?.target?.files &&
      data.value.target.files?.length > 0
    ) {
      const file = data?.value?.target?.files[0];
      var formData = new FormData();
      formData.append('folderName', 'user');
      formData.append('file', file);
      setFormData((data) => ({ ...data, inProgress: true }));

      const response = await axios.post(apiList.fileUpload, formData, {
        headers: {},
        withCredentials: true,
      });

      const result = response?.data || {};

      if (result?.data) {
        setFormData((data) => ({
          ...data,
          values: {
            ...data.values,
            profile_image: result?.data?.uploaded_url,
            original_name: result?.data?.original_name,
          },
          inProgress: false,
        }));
      } else {
        dispatch(openSnackBarLayout('User profile image is not uploaded', 'error', 1000));
      }
    }

    if (data.field?.name === 'remove') {
      setFormData((data) => ({
        ...data,
        values: { ...data.values, profile_image: '' },
      }));
    }
  };

  // Form submit
  const formSubmit = (data) => {
    data?.current_password && delete data?.current_password;
    data?.new_password && delete data?.current_password;
    data?.confirm_password && delete data?.current_password;
    data.profile_image = formData?.values?.profile_image || '';
    setIsLoading(() => true);
    setIsFormSubmit(() => true);
    dispatch(updateUserProfile(data));
    setIsLoading(() => false);
  };

  // refresh profile data after update
  useEffect(() => {
    !userProfileUpdateProcess && userProfileUpdateData && dispatch(userProfileFind());
  }, [userProfileUpdateProcess, userProfileUpdateData]);

  // password submit
  const passwordSubmit = (data) => {
    if (data?.new_password && data?.confirm_password) {
      if (data?.new_password !== data?.confirm_password) {
        dispatch(openSnackBarLayout('Password not confirmed', 'error', 3000));
        return false;
      }
      if (data?.current_password === data?.new_password) {
        dispatch(openSnackBarLayout('Current and new password are same', 'error', 3000));
        return false;
      }
    }

    const passwordData = {
      current_password: data?.current_password,
      new_password: data?.new_password,
      confirm_password: data?.confirm_password,
    };
    setIsLoading(() => true);
    dispatch(updateUserPassword(passwordData));
    setIsPasswordFormSubmit(() => true);
    setIsLoading(() => false);
  };

  // update profile success response
  useEffect(() => {
    setIsLoading(false);
    if (isFormSubmit) {
      if (!userProfileUpdateProcess && !userProfileUpdateError && userProfileUpdateData) {
        dispatch(openSnackBarLayout(userProfileUpdateData?.message, 'success', 3000));
      } else if (userProfileUpdateError) {
        dispatch(openSnackBarLayout(userProfileUpdateProcess?.message, 'error', 3000));
      }
    }
  }, [userProfileUpdateProcess, userProfileUpdateError, userProfileUpdateData]);

  // update password success response
  useEffect(() => {
    if (isPasswordFormSubmit) {
      setIsLoading(false);
      if (!userPasswordUpdateProcess && !userPasswordUpdateError && userPasswordUpdateData) {
        dispatch(openSnackBarLayout(userPasswordUpdateData?.message, 'success', 3000));
        setTimeout(() => dispatch(userProfileFind()), 3000);
      } else if (userPasswordUpdateError) {
        dispatch(openSnackBarLayout(userPasswordUpdateError?.message, 'error', 3000));
      }
    }
  }, [userPasswordUpdateProcess, userPasswordUpdateError, userPasswordUpdateData]);

  return (
    <>
      {/* Update profile */}
      {!userProfileFindProcess && userProfileFindData && (
        <FormCustom
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          readOnlyKeys={readOnlyKeys}
          isLoading={isLoading}
        />
      )}

      {/* Update password */}
      {!userProfileFindProcess && userProfileFindData && (
        <FormCustom
          {...PasswordFormData}
          formSubmit={passwordSubmit}
          formClass={PasswordFormData?.list[0]?.formClass}
          isLoading={isLoading}
        />
      )}

      {userProfileFindProcess ||
        userProfileUpdateProcess ||
        (userPasswordUpdateProcess && (
          <div className="loader">
            <CircularProgress />
          </div>
        ))}
    </>
  );
};
export default Profile;
