import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    company_logo: 'FK',
    company_name: 'Flipkoins',
    primary_email: 'flipkoins@test.com',
    phone: '+91 9999999999',
    country: 'India',
  },
  {
    id: 2,
    company_logo: 'HG',
    company_name: 'Heptagon',
    primary_email: 'heptagon@test.com',
    phone: '+91 9999999999',
    country: 'India',
  },
  {
    id: 3,
    company_logo: 'FK',
    company_name: 'Flipkoins',
    primary_email: 'flipkoins@test.com',
    phone: '+91 9999999999',
    country: 'India',
  },
  {
    id: 4,
    company_logo: 'HG',
    company_name: 'Heptagon',
    primary_email: 'heptagon@test.com',
    phone: '+91 9999999999',
    country: 'India',
  },
];

// Applicants table header
export const ClientHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Company',
    key: 'company_logo',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo',
  },
  { label: 'Company Name', key: 'company_name', hide: true, headClass: 'w-200', type: 'text' },
  { label: 'Primary Email', key: 'primary_email', hide: false, type: 'text', headClass: 'w-200' },
  {
    label: 'Phone no.',
    key: 'phone',
    hide: false,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
  // { label: 'City', key: 'city', hide: false, type: 'text', headClass: 'w-150' },
  // { label: 'State', key: 'state', hide: false, type: 'text', headClass: 'w-150' },
  {
    label: 'Action',
    key: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: EditIcon,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
      {
        bodyClass: 'icon-danger',
        type: 'delete',
        icon: DeleteIcon,
        text: 'Delete',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
];

// page header, title, breadcrumb and button
// export const PageHeader = {
//   className: 'page-header',
//   page_title: 'Clients',
//   button: {
//     text: 'Create Client',
//     className: 'link-button btn-sm btn-primary',
//     url: '/clients/create',
//     hiddenKey: 'create-client',
//   },
//   breadcrumb: [
//     { text: 'Dashboard', link: '/dashboard', className: '' },
//     { text: 'Clients', link: '', className: 'active' },
//   ],
// };

export const PageHeader = {
  className: 'page-header',
  page_title: 'Clients',
  button: {
    text: 'Create Client',
    className: 'link-button btn-sm btn-primary',
    url: '/hrms/clients/create',
    hiddenKey: 'create-client',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Clients', link: '', className: 'active' },
  ],
};

// Tabs data
// export const Tabs = {
//   tabClass: 'dashboard-tab',
//   list: [
//     {
//       label: 'Create Client',
//       key: 'create',
//       link: '/clients/create',
//       className: 'tab-link',
//       hiddenKey: 'create-client',
//     },
//     {
//       label: 'Edit Client',
//       key: 'edit',
//       link: '/clients/edit/:id',
//       path: '/clients/edit/:id',
//       className: 'tab-link',
//       hiddenKey: 'edit-client',
//     },
//     {
//       label: 'View Client',
//       key: 'view',
//       link: '/clients/view/:id',
//       path: '/clients/view/:id',
//       className: 'tab-link',
//       hiddenKey: 'list-clients',
//     },
//     {
//       label: 'Clients',
//       key: 'clients',
//       link: '/clients',
//       className: 'tab-link',
//       hiddenKey: 'list-clients',
//     },
//   ],
// };

export const Tabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Client',
      key: 'create',
      link: '/hrms/clients/create',
      className: 'tab-link',
      hiddenKey: 'create-client',
    },
    {
      label: 'Edit Client',
      key: 'edit',
      link: '/hrms/clients/edit/:id',
      path: '/hrms/clients/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-client',
    },
    {
      label: 'View Client',
      key: 'view',
      link: '/hrms/clients/view/:id',
      path: '/hrms/clients/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-clients',
    },
    {
      label: 'Clients',
      key: 'clients',
      link: '/hrms/clients',
      className: 'tab-link',
      hiddenKey: 'list-clients',
    },
  ],
};
