import React, { useEffect, useState } from 'react';
import { TableCustom, TabCustom } from '../../common';
import { Box, CircularProgress } from '@material-ui/core';
import { ClientHead, Tabs } from '../../../data/ClientsData';
import '../../positions/Positions.scss';
import { useHistory } from 'react-router-dom';
import { commonFn } from '../../../util/commonFn';
import { clientsFindAll } from '../../../redux/clients/action';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteClient } from '../../../redux/clients/action';

// Start: Main Function
export const Clients = ({ match }) => {
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const {
    clientsFindAllError,
    clientsFindAllData,
    clientsFindAllProcess,
    clientDeleteProcess,
    clientDeleteError,
    clientDeleteData,
    userInfo,
  } = useSelector(({ clients, sign }) => ({
    ...clients,
    ...sign,
  }));

  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const limit = 10;
  const [hiddenAction, setHiddenAction] = useState([]);
  const [hiddenTableCol, setHiddenTableCol] = useState([]);

  // Set page title
  useEffect(() => {
    document.title = 'Quark - Clients';
  }, []);

  useEffect(() => {
    dispatch(clientsFindAll(skip, limit));
  }, [skip]);

  // Actions click function
  const clickOnCell = (data, event) => {
    event.stopPropagation();
    const clientId = data.row[0].value;
    if (clientId && data?.action?.type === 'edit') {
      if (commonFn.menuHiddenKeys.indexOf('edit-client') !== -1) {
        commonFn.showPopup(commonFn.editAccessError);
        return false;
      }
      history.push(`/hrms/clients/edit/${clientId}`);
    }

    // view client
    if ((clientId && data.action?.key) || (clientId && data.action?.type === 'view')) {
      history.push(`/hrms/clients/view/${clientId}`);
    }

    // delete client
    if (clientId && data.action.type === 'delete') {
      if (commonFn.menuHiddenKeys.indexOf('delete-client') !== -1) {
        commonFn.showPopup(commonFn.deleteAccessError);
        return false;
      }

      if (userInfo?.data?.company_id === clientId) {
        MySwal.fire({
          title: 'Warning!!',
          text: `You can't delete your company`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        });
        return false;
      }

      MySwal.fire({
        title: 'Are you sure?',
        text: 'You want to delete the client',
        icon: 'warning',
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        result = result.isConfirmed && dispatch(deleteClient(clientId));
      });
    }
  };

  // refresh clients data after deleting
  useEffect(() => {
    if (!clientDeleteProcess) {
      dispatch(clientsFindAll(skip, limit));
      if (clientDeleteData) {
        Swal.fire({
          title: 'Success!',
          text: 'Client has been deleted',
          icon: 'success',
          iconColor: '#3085d6',
        });
      } else if (clientDeleteError) {
        Swal.fire({
          title: 'Error!',
          text: `${clientDeleteError?.message}`,
          icon: 'error',
          iconColor: '#B41E26',
        });
      }
    }
  }, [clientDeleteData, clientDeleteError, clientDeleteProcess]);

  // pagination click - change page
  const pageChange = (e, value) => {
    setSkip(limit * (value - 1));
  };

  // hide action based on the permission
  useEffect(() => {
    if (!clientsFindAllProcess && !clientsFindAllError && clientsFindAllData) {
      if (commonFn.menuHiddenKeys.indexOf('edit-client') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Edit']);
      }
      if (commonFn.menuHiddenKeys.indexOf('delete-client') !== -1) {
        setHiddenAction((hiddenAction) => [...hiddenAction, 'Delete']);
      }
    }
  }, [clientsFindAllProcess, clientsFindAllError, clientsFindAllData]);

  // hide the action column if edit and delete action is not
  useEffect(() => {
    if (
      hiddenAction &&
      hiddenAction.indexOf('Edit') !== -1 &&
      hiddenAction.indexOf('Delete') !== -1
    ) {
      setHiddenTableCol((hiddenTableCol) => [...hiddenTableCol, 'action']);
    }
  }, [hiddenAction]);

  // Mapping API response
  const tableData = {
    hiddenAction: hiddenAction,
    count: clientsFindAllData?.data?.count,
    skip: skip,
    data: clientsFindAllData?.data?.list?.map((client) => ({
      ...client,
      company_name: `${client.company_name} ${
        client.id === userInfo?.data?.company_id ? '(Your company)' : ''
      }`,
    })),
    head: ClientHead,
    pagination: {
      numberOfPages: clientsFindAllData?.data?.numberOfPages,
      handleChange: pageChange,
      currentPage: skip / limit + 1,
    },
    clickOnCell: clickOnCell,
    errorMessage: clientsFindAllError?.message,
  };

  const tabs = {
    ...Tabs,
    list: commonFn.updateLink(Tabs.list, match),
  };

  return (
    <div>
      {/* Table component */}
      <TabCustom {...tabs} />

      {clientsFindAllProcess && (
        <div className="align-center mt-36">
          <CircularProgress />
        </div>
      )}

      {!clientsFindAllProcess && (
        <>
          <Box mb={4}>
            <TableCustom {...tableData} hiddenKeys={hiddenTableCol} />
          </Box>
        </>
      )}
    </div>
  );
};
