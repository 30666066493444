import React from "react";
import styled from 'styled-components';
import { colors } from '../../theme';
import { Link } from 'react-router-dom';

const LinkButton = styled(Link)`
    background-color: ${colors.primary.black90} !important;
    border-color: ${colors.primary.black90} !important;
    color: ${colors.white} !important;
    text-transform: capitalize !important;
    padding: 10px 12px !important;
    border-radius: .2rem !important;
    text-decoration: none !important;
    border-radius: 4px !important;

    &:hover {
        opacity: 0.9;
        box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%) !important;
    }
`;

export const LinkPrimaryButton = (props) => {
    return <LinkButton to={props.href} hidden={props?.hide} className={props.className}> {props.text} </LinkButton>
}

export default LinkPrimaryButton;