import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { EmployeeReimbursement } from '.';
import { AddOrEditReimbursement } from '../../common';

export const ReimbursementRouter = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/employee/reimbursements'} component={EmployeeReimbursement} />
        <Route path={'/employee/reimbursements/create'}
          render={(props) => <AddOrEditReimbursement {...props} />}
        />
      </Switch>
    </>
  );
};
