import { Types } from './types';

const initState = {
  createReimbursementProcess: false,
  createReimbursementError: null,
  createReimbursementData: null,

  reimbursementFindAllProcess: false,
  reimbursementFindAllError: null,
  reimbursementFindAllData: null,
};

export const reimbursements = (state = initState, action) => {
  switch (action.type) {
    // create reiumbursement
    case Types.START_CREATE_REIMBURSEMENT:
      return {
        ...state,
        createReimbursementProcess: true,
        createReimbursementError: null,
        createReimbursementData: null,
      };
    case Types.END_CREATE_REIMBURSEMENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        createReimbursementProcess: false,
        createReimbursementError: error,
        createReimbursementData: success,
      };
    }

    case Types.START_FIND_ALL_REIMBURSEMENT:
      return {
        ...state,
        reimbursementFindAllProcess: true,
        reimbursementFindAllError: null,
        reimbursementFindAllData: null,
      };
    case Types.END_FIND_ALL_REIMBURSEMENT: {
      const { success, error } = action.payload;
      return {
        ...state,
        reimbursementFindAllProcess: false,
        reimbursementFindAllError: error,
        reimbursementFindAllData: success,
      };
    }

    default:
      return state;
  }
};
