export const Types = {
  START_CLIENTS_FIND_ALL: 'START_CLIENTS_FIND_ALL',
  END_CLIENTS_FIND_ALL: 'END_USERS_FIND_ALL',

  START_CREATE_CLIENT: 'START_CREATE_CLIENT',
  END_CREATE_CLIENT: 'END_CREATE_CLIENT',

  START_CLIENT_FIND: 'START_CLIENT_FIND',
  END_CLIENT_FIND: 'END_CLIENT_FIND',

  START_UPDATE_CLIENT: 'START_UPDATE_CLIENT',
  END_UPDATE_CLIENT: 'END_UPDATE_CLIENT',

  START_DELETE_CLIENT: 'START_DELETE_CLIENT',
  END_DELETE_CLIENT: 'END_DELETE_CLIENT',

  START_CLIENT_PROFILE_FIND: 'START_CLIENT_PROFILE_FIND',
  END_CLIENT_PROFILE_FIND: 'END_CLIENT_PROFILE_FIND',

  START_UPDATE_CLIENT_PROFILE: 'START_UPDATE_CLIENT_PROFILE',
  END_UPDATE_CLIENT_PROFILE: 'END_UPDATE_CLIENT_PROFILE',
};
