import { Types } from './types';

const initState = {
  applicantsFindAllProcess: false,
  applicantsFindAllError: null,
  applicantsFindAllData: null,

  createApplicantProcess: false,
  createApplicantError: null,
  createApplicantData: null,

  applicantFindProcess: false,
  applicantFindError: null,
  applicantFindData: null,

  applicantSourceFindAllProcess: false,
  applicantSourceFindAllError: null,
  applicantSourceFindAllData: null,

  applicantDeleteProcess: false,
  applicantDeleteError: null,
  applicantDeleteData: null,

  updateApplicantProcess: false,
  updateApplicantError: null,
  updateApplicantData: null,

  sourceCreateProcess: false,
  sourceCreateError: null,
  sourceCreateData: null,
};

export const applicants = (state = initState, action) => {
  switch (action.type) {
    case Types.START_APPLICANTS_FIND_ALL:
      return {
        ...state,
        applicantsFindAllProcess: true,
        applicantsFindAllError: null,
        applicantsFindAllData: null,

        createApplicantError: null,
        createApplicantData: null,

        applicantFindError: null,
        applicantFindData: null,
      };
    case Types.END_APPLICANTS_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        applicantsFindAllProcess: false,
        applicantsFindAllError: error,
        applicantsFindAllData: success,
      };
    }
    case Types.START_CREATE_APPLICANT:
      return {
        ...state,
        createApplicantProcess: true,
        createApplicantError: null,
        createApplicantData: null,
      };
    case Types.END_CREATE_APPLICANT: {
      const { success, error } = action.payload;
      return {
        ...state,
        createApplicantProcess: false,
        createApplicantError: error,
        createApplicantData: success,
      };
    }
    case Types.START_APPLICANT_FIND:
      return {
        ...state,
        applicantFindProcess: true,
        applicantFindError: null,
        applicantFindData: null,
      };
    case Types.END_APPLICANT_FIND: {
      const { success, error } = action.payload;
      return {
        ...state,
        applicantFindProcess: false,
        applicantFindError: error,
        applicantFindData: success,
      };
    }
    case Types.START_APPLICANT_SOURCE_FIND_ALL:
      return {
        ...state,
        applicantSourceFindAllProcess: true,
        applicantSourceFindAllError: null,
        applicantSourceFindAllData: null,
      };
    case Types.END_APPLICANT_SOURCE_FIND_ALL: {
      const { success, error } = action.payload;
      return {
        ...state,
        applicantSourceFindAllProcess: false,
        applicantSourceFindAllError: error,
        applicantSourceFindAllData: success,
      };
    }

    // Delete applicant
    case Types.START_APPLICANT_DELETE:
      return {
        ...state,
        applicantDeleteProcess: true,
        applicantDeleteError: null,
        applicantDeleteData: null,
      };
    case Types.END_APPLICANT_DELETE: {
      const { success, error } = action.payload;
      return {
        ...state,
        applicantDeleteProcess: false,
        applicantDeleteError: null,
        applicantDeleteData: success,
      };
    }

    // Update applicant
    case Types.START_APPLICANT_UPDATE:
      return {
        ...state,
        updateApplicantProcess: true,
        updateApplicantError: null,
        updateApplicantData: null,
      };
    case Types.END_APPLICANT_UPDATE: {
      const { success, error } = action.payload;
      return {
        ...state,
        updateApplicantProcess: false,
        updateApplicantError: null,
        updateApplicantData: success,
      };
    }

    // create source
    case Types.START_SOURCE_CREATE:
      return {
        ...state,
        sourceCreateProcess: true,
        sourceCreateError: null,
        sourceCreateData: null,
      };
    case Types.END_SOURCE_CREATE: {
      const { success, error } = action.payload;
      return {
        ...state,
        sourceCreateProcess: false,
        sourceCreateError: null,
        sourceCreateData: success,
      };
    }

    default:
      return state;
  }
};
