import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { CompanyProfileMain } from './profile';
import { EmployeesMain } from './employees';
import { AccountsMain } from './accounts';
import { HolidaysMain } from './holidays';
import { LeavesMain } from './leaves';
import { NotFound } from '../common';

export const CompanyLanding = () => {
  return (
    <>
      <Switch>
        <Route path={`/company/profile`} component={CompanyProfileMain} />
        <Route path={`/company/employees`} component={EmployeesMain} />
        <Route path={`/company/invoices`} component={AccountsMain} />
        <Route path={`/company/holidays`} component={HolidaysMain} />
        <Route path={`/company/leaves`} component={LeavesMain} />
        <Route path={`/company/attendance`} component={LeavesMain} />
        <Route path={`**`} component={NotFound} />
      </Switch>
    </>
  );
};
