import React, { useState, useEffect } from 'react';
import { FormCustom, TabCustom, LeaveForm, DialogBox } from '.';
import { FormInputsData } from '../../data/EditLeaveData';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import {
  findLeaveTypes,
  findLeaveApprovers,
  createLeave,
  findLeave,
  updateLeave,
  leaveStatusFindAll,
} from '../../redux/leaves/action';
import { openSnackBarLayout } from '../../redux/common/action';
import moment from 'moment';
import { Tabs, HrmsLeavesTabs, EmployeeTabs } from '../../data/LeavesData';
import { useLocation, useHistory } from 'react-router-dom';
import { employeeRemainingLeave } from '../../redux/hrms/action';

export const AddOrEditLeave = ({ isEdit, isView, match, options = {}, values = {} }) => {
  const [formData, setFormData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [hiddenKeys, setHiddenKeys] = useState([]);
  const [readOnlyKeys, setReadOnlyKeys] = useState([]);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [hiddenButton, setHiddenButton] = useState([]);
  const [availableLeaveCount, setAvailableLeaveCount] = useState(0);
  const [leaveAvailableMessage, setLeaveAvailableMessage] = useState('');
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  // tabs
  let tabsData = [];
  const leaveGroupKey = 'leaves';

  if (location?.pathname.indexOf('company') !== -1) {
    module = 'company';
    tabsData = Tabs;
    document.title = 'Quark - Company | Employee Leaves';
  } else if (location?.pathname.indexOf('hrms') !== -1) {
    tabsData = HrmsLeavesTabs;
    module = 'hrms';
    document.title = 'Quark - HRMS | Employee Leaves';
  } else if (location?.pathname.indexOf('employee') !== -1) {
    tabsData = EmployeeTabs;
    module = 'employee';
    document.title = 'Quark - Employee | Leaves';
  }

  const buttonSection = {
    isRequired: true,
    list: [
      {
        viewText: 'Edit',
        viewKey: 'Edit',
        editText: 'Update',
        editKey: 'update',
        createText: 'Apply',
        createKey: 'apply',
      },
    ],
  };

  const {
    leaveTypesFindAllProcess,
    leaveTypesFindAllError,
    leaveTypesFindAllData,
    leaveApproversFindAllProcess,
    leaveApproversFindAllError,
    leaveApproversFindAllData,
    leaveCreateProcess,
    leaveCreateError,
    leaveCreateData,
    leaveFindProcess,
    leaveFindError,
    leaveFindData,
    leaveUpdateProcess,
    leaveUpdateError,
    leaveUpdateData,
    leaveStatusFindAllProcess,
    leaveStatusFindAllError,
    leaveStatusFindAllData,
    employeeRemainingLeaveProcess,
    employeeRemainingLeaveError,
    employeeRemainingLeaveData,
  } = useSelector(({ leaves, employees }) => ({
    ...leaves,
    ...employees,
  }));

  // set hidden key in form inputs
  useEffect(() => {
    if (FormInputsData) {
      if (module === 'employee') {
        setHiddenKeys(() => ['employee_detail', 'status_slug', 'comment']);
        setReadOnlyKeys(() => ['total_days']);
      } else if (module === 'hrms' || module === 'company') {
        setHiddenKeys(() => []);
        setReadOnlyKeys(() => [
          'employee_id',
          'first_name',
          'last_name',
          'flipkoins_email',
          'company_name',
          'total_days',
          'leave_type_slug',
          'from_date',
          'to_date',
          'total_days',
          'approver_email',
          'leave_reason',
        ]);
      }
    }
  }, [FormInputsData]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    }));
    setIsRouteChange(true);
    match?.params?.id && dispatch(findLeave(match?.params?.id));

    dispatch(findLeaveTypes());
    dispatch(findLeaveApprovers());
    dispatch(leaveStatusFindAll(leaveGroupKey));

    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
  }, [match?.params?.id]);

  // leave data
  useEffect(() => {
    if (isEdit) {
      if (!leaveFindProcess && !leaveFindError && leaveFindData?.data) {
        const comment =
          leaveFindData?.data?.comment &&
          JSON.parse(leaveFindData?.data?.comment).map((comment) => {
            return `Rejected By: ${comment?.rejectedBy}, Reason: ${comment?.reason}`;
          });

        leaveFindData.data.comment = comment;
        setFormData((data) => ({
          ...data,
          values: {
            ...leaveFindData?.data,
          },
          options: {
            ...data.options,
          },
          inProgress: false,
        }));
      } else if (!leaveFindProcess && leaveFindError && !leaveFindData?.data) {
        const error = leaveFindData?.message || leaveFindError?.message;
        setFormData((data) => ({ ...data, error, inProgress: false }));
      }
    }
  }, [leaveFindProcess, leaveFindError, leaveFindData]);

  // leave types
  useEffect(() => {
    if (!leaveTypesFindAllProcess && !leaveTypesFindAllError && leaveTypesFindAllData?.data) {
      const leaveTypes =
        (leaveTypesFindAllData &&
          leaveTypesFindAllData?.data?.list?.map((type) => {
            return { label: type?.type, value: type?.slug };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && leaveFindData?.data
            ? {
                ...data.values,
                leave_type_slug: commonFn.getMultiValueFromId(
                  leaveFindData?.data?.leave_type_slug,
                  leaveTypes,
                )[0],
              }
            : data.values,
        options: { ...data.options, leaveTypes },
      }));
    }
  }, [
    leaveStatusFindAllProcess,
    leaveStatusFindAllError,
    leaveStatusFindAllData,
    leaveFindProcess,
    leaveFindError,
    leaveFindData,
  ]);

  // Leave approvers
  useEffect(() => {
    if (
      (isEdit && !leaveFindProcess && !leaveFindError && leaveFindData?.data) ||
      (!leaveApproversFindAllProcess && !leaveApproversFindAllError && leaveApproversFindAllData)
    ) {
      let leaveApprovers = [];

      if (isEdit) {
        leaveApprovers =
          (leaveFindData?.data?.approver_email &&
            leaveFindData?.data?.approver_email?.split(',').map((approver) => {
              return { label: approver, value: approver };
            })) ||
          [];
      } else {
        leaveApprovers =
          (leaveApproversFindAllData?.data?.list &&
            leaveApproversFindAllData?.data?.list?.map((approver) => {
              return { label: approver?.reporting_manager, value: approver?.reporting_manager };
            })) ||
          [];
      }

      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && leaveFindData?.data
            ? {
                ...data.values,
                approver_email: commonFn.getMultiValueFromId(
                  leaveFindData?.data?.approver_email,
                  leaveApprovers,
                ),
              }
            : data.values,
        options: { ...data.options, leaveApprovers },
      }));
    }
  }, [
    leaveApproversFindAllProcess,
    leaveApproversFindAllError,
    leaveApproversFindAllData,
    leaveFindProcess,
    leaveFindError,
    leaveFindData,
  ]);

  // leave status
  useEffect(() => {
    if (!leaveStatusFindAllProcess && !leaveStatusFindAllError && leaveStatusFindAllData?.data) {
      const leaveStatus =
        (leaveStatusFindAllData &&
          leaveStatusFindAllData?.data?.list?.map((status) => {
            return { label: status?.status, value: status?.slug };
          })) ||
        [];
      setFormData((data) => ({
        ...data,
        values:
          match?.params?.id && leaveFindData?.data
            ? {
                ...data.values,
                status_slug: commonFn.getMultiValueFromId(
                  leaveFindData?.data?.status_slug,
                  leaveStatus,
                )[0],
              }
            : data.values,
        options: { ...data.options, leaveStatus },
      }));
    }
  }, [leaveStatusFindAllProcess, leaveStatusFindAllError, leaveStatusFindAllData, leaveFindData]);

  // change event
  const changeEvent = async (data) => {

    const fromDate =
      (data?.field?.name === 'from_date' && data?.value?.target?.value) || data?.watch?.from_date;
    const toDate =
      (data?.field?.name === 'to_date' && data?.value?.target?.value) || data?.watch?.to_date;

    const days = fromDate && toDate && commonFn.daysBetweenTwoDates(fromDate, toDate);

    if (toDate < fromDate) {
      dispatch(openSnackBarLayout('To date must not be back date', 'error', 3000));
    }

    const dayResult =
      days &&
      days.filter((day) => {
        return day !== 'Saturday' && day !== 'Sunday';
      });

    const status_slug = data?.field?.name === 'status_slug' && data?.value;
    const leaveTypeSlug = data?.field?.name === 'leave_type_slug' && data?.value?.value;

    data?.value && dispatch(employeeRemainingLeave(leaveTypeSlug));

    // set leave form data
    setFormData((form) => ({
      ...form,
      values: {
        ...form.values,
        ...data.watch,
        from_date: (fromDate && fromDate) || formData?.values?.from_date,
        to_date: (toDate && toDate) || formData?.values?.to_date,
        total_days: (dayResult && dayResult.length) || data?.watch?.total_days,
        status_slug:
          module === 'hrms' || (module === 'company' && status_slug)
            ? status_slug
            : formData?.values?.status_slug,
      },
    }));

    const formDataLeaveType =
      (leaveTypeSlug && leaveTypeSlug) || formData?.values?.leave_type_slug?.value;

    if (
      formDataLeaveType === 'casual-leave' ||
      formDataLeaveType === 'sick-leave' ||
      formDataLeaveType === 'compensatory-off' ||
      formDataLeaveType === 'maternity-leave' ||
      formDataLeaveType === 'paternity-leave' ||
      formDataLeaveType === 'loss-of-pay'
    ) {
      if (dayResult && dayResult.length > availableLeaveCount) {
        module === 'employee' && setHiddenButton(() => ['apply']);
      } else {
        setHiddenButton(() => []);
      }
    }

    if (!data?.watch?.leave_type_slug?.value) {
      setFormData((form) => ({
        ...form,
        values: {
          ...form.values,
          ...data.watch,
          message: '',
        },
      }));
    }
  };

  // set form data after leave type change
  useEffect(() => {
    if (
      !employeeRemainingLeaveProcess &&
      !employeeRemainingLeaveError &&
      employeeRemainingLeaveData?.data
    ) {
      if (module === 'employee') {
        const formDataLeaveType = formData?.values?.leave_type_slug?.value;
        if (
          formDataLeaveType === 'casual-leave' ||
          formDataLeaveType === 'sick-leave' ||
          formDataLeaveType === 'compensatory-off' ||
          formDataLeaveType === 'maternity-leave' ||
          formDataLeaveType === 'paternity-leave' ||
          formDataLeaveType === 'loss-of-pay'
        ) {
          if (formData?.values?.total_days > employeeRemainingLeaveData?.data?.remaining_leaves) {
            setHiddenButton(() => ['apply']);
            dispatch(
              openSnackBarLayout(
                'Sorry! leave count is exceeding from the available leave',
                'error',
                3000,
              ),
            );
          } else {
            setHiddenButton(() => []);
          }
        } else {
          // setHiddenButton(() => []);
          setHiddenButton(() => ['apply']);
        }

        setAvailableLeaveCount(() => employeeRemainingLeaveData?.data?.remaining_leaves);
        setFormData((form) => ({
          ...form,
          values: {
            ...form.values,
            message: `Available leave: ${employeeRemainingLeaveData?.data?.remaining_leaves}`,
          },
        }));
      }
    }
  }, [employeeRemainingLeaveProcess, employeeRemainingLeaveError, employeeRemainingLeaveData]);

  // form submit
  const formSubmit = (data) => {
    const request = data;
    request.approver_email = commonFn.getIdFromMultiValue(data?.approver_email);
    request.leave_type_slug = commonFn.getIdFromMultiValue(data?.leave_type_slug).value;

    console.log('request', request);

    if (request?.to_date < request?.from_date) {
      dispatch(openSnackBarLayout('To date must not be back date', 'error', 3000));
      return;
    }

    if (module === 'hrms' || module === 'company') {
      request.status_slug = data?.status_slug?.value || formData?.values?.status_slug?.value;

      if (request.status_slug === 'rejected' && request.comment === null) {
        dispatch(openSnackBarLayout('Please specify the rejection reason/comment', 'error', 3000));
        return;
      }
    } else if (module === 'employee') {
      request.status_slug = '';
    }

    setIsFormSubmitted(() => true);
    setIsLoading(() => true);

    if (isEdit) {
      dispatch(updateLeave(match?.params?.id, request));
    } else {
      dispatch(createLeave(request));
    }
  };

  // create leave success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!leaveCreateProcess && !leaveCreateError && leaveCreateData) {
        dispatch(openSnackBarLayout(leaveCreateData?.message, 'success', 1000));
        setIsLoading(() => false);
        history.push(`/employee/leaves/view/${leaveCreateData?.data?.id}`);
      } else if (leaveCreateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(leaveCreateError?.message, 'error', 1000));
      }
    }
  }, [leaveCreateProcess, leaveCreateError, leaveCreateData]);

  // update leave success response
  useEffect(() => {
    if (isFormSubmitted) {
      if (!leaveUpdateProcess && !leaveUpdateError && leaveUpdateData) {
        dispatch(openSnackBarLayout(leaveUpdateData.message, 'success', 1000));
        setIsLoading(() => false);
        module === 'employee' && history.push(`/employee/leaves/view/${leaveUpdateData?.data?.id}`);
        module === 'hrms' && history.push(`/hrms/leaves/view/${leaveUpdateData?.data?.id}`);
        module === 'company' && history.push(`/company/leaves/view/${leaveUpdateData?.data?.id}`);
      } else if (leaveUpdateError) {
        setIsLoading(() => false);
        dispatch(openSnackBarLayout(leaveUpdateError?.message, 'error', 1000));
      }
    }
  }, [leaveUpdateProcess, leaveUpdateError, leaveUpdateData]);

  // close dialog function
  const closeDialog = () => {
    setIsDialogOpened(() => false);
    // setIsSendButtonClicked(() => false);
    // setFormDataSet(() => {});
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && !formData.inProgress && (
        <LeaveForm
          {...formData}
          formSubmit={formSubmit}
          changeEvent={changeEvent}
          hiddenKeys={hiddenKeys}
          readOnlyKeys={readOnlyKeys}
          buttonSection={buttonSection}
          hiddenButton={hiddenButton}
        />
      )}

      {isDialogOpened && (
        <DialogBox
          {...formData}
          isOpened={isDialogOpened}
          closeDialog={closeDialog}
          changeEvent={changeEvent}
          formSubmit={formSubmit}
        />
      )}

      {isRouteChange && (
        <div className="loader align-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
