import React from 'react';
import { ClientsRouter } from '.';
import { DashboardHeader } from '../../common';
import { PageHeader } from '../../../data/ClientsData';
import { Container } from '@material-ui/core';

export const ClientsMain = () => {
  const headerData = { header: PageHeader };

  return (
    <Container maxWidth="xl">
      <DashboardHeader {...headerData} />
      <ClientsRouter />
    </Container>
  );
};
