import { Edit } from '@material-ui/icons';

// Applicants table data
export const ResponseData = [
  {
    id: 1,
    profile_image: 'JD',
    full_name: 'John Doe',
    date: '2021-12-20',
    payslip: '',
  },
  {
    id: 2,
    profile_image: 'SD',
    full_name: 'Sam Doe',
    date: '2021-12-01',
    payslip: '',
  },
  {
    id: 3,
    profile_image: 'RK',
    full_name: 'Rakesh',
    date: '2021-11-20',
    payslip: '',
  },
];

// holidays table header
export const PayrollHead = [
  { label: 'id', key: 'id', hide: true, type: 'text', headClass: ' ', bodyClass: '' },
  {
    label: 'Avatar',
    key: 'profile_image',
    hiddenKey: 'profile_image',
    hide: false,
    type: 'file',
    headClass: 'w-80',
    bodyClass: 'avatar-text',
    imageClass: 'avatar-logo profile-image',
  },
  {
    label: 'Name',
    key: 'full_name',
    hiddenKey: 'full_name',
    hide: false,
    headClass: 'w-200',
    type: 'array',
    keys: [
      { key: 'text', hide: false, arrayClass: 'name-phone-group' },
    ],
  },
  {
    label: 'Date',
    key: 'payslip_date',
    hiddenKey: 'payslip_date',
    hide: false,
    headClass: 'w-200',
    type: 'text',
  },
  {
    label: 'Payslip',
    key: 'payslip_name',
    hide: false,
    type: 'text',
    headClass: 'w-100',
    bodyClass: 'highlighted-link',
  },
  {
    label: 'Action',
    key: 'actions',
    hiddenKey: 'actions',
    hide: false,
    type: 'actions',
    headClass: 'w-150 action-head',
    actions: [
      {
        bodyClass: 'icon-success',
        type: 'edit',
        icon: Edit,
        text: 'Edit',
        click: '',
        url: '',
        actionClass: 'icon-wrapper',
      },
    ],
  },
  {
    label: 'Payslip URL',
    key: 'payslip',
    hide: true,
    type: 'text',
    headClass: 'w-200',
    bodyClass: '',
  },
];

// Hrms page header
export const HrmsPageHeader = {
  className: 'page-header',
  page_title: 'Payrolls',
  button: {
    text: 'Create Payroll',
    className: 'link-button btn-sm btn-primary',
    url: '/hrms/payrolls/create',
    hiddenKey: 'create-payroll',
  },
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Payrolls', link: '', className: 'active' },
  ],
};

// Hrms page header
export const EmployeePageHeader = {
  className: 'page-header',
  page_title: 'Payrolls',
  breadcrumb: [
    { text: 'Dashboard', link: '/employee/profile', className: '' },
    { text: 'Payrolls', link: '', className: 'active' },
  ],
};

// HRMS Tabs data
export const HrmsPayrollTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'Create Payroll',
      key: 'create',
      link: '/hrms/payrolls/create',
      path: '/hrms/payrolls/create',
      className: 'tab-link',
      hiddenKey: 'create-payroll',
    },
    {
      label: 'Edit Payroll',
      key: 'edit',
      link: '/hrms/payrolls/edit/:id',
      path: '/hrms/payrolls/edit/:id',
      className: 'tab-link',
      hiddenKey: 'edit-payroll',
    },
    {
      label: 'View Payroll',
      key: 'view',
      link: '/hrms/payrolls/view/:id',
      path: '/hrms/payrolls/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-payroll',
    },
    {
      label: 'Payrolls',
      key: 'list-payrolls',
      link: '/hrms/payrolls',
      path: '/hrms/payrolls',
      className: 'tab-link',
      hiddenKey: 'list-payrolls',
    },
  ],
};

// Employee Leaves Tabs data
export const EmployeeTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'View Payroll',
      key: 'view',
      link: '/employee/payrolls/view/:id',
      path: '/employee/payrolls/view/:id',
      className: 'tab-link',
      hiddenKey: 'list-payrolls',
    },
    {
      label: 'Payrolls',
      key: 'list-payrolls',
      link: '/employee/payrolls',
      path: '/employee/payrolls',
      className: 'tab-link',
      hiddenKey: 'list-payrolls',
    },
  ],
};

// Payrolls filter
export const MainFilter = {
  filterClass: 'payrolls-filter',
  list: [
    {
      label: 'Search',
      placeholder: 'Search',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'text',
      name: 'search',
      key: 'search',
      variant: 'outlined',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'From',
      placeholder: 'From',
      formControlClass: 'filter-formControl',
      fieldClass: 'textBoxStyle',
      margin: 'dense',
      hide: false,
      type: 'date',
      name: 'from_date',
      key: 'from_date',
      variant: 'outlined',
      xs: 12,
      sm: 6,
      md: 6,
      lg: 3,
      xl: 3,
    },
    {
      label: 'To Date',
      xs: 12,
      sm: 12,
      md: 6,
      lg: 3,
      xl: 3,
      key: 'to_date',
      name: 'to_date',
      placeholder: 'To Date',
      hide: false,
      formControlClass: 'input-form-control',
      type: 'date',
      fieldClass: 'textBoxStyle',
      variant: 'outlined',
      margin: 'dense',
      formElementClass: 'form-input-box',
    }
  ],
};

