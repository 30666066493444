import React, { useState, useEffect } from 'react';
import { EmployeeTabs } from '../../data/ReimbursementData';
import { FormInputsData, DocumentUploadInputs } from '../../data/CreateReimbursementData';
import { TabCustom, FormCustom } from '.';
import { useLocation, useHistory } from 'react-router-dom';
import { commonFn } from '../../util/commonFn';
import { useDispatch, useSelector } from 'react-redux';
import { clientsFindAll } from '../../redux/clients/action';
import { CircularProgress, Grid, Box, Link } from '@material-ui/core';
import { openSnackBarLayout } from '../../redux/common/action';
import { invoiceStatusFindAll } from '../../redux/master/action';
import axios from 'axios';
import { apiList } from '../../util/apiList';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { DialogBox } from '../common';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import './FormCustom.scss';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { useFieldArray } from 'react-hook-form';
import { createReimbursement } from '../../redux/reimbursements/action';

export const AddOrEditReimbursement = ({
  match,
  isEdit,
  isView,
  module,
  options = {},
  values = {},
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const location = useLocation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [hiddenKeys, setHiddenKeys] = useState([]);

  const MySwal = withReactContent(Swal);

  const {
    clientsFindAllProcess,
    clientsFindAllError,
    clientsFindAllData,
    createReimbursementProcess,
    createReimbursementError,
    createReimbursementData,
  } = useSelector(({ clients, reimbursements }) => ({
    ...clients,
    ...reimbursements,
  }));

  // tabs
  let tabsData = [];

  if (location?.pathname.indexOf('employee') !== -1) {
    module = 'employee';
    tabsData = EmployeeTabs;
  } else if (location?.pathname.indexOf('hrms') !== -1) {
    tabsData = EmployeeTabs;
    module = 'hrms';
  }

  // set hidden keys
  useEffect(() => {
    module && module === 'employee' && setHiddenKeys(() => ['reimbursement_status']);
  }, [module]);

  // tabs
  const tabs = {
    ...tabsData,
    list: commonFn.updateLink(tabsData?.list, match),
  };

  // set form data
  useEffect(() => {
    setFormData(() => ({
      list: FormInputsData,
      isEdit,
      isView,
      values: {},
      error: '',
      inProgress: isEdit ? true : false,
      options: {},
    }));
    setIsRouteChange(true);
    // match?.params?.id && dispatch(invoiceFind(match?.params?.id));
    dispatch(clientsFindAll());
    dispatch(invoiceStatusFindAll());
    setTimeout(() => setIsRouteChange(false), 50);
    setIsFormSubmitted(() => false);
  }, [match?.params?.id]);

  // change event
  const changeEvent = () => {};

  // form submit
  const formSubmit = (data) => {
    const request = data;
    dispatch(createReimbursement(request));
  };

  //  Upload Reimbursement
  const UploadReimbursement = (form, watch) => {
    const {
      formState: { errors },
      control,
    } = form;
    const { fields, append, remove, update } = useFieldArray({
      control,
      name: 'documentList',
    });

    const handleAddClick = () => {
      append({
        document: '',
        document_name: '',
      });
    };

    useEffect(() => {
      if (!isEdit) {
        handleAddClick();
      }
    }, []);

    // Document upload
    const documentUploadChange = async (e, watch) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target?.files[0];
        var fileFormData = new FormData();
        fileFormData.append('folderName', 'documents');
        fileFormData.append('file', file);
        setIsDocumentUploading(() => true);

        const response = await axios.post(apiList.fileUpload, fileFormData, {
          headers: {},
          withCredentials: true,
        });
        const result = response?.data || {};

        setIsDocumentUploading(() => false);

        if (result?.data) {
          const documentList =
            (formData?.values?.documentList && formData?.values?.documentList) || [];
          const documentObject = {
            document: result?.data?.uploaded_url,
            document_name: result?.data?.original_name,
          };

          documentList.push(documentObject);

          setFormData((data) => ({
            ...data,
            values: {
              ...data.values,
              ...watch,
              documentList: documentList,
            },
          }));
        } else {
          dispatch(openSnackBarLayout('Document is not uploaded', 'error', 1000));
        }
      }
    };

    // remove uploaded document
    const removeDocument = (watch, index) => {
      update(index, {
        ...watch?.documentList?.[index],
        document: '',
        document_name: '',
      });
    };

    // remove uploaded document row
    const handleRemoveClick = (watch, index) => {
      if (watch?.documentList[index] && watch?.documentList[index]?.document) {
        MySwal.fire({
          title: 'Are you sure?',
          text: `You want to remove the uploaded document`,
          icon: 'warning',
          focusConfirm: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            remove(index); // remove selected row
            Swal.fire({
              title: 'Success!',
              text: 'Document has been removed',
              icon: 'success',
              iconColor: '#3085d6',
            });
          }
        });
      } else {
        remove(index);
      }
    };

    // list document types based on the employee doc type id
    // useEffect(() => {
    //   if (isEdit && !employeeFindProcess && !employeeFindError && employeeFindData) {
    //     const documents = [];
    //     employeeFindData?.data?.documents?.forEach((document) => {
    //       documents.push({
    //         id: document.id,
    //         document_type: {
    //           label: document.document_type,
    //           value: parseInt(document.document_type_id),
    //         },
    //         document: document.document,
    //         document_name: document.document_name,
    //       });
    //     });

    //     append(documents);
    //     if (employeeFindData?.data?.documents?.length === 0) {
    //       handleAddClick();
    //     }
    //   }
    // }, [employeeFindProcess, employeeFindError, employeeFindData]);

    const getNonSelectedValue = (selectedValue) => {
      return formData.options?.document_types;
    };

    return (
      <>
        <Grid container spacing={1} className={DocumentUploadInputs?.containerClass}>
          <Grid container spacing={1} className={DocumentUploadInputs?.containerClass}>
            {formData?.values?.documentList &&
              formData?.values?.documentList.map((document, documentIndex) => (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      ml={3}
                      className={`multiple-file-upload ` + DocumentUploadInputs?.formElementClass}
                    >
                      <a href={document?.document} target="_blank">
                        {' '}
                        {document?.document_name}{' '}
                      </a>
                      <div
                        className={'delete-file'}
                        onClick={() => {
                          removeDocument(watch, documentIndex);
                        }}
                      >
                        <HighlightOffIcon />
                      </div>
                    </Box>
                  </Grid>
                </>
              ))}
          </Grid>

          {/* file upload */}
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <Box my={1} className={DocumentUploadInputs?.formElementClass}>
              {isDocumentUploading && ( <div className="loader">
                  <CircularProgress />
                </div>
              )}
              {isDocumentUploading}
              {(isDocumentUploading === undefined || isDocumentUploading === false) && (
                <>
                  <input
                    id={DocumentUploadInputs?.id}
                    className={DocumentUploadInputs.inputClass}
                    type={DocumentUploadInputs?.documentUploadType}
                    onChange={(e) => {
                      documentUploadChange(e, watch);
                    }}
                  />
                  {/* {!watch?.documentList?.[inputListIndex]?.document && ( */}
                  <label
                    htmlFor={DocumentUploadInputs?.id}
                    className={`multiple-file-label ` + DocumentUploadInputs?.uploadTextClass}
                  >
                    <FileCopyIcon /> &nbsp; {DocumentUploadInputs?.documentPlaceholder}
                  </label>
                  {/*  )} */}

                  {/* {watch?.documentList?.[inputListIndex]?.document && (
                        <div className="file-section">
                          <div
                            className={'delete-file'}
                            onClick={() => {
                              removeDocument(watch, inputListIndex);
                            }}
                          >
                            <HighlightOffIcon />
                          </div>
                          <a
                            href={watch?.documentList?.[inputListIndex]?.document}
                            target="_blank"
                            className={DocumentUploadInputs.uploadTextClass}
                          >
                            <FileCopyIcon />
                            {watch?.documentList?.[inputListIndex]?.document_name}
                          </a>
                        </div>
                      )} */}
                </>
              )}
            </Box>
          </Grid>

          {/* remove(inputListIndex)} */}
          {/* action buttons (add and remove) */}
          {/* <Grid item>
                <Box className={DocumentUploadInputs?.formElementClass}>
                  {fields.length - 1 >= 1 && (
                    <Link
                      className={'link-button'}
                      onClick={() => handleRemoveClick(watch, inputListIndex)}
                    >
                      <RemoveCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                  {fields.length - 1 === inputListIndex && (
                    <Link className={'link-button'} onClick={handleAddClick}>
                      <AddCircle className={'interview-status-icon'} />
                    </Link>
                  )}
                </Box>
              </Grid> */}
          {/* button ends */}
        </Grid>
      </>
    );
  };

  return (
    <>
      <TabCustom {...tabs} />
      {!isRouteChange && (
        <FormCustom
          {...formData}
          changeEvent={changeEvent}
          formSubmit={formSubmit}
          isLoading={isLoading}
          renderer={{ UploadReimbursement }}
          hiddenKeys={hiddenKeys}
        />
      )}

      {isRouteChange && (
        <div className="loader align-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
