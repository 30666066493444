import { PhotoCamera } from '@material-ui/icons';
import { Validation } from './CommonData';

export const FormInputsData = [
  {
    groupLabel: 'Partners Data',
    groupLabelClass: 'fieldset-label',
    key: 'partners',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Company Logo',
        xs: 12,
        sm: 8,
        md: 8,
        lg: 4,
        xl: 4,
        key: 'avatar',
        placeholder: '',
        hide: false,
        formControlClass: 'input-form-control file-upload-control',
        type: 'file',
        imageClass: 'form-control-image display-avatar-logo',
        displayImageClass: 'display-avatar-logo',
        name: 'company_logo',
        fieldClass: 'input-file-button',
        id: 'company-avatar-upload',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box file-upload-element',
        icon: PhotoCamera,
        accept: 'image/*',
        buttonText: 'Change',
      },
      {
        label: 'Account Name',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'account_name',
        name: 'account_name',
        placeholder: 'Account Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter partners name' },
          { type: 'minLength', message: 'Please enter atleast three characters' },
        ],
      },
      {
        label: 'Primary Email',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'email',
        name: 'primary_email',
        placeholder: 'Primary Email',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        pattern: Validation.email,
        maxLength: 100,
        errors: [
          { type: 'required', message: 'Please enter a primary email' },
          { type: 'pattern', message: 'Please enter a valid email' },
        ],
      },
      {
        label: 'Phone Number',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'phone',
        name: 'phone',
        placeholder: 'Phone Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        pattern: Validation.numberAndPlus,
        maxLength: 20,
        minLength: 5,
        errors: [
          { type: 'pattern', message: 'Please enter a valid phone number' },
          { type: 'minLength', message: 'Phone number must be greater than 5 digits' },
        ],
      },
      {
        label: 'Website',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'website',
        name: 'website',
        placeholder: 'Website',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'LinkedIn',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'linkedin',
        name: 'linkedin',
        placeholder: 'LinkedIn',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Industry',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'industry',
        name: 'industry',
        placeholder: 'Industry',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        maxLength: 100,
      },
    ],
  },
  {
    groupLabel: 'Partners Address',
    groupLabelClass: 'fieldset-label',
    key: 'address',
    name: 'address',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Address',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'address',
        name: 'address',
        placeholder: 'Address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        // minLength: 3,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Please enter the company address' }],
      },
      {
        label: 'City',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'city_id',
        name: 'city_id',
        placeholder: 'Select or Create City',
        hide: false,
        optionsKey: 'city',
        valueId: 'city_id',
        displayKey: 'city',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the city' }],
      },
      {
        label: 'Zip Code',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'zipcode',
        name: 'zipcode',
        placeholder: 'Zip Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        isRequired: true,
        minLength: 3,
        maxLength: 10,
        errors: [
          {
            type: 'required',
            message: 'Please enter the zipcode',
          },
          {
            type: 'pattern',
            message: 'Please enter a valid zipcode',
          },
          {
            type: 'minLength',
            message: 'Please enter atleast three characters',
          },
        ],
      },
      {
        label: 'State',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'state_id',
        name: 'state_id',
        placeholder: 'Select or Create State',
        hide: false,
        optionsKey: 'state',
        valueId: 'state_id',
        displayKey: 'state',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the state' }],
      },
      {
        label: 'Country',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'country_id',
        name: 'country_id',
        placeholder: 'Select or Create Country',
        hide: false,
        optionsKey: 'country',
        valueId: 'country_id',
        displayKey: 'country',
        formControlClass: 'input-form-control',
        type: 'singleSelectCreate',
        fieldClass: 'textBoxStyle multiSelectStyle', // textBoxStyle multiSelectStyle z-index-4
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
        isRequired: true,
        maxLength: 100,
        errors: [{ type: 'required', message: 'Select or create the country' }],
      },
      {
        label: 'Timezone',
        xs: 12,
        sm: 6,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'timezone_id',
        name: 'timezone_id',
        placeholder: 'Select Timezone',
        hide: false,
        optionsKey: 'timezones',
        valueId: 'timezone_id',
        displayKey: 'timezone',
        formControlClass: 'input-form-control',
        type: 'select',
        fieldClass: 'multiselect textBoxStyle',
        options: [],
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
        size: 'small',
      },
    ],
  },
  {
    groupLabel: 'Partners Account Detail',
    groupLabelClass: 'fieldset-label',
    key: 'flipkoins_account_detail',
    name: 'flipkoins_account_detail',
    groupClass: 'form-fieldset',
    inputs: [
      {
        label: 'Account Name',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'partners_bank_account_name',
        name: 'partners_bank_account_name',
        placeholder: 'Account Name',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Account Number',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'partners_bank_account_number',
        name: 'partners_bank_account_number',
        placeholder: 'Account Number',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'IFSC Code',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'ifsc_code',
        name: 'ifsc_code',
        placeholder: 'IFSC Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Swift Code',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'swift_code',
        name: 'swift_code',
        placeholder: 'Swift Code',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Branch',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'branch',
        name: 'branch',
        placeholder: 'Branch',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'text',
        fieldClass: 'textBoxStyle',
        variant: 'outlined',
        margin: 'dense',
        formElementClass: 'form-input-box',
      },
      {
        label: 'Partners Bank Details',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'partners_more_bank_details',
        name: 'partners_more_bank_details',
        placeholder: 'Partners Bank Details',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        variant: 'outlined',
        margin: 'dense',
        rows: 6,
        formElementClass: 'form-input-box',
      },
      {
        label: 'Partners Account address',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        key: 'partners_account_address',
        name: 'partners_account_address',
        placeholder: 'Account address',
        hide: false,
        formControlClass: 'input-form-control',
        type: 'resizableTextArea',
        fieldClass: 'textBoxStyle resizableTextArea',
        variant: 'outlined',
        margin: 'dense',
        rows: 6,
        formElementClass: 'form-input-box',
      },
    ],
  },

  {
    groupLabel: 'Upload Document(s)',
    groupLabelClass: 'fieldset-label',
    key: 'documents',
    groupClass: 'form-fieldset',
    inputs: [
      {
        type: 'renderer',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        key: 'UploadDocuments',
      },
    ],
  },
];

// document upload
export const DocumentUploadInputs = {
  label: 'Upload SLA',
  displayLabel: 'Upload Documents',
  containerClass: 'interview_status_row',
  xs: 12,
  sm: 12,
  md: 12,
  lg: 3,
  xl: 3,
  documentTypeName: 'documentList.0.document_type',
  documentName: 'documentList.0.document',
  documentUploadType: 'file',
  documentUploadId: 'document-0-value',
  uploadTextClass: 'file-label',
  formControlClass: 'input-form-control',
  fieldClass: 'text-box-style',
  documentTypePlaceholder: 'Select Document Type',
  documentPlaceholder: 'Upload Document',
  variant: 'outlined',
  margin: 'dense',
  inputClass: 'input-file-button',
  keyValue: 1,
  formElementClass: 'form-input-box',
};

// page header, title, breadcrumb and button
export const PageHeader = {
  className: 'page-header',
  page_title: 'Profile',
  breadcrumb: [
    { text: 'Dashboard', link: '/dashboard', className: '' },
    { text: 'Profile', link: '', className: 'active' },
  ],
};

// partners profile tab
export const ProfileTabs = {
  tabClass: 'dashboard-tab',
  list: [
    {
      label: 'View Profile',
      key: 'view',
      link: '/partners/profile',
      path: '/partners/profile',
      className: 'tab-link',
      hiddenKey: 'view-profile',
    },
    {
      label: 'Edit Profile',
      key: 'edit',
      link: '/partners/profile/edit',
      path: '/partners/profile/edit',
      className: 'tab-link',
      hiddenKey: 'edit-profile',
    },
  ],
};
